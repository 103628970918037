import React from "react";

export const NowIcon = (
  props: React.AllHTMLAttributes<SVGElement>
): JSX.Element => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 3v10m0 0 4-3m-4 3-4-3"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
