import React from "react";

export interface UncheckedSVGProps extends React.SVGProps<SVGSVGElement> {}

export const UncheckedSVG = (props: UncheckedSVGProps): JSX.Element => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="9"
        cy="9"
        r="8"
        stroke="currentcolor"
        style={{ stroke: "currentcolor", strokeOpacity: 1 }}
        strokeLinecap="round"
        strokeDasharray="1 2.75"
      />
    </svg>
  );
};
