import React from "react";

export const TrashIcon = (
  props: React.AllHTMLAttributes<SVGElement>
): JSX.Element => (
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.22 12.606c.143.178.293.372.49.494.661.406 1.494.487 2.255.484.435-.002.806-.203 1.178-.41.654-.367 1.303-.75 1.9-1.206.168-.13.342-.267.482-.428.137-.157.26-.341.331-.537.17-.474.173-1.025.158-1.52-.032-1.076-.423-1.39-.972-2.265-.769-1.226-2.84-1.25-3.994-.891-.402.125-1.657.7-2.031.891-1.261.648-1.466 1.919.054 2.315.288.075.604.095.9.083.36-.013.737-.09 1.077-.21.792-.282 1.404-.614 1.561-1.436.066-.342.127-.683.172-1.028.054-.42.103-.85.094-1.276-.013-.664.429-1.369 1.34-1.666 2.297-.75 3 .5 3.975 2.786.31.682.005 1.596-.295 2.232-.165.35-.542.618-.882.778a1.75 1.75 0 0 1-.501.144c-.352.064-.71.103-1.065.14-.534.056-1.041.077-1.547-.14a2.007 2.007 0 0 1-.669-.436c-.692-.663-1.05-1.74-1.517-2.562-.321-.565-.725-1.197-1.313-1.494-.321-.16-.645-.154-.994-.154-.382 0-1.193-.877-1.193-1.794"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
