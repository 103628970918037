import { useEffect, useRef, useState, useCallback } from "react";
import { useMutation } from "react-query";
import { auth } from "./firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import "./App.css";
import styles from "./App.module.css";
import cn from "classnames";
import { serverTimestamp } from "firebase/firestore";
import { useTodos, useAuth, useViewportSize } from "./hooks";
import type { TodoHook } from "./hooks";
import { addNewTodo, getIncrementedListName } from "./utils";
import type { ParentList, Todo } from "./types";
import { Nav } from "./components/Nav";
import { Card } from "./components/Card";
import "react-swipeable-list/dist/styles.css";
import { getDayName } from "./utils";
import { useGlobalContext } from "./App";
import { useToast, setGlobalToastMessage } from "./components/Toast";
import { queryClient } from "./App";
import { UserLogin } from "./components/UserLogin";
import { TodoList } from "./components/Todo/TodoList";
import { Busy } from "./components/Busy";

export interface AddTodoProps {
  parentList: ParentList;
  /**
   * Given a todo ID, the new todo will be added **AFTER** the todo with the given ID.
   * - "first" will add the new todo to the top of the list.
   * - "last" will add the new todo to the bottom of the list.
   *
   * @default "first"
   */
  position?: string | "first" | "last";
}

const offScreenPosition = "100vw";
const inactiveCardOpacity: number = 0.25;
const fractionalIncrement = 0.001;

export function Main() {
  const { width: viewportWidth } = useViewportSize();
  const isSideBySideLayout = viewportWidth >= 768;
  const isStackedLayout = !isSideBySideLayout;
  const { setUser, setFocusedTodoID, activeCard, setActiveCard } =
    useGlobalContext();
  const addTodoButtonRef = useRef<HTMLButtonElement>(null);
  const nowTodos: TodoHook = useTodos("now");
  const nextTodos: TodoHook = useTodos("next");
  const laterTodos: TodoHook = useTodos("later");

  const addTodoMutation = useMutation(addNewTodo, {
    onSuccess: (_data, variables) => {
      const { parentList } = variables;
      queryClient.invalidateQueries(["todos", parentList]);
    },
  });

  const isNextCardVisible = activeCard === "next" || activeCard === "later";
  const nextCardInactiveLeftPosition = offScreenPosition;
  const [nextCardActiveLeftPosition, setNextCardActiveLeftPosition] =
    useState<string>(offScreenPosition);
  const [nextCardLeftPosition, setNextCardLeftPosition] =
    useState<string>(offScreenPosition);
  const [nextCardOpacity, setNextCardOpacity] =
    useState<number>(inactiveCardOpacity);
  const [laterCardOpacity, setLaterCardOpacity] =
    useState<number>(inactiveCardOpacity);
  const laterCardInactiveLeftPosition = offScreenPosition;
  const [laterCardActiveLeftPosition, setLaterCardActiveLeftPosition] =
    useState<string>(offScreenPosition);
  const [laterCardLeftPosition, setLaterCardLeftPosition] =
    useState<string>(offScreenPosition);
  const isLaterCardVisible = activeCard === "later";

  const { user, loading: isUserAuthLoading } = useAuth();
  const toast = useToast();

  // console.log("nowTodos", nowTodos);
  // console.log("completedTodos", completedTodos);

  const changeActiveCard = useCallback(
    (newCard: ParentList | null) => {
      if (newCard === activeCard || newCard === null) return;

      if (activeCard === "now") {
        if (newCard === "next") {
          setNextCardLeftPosition(nextCardActiveLeftPosition);
          setNextCardOpacity(1);
        }
        if (newCard === "later") {
          setNextCardLeftPosition(nextCardActiveLeftPosition);
          setNextCardOpacity(1);
          setLaterCardLeftPosition(laterCardActiveLeftPosition);
          setLaterCardOpacity(1);
        }
      }
      if (activeCard === "next") {
        if (newCard === "now") {
          setNextCardLeftPosition(nextCardInactiveLeftPosition);
          setNextCardOpacity(inactiveCardOpacity);
        }
        if (newCard === "later") {
          setLaterCardLeftPosition(laterCardActiveLeftPosition);
          setLaterCardOpacity(1);
        }
      }
      if (activeCard === "later") {
        if (newCard === "next") {
          setLaterCardLeftPosition(laterCardInactiveLeftPosition);
          setLaterCardOpacity(inactiveCardOpacity);
        }
        if (newCard === "now") {
          setNextCardLeftPosition(nextCardInactiveLeftPosition);
          setNextCardOpacity(inactiveCardOpacity);
          setLaterCardLeftPosition(laterCardInactiveLeftPosition);
          setLaterCardOpacity(inactiveCardOpacity);
        }
      }

      setActiveCard(newCard);
    },
    [
      activeCard,
      laterCardActiveLeftPosition,
      laterCardInactiveLeftPosition,
      nextCardActiveLeftPosition,
      nextCardInactiveLeftPosition,
      setActiveCard,
    ]
  );

  const incrementActiveCard = useCallback(
    (direction: number) => {
      const newCard = getIncrementedListName(direction, activeCard);
      changeActiveCard(newCard);
    },
    [activeCard, changeActiveCard]
  );

  const handleAddTodo = useCallback(
    async ({ parentList, position }: AddTodoProps) => {
      if (!user) {
        console.log("User is not signed in");
        return;
      }

      const targetList = () => {
        if (parentList === "now") return nowTodos;
        if (parentList === "next") return nextTodos;
        return laterTodos;
      };

      const getNewItemOrder = (): number => {
        const positionIsID =
          position && position !== "first" && position !== "last";

        if (positionIsID) {
          const todoToFollow = targetList().todos.find(
            (todo) => todo.id === position
          );
          if (todoToFollow) return todoToFollow.order + fractionalIncrement;
        }

        if (position === "last") {
          return targetList().largestOrder === 0
            ? 1
            : targetList().largestOrder + fractionalIncrement;
        }

        return targetList().smallestOrder === Infinity
          ? 1
          : targetList().smallestOrder - fractionalIncrement;
      };

      const todoData: Partial<Todo> = {
        title: "",
        userId: user.uid,
        datetimeCompleted: null,
        created: serverTimestamp(),
        parentList,
        order: getNewItemOrder(),
      };

      addTodoMutation.mutate(todoData, {
        onSuccess: (newTodoId) => setFocusedTodoID(newTodoId),
        onError: (error) => console.error("Error adding todo: ", error),
      });
    },
    [addTodoMutation, laterTodos, nextTodos, nowTodos, setFocusedTodoID, user]
  );

  const handleNavSwipe = (direction: "left" | "right", distance: number) => {
    if (isSideBySideLayout) return;

    if (Math.abs(distance) < 40) return;

    incrementActiveCard(direction === "left" ? 1 : -1);
  };
  const handleNavSwipeMove = (distance: number) => {
    if (isSideBySideLayout) return;

    const opacityPercentage = Math.max(
      inactiveCardOpacity,
      Math.abs(distance) / 250
    );

    if (activeCard === "now") {
      setNextCardLeftPosition(
        `calc(${nextCardInactiveLeftPosition} - ${distance}px)`
      );
      setNextCardOpacity(opacityPercentage);
    }
    if (activeCard === "next" && distance < 0) {
      setNextCardLeftPosition(
        `calc(${nextCardActiveLeftPosition} - ${distance}px)`
      );
    }
    if (activeCard === "next" && distance > 0) {
      setLaterCardLeftPosition(
        `calc(${laterCardInactiveLeftPosition} - ${distance}px)`
      );
      setLaterCardOpacity(opacityPercentage);
    }
    if (activeCard === "later" && distance < 0) {
      setLaterCardLeftPosition(
        `calc(${laterCardActiveLeftPosition} - ${distance}px)`
      );
    }
  };

  // unsub from auth on unmount
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log("User is signed in:", user);
      } else {
        // User is signed out
        console.log("User is signed out");
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);
  // global keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.target instanceof HTMLElement &&
        event.target.tagName === "BODY"
      ) {
        if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey)
          return;

        switch (event.key) {
          case "n":
            handleAddTodo({ parentList: activeCard });
            return;
          default:
            return;
        }
      }

      const hasAllModifiersMashed =
        (event.metaKey || event.ctrlKey) && event.altKey && event.shiftKey;

      if (hasAllModifiersMashed) {
        switch (event.key) {
          case "ArrowRight":
            incrementActiveCard(-1);
            return;
          case "ArrowLeft":
            incrementActiveCard(1);
            return;
          case "ArrowUp":
            handleAddTodo({ parentList: activeCard, position: "first" });
            return;
          case "ArrowDown":
            handleAddTodo({ parentList: activeCard, position: "last" });
            return;
          default:
            return;
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [activeCard, handleAddTodo, incrementActiveCard]);
  // setUser
  useEffect(() => {
    setUser(user);
  }, [user, setUser]);
  // setGlobalToastMessage
  useEffect(() => {
    setGlobalToastMessage(toast.addMessage);
  }, [toast]);
  // responsive updates
  useEffect(() => {
    if (isSideBySideLayout) {
      setNextCardActiveLeftPosition("calc(100vw * (1 / 3)");
      setLaterCardActiveLeftPosition("calc(100vw * (2 / 3)");
    } else {
      setNextCardActiveLeftPosition(
        "calc(var(--page-margin) +  var(--card-outer-border-stroke))"
      );
      setLaterCardActiveLeftPosition(
        "calc((var(--page-margin) * 2) +  var(--card-outer-border-stroke))"
      );
    }

    // reset card positions in case the viewport changed layout styles
    if (activeCard === "now") {
      setNextCardLeftPosition(nextCardInactiveLeftPosition);
      setLaterCardLeftPosition(laterCardInactiveLeftPosition);
    }
    if (activeCard === "next") {
      setNextCardLeftPosition(nextCardActiveLeftPosition);
      setNextCardOpacity(1);
      setLaterCardLeftPosition(laterCardInactiveLeftPosition);
    }
    if (activeCard === "later" || isSideBySideLayout) {
      setNextCardLeftPosition(nextCardActiveLeftPosition);
      setNextCardOpacity(1);
      setLaterCardLeftPosition(laterCardActiveLeftPosition);
      setLaterCardOpacity(1);
    }
  }, [
    activeCard,
    isSideBySideLayout,
    laterCardActiveLeftPosition,
    laterCardInactiveLeftPosition,
    nextCardActiveLeftPosition,
    nextCardInactiveLeftPosition,
    setActiveCard,
    viewportWidth,
  ]);

  if (isUserAuthLoading) return <Busy />;

  return (
    <>
      {!user ? (
        <UserLogin />
      ) : (
        <>
          <div className={styles.cardBox}>
            <Card
              addTodoButtonRef={addTodoButtonRef}
              className={cn(styles.card, styles.nowCard)}
              handleAddTodo={() => handleAddTodo({ parentList: "now" })}
              inert={activeCard !== "now" && isStackedLayout}
              listName="now"
              onClickHeader={() => {
                if (isStackedLayout) changeActiveCard("now");
              }}
              title={getDayName()}
              flush
              list={nowTodos.todos}
            >
              <TodoList
                todos={nowTodos.todos}
                listName="now"
                handleAddTodo={handleAddTodo}
              />
            </Card>
            <Card
              addTodoButtonRef={addTodoButtonRef}
              className={cn(styles.card, styles.nextCard, {
                [styles.visible]: isNextCardVisible,
              })}
              floating={isNextCardVisible}
              handleAddTodo={() => handleAddTodo({ parentList: "next" })}
              inert={activeCard !== "next" && isStackedLayout}
              listName="next"
              onClickHeader={() => {
                if (isStackedLayout) changeActiveCard("next");
              }}
              title={"Next"}
              list={nextTodos.todos}
              style={{ left: nextCardLeftPosition, opacity: nextCardOpacity }}
              flush={isSideBySideLayout}
            >
              <TodoList
                todos={nextTodos.todos}
                listName="next"
                handleAddTodo={handleAddTodo}
              />
            </Card>
            <Card
              addTodoButtonRef={addTodoButtonRef}
              className={cn(styles.card, styles.laterCard, {
                [styles.visible]: isLaterCardVisible,
              })}
              floating={isLaterCardVisible}
              handleAddTodo={() => handleAddTodo({ parentList: "later" })}
              listName="later"
              title={"Later"}
              list={laterTodos.todos}
              style={{ left: laterCardLeftPosition, opacity: laterCardOpacity }}
              flush={isSideBySideLayout}
            >
              <TodoList
                todos={laterTodos.todos}
                listName="later"
                handleAddTodo={handleAddTodo}
              />
            </Card>
          </div>
          <Nav onSwipe={handleNavSwipe} onSwipeMove={handleNavSwipeMove} />
        </>
      )}
    </>
  );
}
