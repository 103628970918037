import React from "react";
import cn from "classnames";
import {
  BaseDialog,
  DialogContent,
  DialogDescription,
  DialogHeading,
} from "./BaseDialog";
import { DialogClose } from "./DialogClose";
import styles from "./Dialog.module.css";

export type DialogCSSProps = {
  "--dialog-surface"?: string;
};
export interface DialogProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "style"> {
  children: React.ReactNode;
  headingText: string;
  headingAction?: JSX.Element;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  style?: DialogCSSProps & React.CSSProperties;
}

export const Dialog = ({
  children,
  className,
  headingText,
  headingAction,
  open,
  onOpenChange,
  ...restProps
}: DialogProps): JSX.Element => {
  return (
    <BaseDialog open={open} onOpenChange={onOpenChange} {...restProps}>
      <DialogContent className={cn(styles.content, className)}>
        <header className={styles.header}>
          <DialogHeading>{headingText}</DialogHeading>
          <div className={styles.headingActionBar}>
            {headingAction}
            <DialogClose />
          </div>
        </header>
        <DialogDescription>{children}</DialogDescription>
      </DialogContent>
    </BaseDialog>
  );
};
