import React from "react";
import { PageTemplate } from "./PageTemplate";

export const Privacy = (): JSX.Element => {
  return (
    <PageTemplate>
      <header>
        <h1>Privacy</h1>
        <span>We really try not to be creepy</span>
      </header>
      <p>
        Like most everything, we&rsquo;re beholden to google for our web
        services. In our case, google's Firebase handles our databases and login
        authentication.
      </p>
      <p>Google's cookies and trackers are listed below. Beyond that:</p>
      <ul>
        <li>
          There is no additional app instrumentation or analytics beyond
          anonymised event tracking. This means we'll see how many times
          different buttons get clicked and what actions were performed. Because
          they're anonymised, we don't know who did it or what data they had in
          their app at the time. Just "number go up" and "number go down".
        </li>
        <li>
          Your todos are not read or monitored by people, robots, "algorithms",
          or AIs. Any data analysis will be generic and against the entire set
          of users to answer fundamental usability questions like, "what's the
          average character count of a todo" or "what's the average time for
          task completion". No analysis at the individual user level. We don't
          know what you're writing and we don't want to know.
        </li>
        <li>
          Your info is not being tracked or sold to third parties. This app is
          free as a service to the open internet, not because we're selling all
          your data. If we choose to monitize this app in the future, it will be
          via an overt and transparent method, like paid accounts.
        </li>
      </ul>
      <p>
        Do non duis mollit voluptate exercitation mollit. Cupidatat ad dolor ea
        eu sint voluptate. Occaecat consequat tempor sint velit voluptate
        occaecat ea fugiat excepteur exercitation irure dolore. Sint non in sunt
        id. Do adipisicing ex labore in reprehenderit enim fugiat ea cupidatat
        esse. Minim tempor ipsum excepteur commodo cillum anim quis officia
        aliqua. Velit id sint aliqua culpa anim velit.
      </p>
      <p>
        Do non duis mollit voluptate exercitation mollit. Cupidatat ad dolor ea
        eu sint voluptate. Occaecat consequat tempor sint velit voluptate
        occaecat ea fugiat excepteur exercitation irure dolore. Sint non in sunt
        id. Do adipisicing ex labore in reprehenderit enim fugiat ea cupidatat
        esse. Minim tempor ipsum excepteur commodo cillum anim quis officia
        aliqua. Velit id sint aliqua culpa anim velit.
      </p>
      <p>
        Do non duis mollit voluptate exercitation mollit. Cupidatat ad dolor ea
        eu sint voluptate. Occaecat consequat tempor sint velit voluptate
        occaecat ea fugiat excepteur exercitation irure dolore. Sint non in sunt
        id. Do adipisicing ex labore in reprehenderit enim fugiat ea cupidatat
        esse. Minim tempor ipsum excepteur commodo cillum anim quis officia
        aliqua. Velit id sint aliqua culpa anim velit.
      </p>
      <p>
        Do non duis mollit voluptate exercitation mollit. Cupidatat ad dolor ea
        eu sint voluptate. Occaecat consequat tempor sint velit voluptate
        occaecat ea fugiat excepteur exercitation irure dolore. Sint non in sunt
        id. Do adipisicing ex labore in reprehenderit enim fugiat ea cupidatat
        esse. Minim tempor ipsum excepteur commodo cillum anim quis officia
        aliqua. Velit id sint aliqua culpa anim velit.
      </p>
    </PageTemplate>
  );
};
