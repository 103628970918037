import React from "react";
import cn from "classnames";
import styles from "./IndicatorSet.module.css";
import { Indicator } from "./Indicator";
import { useGlobalContext } from "../App";

export interface IndicatorSetProps
  extends Omit<React.HTMLAttributes<HTMLOListElement>, "children"> {}

export const IndicatorSet = ({
  className,
  ...restProps
}: IndicatorSetProps): JSX.Element => {
  const { activeCard, setActiveCard } = useGlobalContext();

  return (
    <ol
      className={cn(styles.base, className)}
      {...restProps}
      role="navigation"
      aria-label="current list"
    >
      <Indicator
        active={activeCard === "now"}
        onClick={() => setActiveCard("now")}
      />
      <Indicator
        active={activeCard === "next"}
        onClick={() => setActiveCard("next")}
      />
      <Indicator
        active={activeCard === "later"}
        onClick={() => setActiveCard("later")}
      />
    </ol>
  );
};
