import React from "react";
import { useDialogContext } from "./useDialogContext";
import { Button } from "../Button";

export const DialogClose = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(function DialogClose(props, ref) {
  const { children, ...restProps } = props;
  const { setOpen } = useDialogContext();
  return (
    <Button
      aria-label="Close dialog"
      kind="reverse"
      icon="close"
      {...restProps}
      ref={ref}
      onClick={() => setOpen(false)}
    />
  );
});
