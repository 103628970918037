import { Timestamp } from "@firebase/firestore";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  sub,
} from "date-fns";

/**
 * Returns firestore timestamps for the start and end of today.
 *
 * Like all Chekhov timestamp utilities, "start" and "end" are measured
 * relative to the calendar. "Start" is the earliest time and "end" is the
 * latest time.
 *
 * @returns [startOfDay, endOfDay]
 */
export function getTodayTimestamps(): [Timestamp, Timestamp] {
  const today = new Date();
  return [
    Timestamp.fromDate(startOfDay(today)),
    Timestamp.fromDate(endOfDay(today)),
  ];
}

/**
 * Returns firestore timestamps for the start and end of this week.
 *
 * Like all Chekhov timestamp utilities, "start" and "end" are measured
 * relative to the calendar.
 *
 * @returns [startOfWeek, endOfWeek]
 */
export function getThisWeekTimestamps(): [Timestamp, Timestamp] {
  const today = new Date();
  return [
    Timestamp.fromDate(startOfWeek(today)),
    Timestamp.fromDate(endOfWeek(today)),
  ];
}

/**
 * Returns firestore timestamps for the start and end of last week.
 *
 * Like all Chekhov timestamp utilities, "start" and "end" are measured
 * relative to the calendar.
 *
 * @returns [startOfWeek, endOfWeek]
 */
export function getLastWeekTimeStamps(): [Timestamp, Timestamp] {
  const today = new Date();
  return [
    Timestamp.fromDate(sub(startOfWeek(today), { weeks: 1 })),
    Timestamp.fromDate(sub(endOfWeek(today), { weeks: 1 })),
  ];
}

/**
 * Returns firestore timestamps for the start and end of this month.
 *
 * Like all Chekhov timestamp utilities, "start" and "end" are measured
 * relative to the calendar.
 *
 * @returns [startOfMonth, endOfMonth]
 */
export function getThisMonthTimestamps(): [Timestamp, Timestamp] {
  const today = new Date();
  return [
    Timestamp.fromDate(startOfMonth(today)),
    Timestamp.fromDate(endOfMonth(today)),
  ];
}

/**
 * Returns firestore timestamps for the start and end of this year.
 *
 * Like all Chekhov timestamp utilities, "start" and "end" are measured
 * relative to the calendar.
 *
 * @returns [startOfYear, endOfYear]
 */
export function getThisYearTimestamps(): [Timestamp, Timestamp] {
  const today = new Date();
  return [
    Timestamp.fromDate(startOfYear(today)),
    Timestamp.fromDate(endOfYear(today)),
  ];
}
