import { useQuery } from "react-query";
import { useAuth } from "./";
import { fetchTodos } from "../utils";
import type { Todo, ParentList, TimeFilter } from "../types";

export type TodoHook = {
  todos: Todo[];
  loading: boolean;
  error: unknown; // was Error | null;
  smallestOrder: number;
  largestOrder: number;
};
export type ArchiveTodoHook = {
  todos: Todo[];
  loading: boolean;
  error: unknown; // was Error | null;
};

export const useTodos = (
  parentList: ParentList = "now",
  pageSize: number = 100,
  dateRange?: TimeFilter | null
) => {
  const { user } = useAuth();
  const userId = user?.uid || "";

  const {
    data,
    isLoading: loading,
    error,
    isSuccess,
  } = useQuery(
    ["todos", parentList, userId, dateRange ?? "none", pageSize],
    () => fetchTodos({ parentList, pageSize, userId: userId!, dateRange }),
    {
      enabled: !!userId, // Only run the query if the userUid is not undefined/null
      onSuccess: (todos) => {
        // Your success logic here. For example, updating order states
      },
      onError: (error) => {
        // Your error handling logic here
      },
    }
  );

  let smallestOrder = Infinity;
  let largestOrder = 0;

  if (isSuccess && data) {
    smallestOrder = data.reduce(
      (min, todo) => Math.min(min, todo.order),
      smallestOrder
    );
    largestOrder = data.reduce(
      (max, todo) => Math.max(max, todo.order),
      largestOrder
    );
  }
  const todos = data || [];

  return { todos, loading, error, smallestOrder, largestOrder };
};
