import React from "react";
import { PageTemplate } from "./PageTemplate";

export const Settings = (): JSX.Element => {
  return (
    <PageTemplate>
      <h1>Settings</h1>
      <p>
        Do non duis mollit voluptate exercitation mollit. Cupidatat ad dolor ea
        eu sint voluptate. Occaecat consequat tempor sint velit voluptate
        occaecat ea fugiat excepteur exercitation irure dolore. Sint non in sunt
        id. Do adipisicing ex labore in reprehenderit enim fugiat ea cupidatat
        esse. Minim tempor ipsum excepteur commodo cillum anim quis officia
        aliqua. Velit id sint aliqua culpa anim velit.
      </p>
      <p>
        Do non duis mollit voluptate exercitation mollit. Cupidatat ad dolor ea
        eu sint voluptate. Occaecat consequat tempor sint velit voluptate
        occaecat ea fugiat excepteur exercitation irure dolore. Sint non in sunt
        id. Do adipisicing ex labore in reprehenderit enim fugiat ea cupidatat
        esse. Minim tempor ipsum excepteur commodo cillum anim quis officia
        aliqua. Velit id sint aliqua culpa anim velit.
      </p>
      <p>
        Do non duis mollit voluptate exercitation mollit. Cupidatat ad dolor ea
        eu sint voluptate. Occaecat consequat tempor sint velit voluptate
        occaecat ea fugiat excepteur exercitation irure dolore. Sint non in sunt
        id. Do adipisicing ex labore in reprehenderit enim fugiat ea cupidatat
        esse. Minim tempor ipsum excepteur commodo cillum anim quis officia
        aliqua. Velit id sint aliqua culpa anim velit.
      </p>
      <p>
        Do non duis mollit voluptate exercitation mollit. Cupidatat ad dolor ea
        eu sint voluptate. Occaecat consequat tempor sint velit voluptate
        occaecat ea fugiat excepteur exercitation irure dolore. Sint non in sunt
        id. Do adipisicing ex labore in reprehenderit enim fugiat ea cupidatat
        esse. Minim tempor ipsum excepteur commodo cillum anim quis officia
        aliqua. Velit id sint aliqua culpa anim velit.
      </p>
    </PageTemplate>
  );
};
