import React from "react";
import { PageTemplate } from "./PageTemplate";
import { Link } from "react-router-dom";

export const About = (): JSX.Element => {
  return (
    <PageTemplate>
      <h1>Chekhov is dumb</h1>
      <p>
        Most todo apps want to be a <em>system</em>. Due dating, prioritizing,
        tagging, describing, calendaring, and more 🤢. If that's what you need,
        check out OmniFocus, Things, or Reminders.app — they're all fantastic.
      </p>
      <p>
        Chekhov doesn't want to be a system. It's a dumb list. Jot down
        reminders and quickly organize them into <b>now</b>, <b>next</b>, and{" "}
        <b>later.</b>
      </p>
      <p>
        See our <Link to="/privacy">privacy statement</Link>.
      </p>
      <h2>&ldquo;Why do I want your dumb list?&rdquo;</h2>
      <h3>
        Making a single source of truth for all of your todos is sisyphean.
      </h3>
      <p>
        In a modern work environment, you likely have tasks strewn across email,
        Slack, iMessage, Asana, Jira, meeting notes, and personal todos&hellip;
        what if you could just jot down a reminder instead of recreating it in a
        new app?
      </p>
      <ul>
        <li>
          <code>Check on thingy for Jen</code>
        </li>
        <li>
          <s>
            <code>Reply to Kevin</code>
          </s>
        </li>
        <li>
          <s>
            <code>Q3 planning</code>
          </s>
        </li>
      </ul>

      <h3>Organizing your list is a waste of time.</h3>
      <p>
        If your daily todo list needs tagging and folders then you&rsquo;re over
        commiting yourself. You should have long term planning, backlogs, and
        ticklers but they just don't need to be in your daily todo&nbsp;list.
      </p>
      <p>
        Chekhov is saving you from yourself by only offering three lists: now,
        next, and later.
      </p>

      <h3>Paper is cool, until&hellip;</h3>
      <p>
        The simplicty of a paper is sublime; but it's not always with you.
        Chances are that you are <em>also</em> broken and that your phone or
        computer are always in reach.{" "}
        <Link to="/download">Save this app to your home screen</Link> and
        you&rsquo;ll have a dumb list with you wherever you go.
      </p>
      <p>
        The speed of paper is divine; but the list you made during breakfast is
        toast by lunch. "Pushing" things to tomorrow on paper creates a lot of
        busy work. Chekhov makes it trivial to move things across lists. Just
        swipe and go.
      </p>
    </PageTemplate>
  );
};
