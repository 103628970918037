import React, {
  useState,
  useEffect,
  useRef,
  RefObject,
  useCallback,
} from "react";
import cn from "classnames";
import { useAuth } from "../../hooks";
import type { Todo } from "../../types";
import { Checkbox } from "./Checkbox";
import { toggleTodoStatus, updateTitle } from "../../utils";
import styles from "./TodoInput.module.css";
import { useMutation } from "react-query";
import { queryClient } from "../../App";
import { Timestamp } from "@firebase/firestore";

const copyTextToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error("Error copying todo", error);
  }
};

interface TodoInputProps extends React.HTMLAttributes<HTMLDivElement> {
  existingTodo?: Todo; // Optional prop for editing existing todos
  /**
   * If the ID of this todo matches the focusedTodoID, the INPUT ref will
   * be focused.
   */
  focusedTodoID?: string | null;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  onCreateNewTodo?: (todoID: string) => void;
  onDelete?: (todoID: string) => void;
  onClearFocusID?: () => void;
  /**
   * Callback for moving a todo forward to the list to the "right".
   */
  onMoveForward?: (todoID: string | null) => void;
  /**
   * Callback for moving a todo backward to the list to the "left".
   */
  onMoveBackward?: (todoID: string | null) => void;
}

export const TodoInput: React.FC<TodoInputProps> = ({
  className,
  existingTodo,
  inputRef,
  focusedTodoID,
  onCreateNewTodo,
  onDelete,
  onClearFocusID,
  onMoveForward,
  onMoveBackward,
  ...restProps
}) => {
  const [title, setTitle] = useState(existingTodo?.title || "");
  const [todoID, setTodoId] = useState(existingTodo?.id || null); // Store the ID of the current todo
  const isComplete = Boolean(existingTodo?.datetimeCompleted);
  const inputRefFallback = useRef<HTMLTextAreaElement>(null);
  const [inputMergedRef, setInputMergedRef] = useState<
    RefObject<HTMLTextAreaElement>
  >(inputRef || inputRefFallback);
  const { user } = useAuth();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const toggleTodoStatusMutation = useMutation(toggleTodoStatus, {
    onMutate: async (data) => {
      const parentList = existingTodo?.parentList ?? "now";
      // cancel any ouytgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(["todos", parentList]);

      // snapshot the previous value
      const previousSourceList: Todo[] | undefined = queryClient.getQueryData([
        "todos",
        parentList,
        user?.uid,
        100,
      ]);

      const newDateTimeCompleted = existingTodo?.datetimeCompleted
        ? null
        : Timestamp.now();

      // optimistic update
      queryClient.setQueryData(
        ["todos", parentList, user?.uid, 100],
        // @ts-expect-error - TKTK
        (old: Todo[] | undefined) => {
          if (typeof old === "undefined") return [];

          return (
            old?.map((todo) => {
              if (todo.id === existingTodo?.id) {
                if (!title) return undefined;

                return { ...todo, datetimeCompleted: newDateTimeCompleted };
              }

              return todo;
            }) ?? []
          );
        }
      );

      return { parentList, previousSourceList, newDateTimeCompleted };
    },
    onError(error, _variables, context) {
      console.error("Error toggling todo status", error);

      if (context) {
        const { previousSourceList, parentList } = context;
        queryClient.setQueryData(
          ["todos", parentList, user?.uid, 100],
          previousSourceList
        );
      }
    },
    onSuccess: (_result, todo) => {
      queryClient.invalidateQueries(["todos", existingTodo?.parentList]);
    },
  });

  const focusTextInput = useCallback(
    (position: "start" | "end") => {
      const cursorPosition = position === "start" ? 0 : title.length;

      inputMergedRef.current?.focus();
      inputMergedRef.current?.setSelectionRange(cursorPosition, cursorPosition);
    },
    [inputMergedRef, title.length]
  );

  const handleCompleteToggle = async () => {
    if (!user) return;

    if (todoID) {
      toggleTodoStatusMutation.mutate({
        datetimeCompleted: existingTodo?.datetimeCompleted ?? null,
        id: todoID,
        title,
      });
    }
  };
  const handleGroupKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const isFocusedOnWrapper = e.target === wrapperRef.current;
    const isFocusedOnInput = e.target === inputMergedRef.current;
    const hasMetaKey = e.metaKey || e.ctrlKey;
    const hasAllModifiersMashed =
      (e.metaKey || e.ctrlKey) && e.altKey && e.shiftKey;
    const isCopyCommand =
      (e.key === "c" || e.key === "C") && (e.ctrlKey || e.metaKey);
    const isSpaceKey = e.key === " ";

    if (hasAllModifiersMashed) return; // These are global shortcuts, handled in Main.tsx

    if (isCopyCommand) {
      e.preventDefault();
      copyTextToClipboard(title);
      // @TODO - also respond to edit menu "copy" command
      return; // Intercept copy command
    }

    // This is separate from the switch statement below because otherwise
    // the space key would be captured by the isAlphaNumericKey check.
    if (isSpaceKey && isFocusedOnWrapper) {
      e.preventDefault();
      handleCompleteToggle();
      return; // Intercept space key
    }
    if (isFocusedOnWrapper && (e.key === "Delete" || e.key === "Backspace")) {
      e.preventDefault();
      if (onDelete && todoID) onDelete(todoID);
      return;
    }

    if (isComplete) return; // Don't allow editing completed todos

    if (isFocusedOnWrapper && hasMetaKey) {
      switch (e.key) {
        case "ArrowRight":
          e.preventDefault();
          onMoveForward?.(todoID);
          return;
        case "ArrowLeft":
          e.preventDefault();
          onMoveBackward?.(todoID);
          return;
        default:
          break;
      }
    }

    if (isFocusedOnWrapper) {
      switch (e.key) {
        case "Tab":
          return; // allow tabbing as normal
        case "Enter":
          inputMergedRef.current?.focus();
          return; // edit mode
        case "ArrowRight":
        case "ArrowDown":
          e.preventDefault();
          focusTextInput("end");
          return;
        case "ArrowLeft":
        case "ArrowUp":
          e.preventDefault();
          focusTextInput("start");
          return;
        default:
          break;
      }
    }
    if (isFocusedOnInput) {
      switch (e.key) {
        case "Enter":
          if (onCreateNewTodo && todoID && e.metaKey) onCreateNewTodo(todoID);
          return;
        case "Escape":
          e.preventDefault();
          wrapperRef.current?.focus();
          return;
        default:
          break;
      }
    }

    // This is not perfect but should handle most cases where the user is trying to
    // type a character into the input. We can't rely on just regex like /^[a-zA-Z0-9]$/
    // as it will fail capture keys from other keyboard layouts like "ø".
    const isAlphaNumericKey =
      e.key.length === 1 && !e.ctrlKey && !e.metaKey && !e.altKey;
    if (isAlphaNumericKey && isFocusedOnWrapper) {
      e.preventDefault();
      setTitle(title + e.key);
      focusTextInput("end");
      return; // Intercept alpha-numeric keys
    }
  };

  useEffect(() => {
    // Ensure we reset state when editing a different existing todo
    setTitle(existingTodo?.title || "");
    setTodoId(existingTodo?.id || null);
  }, [existingTodo]);
  useEffect(() => {
    if (title) {
      updateTitle(todoID, title);
    }
  }, [title, todoID]);
  useEffect(() => {
    setInputMergedRef(inputRef || inputRefFallback);
  }, [inputRef, inputRefFallback]);
  useEffect(() => {
    if (focusedTodoID === todoID) {
      focusTextInput("end");
      onClearFocusID?.();
    }
  }, [focusTextInput, focusedTodoID, onClearFocusID, todoID]);

  return (
    <div
      className={cn(className, styles.base, {
        [styles.isComplete]: isComplete,
      })}
      role="group"
      {...restProps}
      tabIndex={0}
      onKeyDown={handleGroupKeyDown}
      onCopy={() => copyTextToClipboard(title)}
      ref={wrapperRef}
    >
      <Checkbox
        className={styles.checkbox}
        checked={isComplete}
        onChange={handleCompleteToggle}
        tabIndex={-1}
      />
      <div
        className={styles.inputWrap}
        /**
         * The value of the data attribute is used to clone the input's
         * value, which is used in CSS to allow for auto resizing. See TodoInput.module.css
         * for more details.
         */
        data-value={title}
      >
        <textarea
          className={styles.input}
          onChange={(e) => setTitle(e.target.value)}
          readOnly={isComplete}
          ref={inputMergedRef}
          rows={1}
          tabIndex={-1}
          value={title}
        />
      </div>
    </div>
  );
};
