import React from "react";
import { useFloatingTree, useListItem, useMergeRefs } from "@floating-ui/react";
import styles from "./DropdownMenuItem.module.css";
import { DropdownMenuContext } from "./DropdownMenu";
import { Link } from "react-router-dom";

interface DropdownMenuButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  label: string;
}
interface DropdownMenuLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  label: string;
  to: string;
}

export type DropdownMenuItemProps =
  | DropdownMenuButtonProps
  | DropdownMenuLinkProps;

function isLink(props: DropdownMenuItemProps): props is DropdownMenuLinkProps {
  return (props as DropdownMenuLinkProps).to !== undefined;
}

export const DropdownMenuItem = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  DropdownMenuItemProps
>((props, forwardedRef) => {
  const menu = React.useContext(DropdownMenuContext);
  const item = useListItem({ label: props.label });
  const tree = useFloatingTree();
  const isActive = item.index === menu.activeIndex;
  const itemRef = useMergeRefs([item.ref, forwardedRef]);

  // Common item props
  const itemProps = {
    role: "menuitem",
    className: styles.item,
    tabIndex: isActive ? 0 : -1,
  };

  if (isLink(props)) {
    const { label, to, ...restProps } = props as DropdownMenuLinkProps;
    const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      props.onClick?.(event as any);
      tree?.events.emit("click");
    };
    const handleOnFocus = (event: React.FocusEvent<HTMLAnchorElement>) => {
      props.onFocus?.(event as any);
      menu.setHasFocusInside(true);
    };

    return (
      <Link
        {...restProps}
        {...itemProps}
        to={to}
        role="menuitem"
        {...menu.getItemProps({
          onClick: handleOnClick,
          onFocus: handleOnFocus,
        })}
      >
        {label}
      </Link>
    );
  }

  const { label, disabled, ...restProps } = props as DropdownMenuButtonProps;

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onClick?.(event as any);
    tree?.events.emit("click");
  };
  const handleOnFocus = (event: React.FocusEvent<HTMLButtonElement>) => {
    props.onFocus?.(event as any);
    menu.setHasFocusInside(true);
  };

  return (
    <button
      {...restProps}
      {...itemProps}
      ref={itemRef as React.Ref<HTMLButtonElement>}
      type="button"
      disabled={disabled}
      {...menu.getItemProps({
        onClick: handleOnClick,
        onFocus: handleOnFocus,
      })}
    >
      {label}
    </button>
  );
});
