import React from "react";
import cn from "classnames";
import styles from "./Group.module.css";

export interface GroupProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Group = ({
  className,
  children,
  ...restProps
}: GroupProps): JSX.Element => (
  <div className={cn(styles.base, className)} {...restProps}>
    {children}
  </div>
);
