import React from "react";
import { useSpring, animated } from "@react-spring/web";
import cn from "classnames";
import styles from "./Indicator.module.css";

export interface IndicatorProps extends React.HTMLAttributes<HTMLLIElement> {
  /**
   * @default false
   */
  active?: boolean;
  onClick?: () => void;
}

const inactivePath =
  "M2 7.50002C2 5.85171 2.46816 4.88783 3.13457 4.322C3.817 3.74256 4.80225 3.49805 6 3.49805C7.19949 3.49805 8.18428 3.73859 8.86607 4.31551C9.53138 4.87849 10 5.8415 10 7.50002C10 9.15708 9.52754 10.1199 8.85968 10.683C8.17506 11.2602 7.18951 11.5 6 11.5C4.81223 11.5 3.82623 11.2562 3.14096 10.6765C2.472 10.1105 2 9.14687 2 7.50002Z";
const activePath =
  "M2 7.50002C2 6.17141 2.74321 4.69316 3.67057 3.38466C4.53238 2.16867 5.50084 1.17129 6.0007 0.685873C6.50072 1.16964 7.46873 2.1634 8.33006 3.37767C9.25673 4.68408 10 6.16337 10 7.50002C10 9.15708 9.52754 10.1199 8.85968 10.683C8.17506 11.2602 7.18951 11.5 6 11.5C4.81223 11.5 3.82623 11.2562 3.14096 10.6765C2.472 10.1105 2 9.14687 2 7.50002Z";

export const Indicator = ({
  "aria-label": ariaLabel,
  "aria-controls": ariaControls,
  active = false,
  className,
  onClick,
  ...restProps
}: IndicatorProps): JSX.Element => {
  const { d } = useSpring({
    d: active ? activePath : inactivePath,
    config: { duration: 250 },
  });

  return (
    <li
      className={cn(styles.base, { [styles.active]: active }, className)}
      {...restProps}
    >
      <button
        className={styles.button}
        aria-label={ariaLabel}
        aria-controls={ariaControls}
        aria-current={active}
        onClick={onClick}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          imageRendering="optimizeQuality"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
          xmlns="http://www.w3.org/2000/svg"
          className={cn(styles.indicator, { [styles.active]: active })}
        >
          <g clipPath="url(#clip0_1343_7423)">
            <rect width="12" height="12" />
            <animated.path fill="none" d={d} />
          </g>
          <defs>
            <clipPath id="clip0_1343_7423">
              <rect width="12" height="12" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </li>
  );
};
