import React from "react";
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from "@floating-ui/react";
import { Button } from "../Button";
import type { ButtonProps } from "../Button";
import styles from "./DropdownMenu.module.css";
import type {
  TDropdownMenuContext,
  DropdownMenuProps,
} from "./DropdownMenu.types";

export const DropdownMenuContext = React.createContext<TDropdownMenuContext>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => {},
  setHasFocusInside: () => {},
  isOpen: false,
});

interface DropdownMenuComponentProps extends DropdownMenuProps {
  triggerAriaLabel?: string;
  triggerIcon?: ButtonProps["icon"];
}

export const DropdownMenuComponent = React.forwardRef<
  HTMLButtonElement,
  DropdownMenuComponentProps & React.HTMLProps<HTMLButtonElement>
>(
  (
    {
      children,
      label,
      triggerAriaLabel,
      triggerIcon,
      renderMenuButton,
      ...props
    },
    forwardedRef
  ) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [hasFocusInside, setHasFocusInside] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

    const elementsRef = React.useRef<Array<HTMLButtonElement | null>>([]);
    const labelsRef = React.useRef<Array<string | null>>([]);
    const parent = React.useContext(DropdownMenuContext);

    const tree = useFloatingTree();
    const nodeId = useFloatingNodeId();
    const parentId = useFloatingParentNodeId();
    const item = useListItem();

    const isNested = parentId != null;

    const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
      nodeId,
      open: isOpen,
      onOpenChange: setIsOpen,
      placement: isNested ? "right-start" : "bottom-start",
      middleware: [
        offset({
          mainAxis: isNested ? 0 : 4,
          alignmentAxis: isNested ? -4 : 0,
        }),
        flip(),
        shift(),
      ],
      whileElementsMounted: autoUpdate,
    });

    const hover = useHover(context, {
      enabled: isNested,
      delay: { open: 75 },
      handleClose: safePolygon({ blockPointerEvents: true }),
    });
    const click = useClick(context, {
      event: "mousedown",
      toggle: !isNested,
      ignoreMouse: isNested,
    });
    const role = useRole(context, { role: "menu" });
    const dismiss = useDismiss(context, { bubbles: true });
    const listNavigation = useListNavigation(context, {
      listRef: elementsRef,
      activeIndex,
      nested: isNested,
      onNavigate: setActiveIndex,
    });
    const typeahead = useTypeahead(context, {
      listRef: labelsRef,
      onMatch: isOpen ? setActiveIndex : undefined,
      activeIndex,
    });

    const { getReferenceProps, getFloatingProps, getItemProps } =
      useInteractions([hover, click, role, dismiss, listNavigation, typeahead]);

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.
    React.useEffect(() => {
      if (!tree) return;

      function handleTreeClick() {
        setIsOpen(false);
      }

      function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
        if (event.nodeId !== nodeId && event.parentId === parentId) {
          setIsOpen(false);
        }
      }

      tree.events.on("click", handleTreeClick);
      tree.events.on("menuopen", onSubMenuOpen);

      return () => {
        tree.events.off("click", handleTreeClick);
        tree.events.off("menuopen", onSubMenuOpen);
      };
    }, [tree, nodeId, parentId]);

    React.useEffect(() => {
      if (isOpen && tree) {
        tree.events.emit("menuopen", { parentId, nodeId });
      }
    }, [tree, isOpen, nodeId, parentId]);

    const buttonProps = {
      ref: useMergeRefs([refs.setReference, item.ref, forwardedRef]),
      tabIndex: !isNested
        ? undefined
        : parent.activeIndex === item.index
        ? 0
        : -1,
      disabled: props.disabled,
      className: isNested ? styles.item : styles.rootMenu,
      role: isNested ? "menuitem" : undefined,
      "data-open": isOpen ? "" : undefined,
      "data-nested": isNested ? "" : undefined,
      "data-focus-inside": hasFocusInside ? "" : undefined,
      ...getReferenceProps(
        parent.getItemProps({
          ...props,
          onFocus(event: React.FocusEvent<HTMLButtonElement>) {
            props.onFocus?.(event);
            setHasFocusInside(false);
            parent.setHasFocusInside(true);
          },
        })
      ),
    };

    return (
      <FloatingNode id={nodeId}>
        {renderMenuButton ? (
          renderMenuButton(buttonProps)
        ) : (
          <Button
            {...buttonProps}
            aria-label={triggerAriaLabel ? triggerAriaLabel : undefined}
            icon={triggerIcon}
          >
            {label}
            {isNested && (
              <span
                aria-hidden
                style={{ marginLeft: "0.5rem", fontSize: "0.625rem" }}
              >
                ▶
              </span>
            )}
          </Button>
        )}
        <DropdownMenuContext.Provider
          value={{
            activeIndex,
            setActiveIndex,
            getItemProps,
            setHasFocusInside,
            isOpen,
          }}
        >
          <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
            {isOpen && (
              <FloatingPortal>
                <FloatingFocusManager
                  context={context}
                  modal={false}
                  initialFocus={isNested ? -1 : 0}
                  returnFocus={!isNested}
                >
                  <div
                    ref={refs.setFloating}
                    className={styles.menu}
                    style={floatingStyles}
                    {...getFloatingProps()}
                  >
                    {children}
                  </div>
                </FloatingFocusManager>
              </FloatingPortal>
            )}
          </FloatingList>
        </DropdownMenuContext.Provider>
      </FloatingNode>
    );
  }
);

export const DropdownMenu = React.forwardRef<
  HTMLButtonElement,
  DropdownMenuComponentProps & React.HTMLProps<HTMLButtonElement>
>((props, ref) => {
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <DropdownMenuComponent {...props} ref={ref} />
      </FloatingTree>
    );
  }

  return <DropdownMenuComponent {...props} ref={ref} />;
});
