import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { User } from "firebase/auth";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-swipeable-list/dist/styles.css";
import { Main } from "./Main";
import { About } from "./pages/About";
import { Settings } from "./pages/Settings";
import { Download } from "./pages/Download";
import {
  ToastProvider,
  GlobalToastProvider,
  toastMessage,
} from "./components/Toast";
import "./App.css";
import { Privacy } from "./pages/Privacy";
import { DialogName, ParentList } from "./types";

export interface IGlobalContext {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  focusedTodoID: string | null;
  setFocusedTodoID: React.Dispatch<React.SetStateAction<string | null>>;
  activeCard: ParentList;
  setActiveCard: React.Dispatch<React.SetStateAction<ParentList>>;
  activeDialog: DialogName | null;
  setActiveDialog: React.Dispatch<React.SetStateAction<DialogName | null>>;
}

export const GlobalContext = React.createContext<IGlobalContext | undefined>(
  undefined
);
export const queryClient = new QueryClient();

export const GlobalContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [focusedTodoID, setFocusedTodoID] = useState<string | null>(null);
  const [activeCard, setActiveCard] = useState<ParentList>("now");
  const [activeDialog, setActiveDialog] = useState<DialogName | null>(null);

  return (
    <GlobalContext.Provider
      value={{
        user,
        setUser,
        focusedTodoID,
        setFocusedTodoID,
        activeCard,
        setActiveCard,
        activeDialog,
        setActiveDialog,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = React.useContext(GlobalContext);

  if (context === undefined) {
    throw new Error(
      "useGlobalContext must be used within a GlobalContextProvider"
    );
  }

  return context;
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalContextProvider>
        <ToastProvider>
          <GlobalToastProvider
            value={{
              addMessage: (message: string, delay?: number) =>
                toastMessage(message, delay),
            }}
          >
            <div className="App">
              <Router>
                <Routes>
                  <Route path="/" element={<Main />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/download" element={<Download />} />
                  <Route path="/privacy" element={<Privacy />} />
                </Routes>
              </Router>
            </div>
          </GlobalToastProvider>
        </ToastProvider>
      </GlobalContextProvider>
    </QueryClientProvider>
  );
}

export default App;
