import { useState, useEffect } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from "../firebase-config";

export type AuthState = {
  error: unknown;
  loading: boolean;
  user: User | null;
};

export const useAuth = (): AuthState => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user: User | null) => {
        setCurrentUser(user);
        setLoading(false);
      },
      (error) => {
        console.error("Error in useAuth hook: ", error);
        setLoading(false);
        setError(error);
      }
    );

    // Cleanup function to unsubscribe from the listener when the component unmounts
    return unsubscribe;
  }, []);

  return {
    user: currentUser,
    loading,
    error,
  };
};
