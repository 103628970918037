import { ParentList, List } from "../types";

/**
 * Canonical info for all lists.
 *
 * This array is in "linear" order. I.e., all lists "before" a list are
 * are "to the left" and all lists "after" a list are "to the right".
 */
export const lists: List[] = [
  {
    color: "#fff",
    description: "Trash",
    icon: "trash",
    name: "trash",
    surface: "var(--magma-12)",
  },
  {
    color: "#fff",
    description: "Done",
    icon: "check",
    name: "completed",
    surface: "var(--magma-9)",
  },
  {
    color: "#fff",
    description: "Now",
    icon: "flash",
    name: "now",
    surface: "var(--magma-14)",
  },
  {
    color: "#fff",
    description: "Next",
    icon: "arrow-right",
    name: "next",
    surface: "var(--coral)",
  },
  {
    color: "#000",
    description: "Later",
    icon: "calendar",
    name: "later",
    surface: "var(--gold)",
  },
];

/**
 * @param {ParentList} listName
 * @returns {number} The position of the list in the `lists` array.
 */
export function getListPosition(listName: ParentList): number {
  return lists.findIndex((list) => list.name === listName);
}
/**
 * @param {ParentList} listName
 * @returns {List[]} All lists BEFORE the given list.
 */
export function getAfterLists(listName: ParentList): List[] {
  return lists.slice(0, getListPosition(listName));
}
/**
 * @param {ParentList} listName
 * @returns {List[]} All lists AFTER the given list.
 */
export function getBeforeLists(listName: ParentList): List[] {
  return lists.slice(getListPosition(listName) + 1);
}

export function getListByName(name: ParentList): List {
  return lists.find((list) => list.name === name) ?? lists[0];
}

/**
 * Increment the current Main list name by the given direction and get the name of the new list.
 */
export function getIncrementedListName(
  direction: number,
  currentListName: ParentList
): ParentList | null {
  const cardOrder: ParentList[] = ["now", "next", "later"];

  const currentIndex = cardOrder.indexOf(currentListName);
  const nextIndex = currentIndex + direction;

  if (nextIndex < 0 || nextIndex >= cardOrder.length) return null;

  return cardOrder[nextIndex];
}
