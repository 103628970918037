import React, { forwardRef } from "react";
import cn from "classnames";
import styles from "./Button.module.css";

export interface ButtonCSSProps {
  "--button-font-size"?: string;
}
export interface ButtonProps
  extends Omit<React.ComponentPropsWithRef<"button">, "style"> {
  /**
   * @default 'default'
   */
  kind?: "primary" | "default" | "reverse";
  label?: React.ReactNode;
  icon?: "add" | "more" | "close";
  style?: ButtonCSSProps & React.CSSProperties;
  large?: boolean;
}

const IconClose = (): JSX.Element => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    className={styles.icon}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.757403 0.757368C0.371439 1.14333 0.371439 1.77806 0.757403 2.16402L3.59555 5.00217L0.757381 7.84033C0.375763 8.22195 0.371423 8.86102 0.757382 9.24698C1.14335 9.63294 1.77809 9.62428 2.15971 9.24266L4.99787 6.40449L7.84036 9.24698C8.22198 9.6286 8.85672 9.62861 9.24268 9.24265C9.62864 8.85669 9.62863 8.22195 9.24701 7.84033L6.40452 4.99784L9.24267 2.1597C9.62431 1.77806 9.63296 1.14333 9.24699 0.757368C8.86103 0.371409 8.22198 0.375734 7.84034 0.757373L5.0022 3.59552L2.16405 0.757373C1.77809 0.371409 1.14336 0.371409 0.757403 0.757368Z" />
  </svg>
);

const IconAdd = (): JSX.Element => (
  <svg
    width="12"
    height="12"
    fill="none"
    className={styles.icon}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 6a1 1 0 0 0 1 1h4v4a1 1 0 0 0 1 1 1 1 0 0 0 1-1V7h4a1 1 0 0 0 1-1 1 1 0 0 0-.99-1H7V1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v4H1a1 1 0 0 0-1 1Z" />
  </svg>
);
const IconMore = (): JSX.Element => (
  <svg
    width="20"
    height="4"
    fill="none"
    className={styles.icon}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="2" r="2" />
    <circle cx="10" cy="2" r="2" />
    <circle cx="18" cy="2" r="2" />
  </svg>
);

const ButtonForwardRef = (
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
): JSX.Element => {
  const {
    className,
    label,
    children,
    kind = "default",
    icon,
    large,
    ...restProps
  } = props;

  const renderContent = (): React.ReactNode => {
    if (icon === "add") return <IconAdd />;
    if (icon === "more") return <IconMore />;
    if (icon === "close") return <IconClose />;
    return label || children;
  };

  return (
    <button
      ref={ref}
      className={cn(className, styles.base, styles[kind], {
        [styles.iconOnly]: icon,
        [styles.large]: large,
      })}
      type="button"
      {...restProps}
    >
      {renderContent()}
    </button>
  );
};

/**
 * Button component
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ButtonForwardRef
) as (props: ButtonProps) => JSX.Element;
