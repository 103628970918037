import React from "react";

export interface CheckedSVGProps extends React.SVGProps<SVGSVGElement> {}

export const CheckedSVG = (props: CheckedSVGProps): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1014_28589)">
        <circle
          cx="8"
          cy="8"
          r="8"
          stroke="currentcolor"
          style={{ stroke: "currentcolor", strokeOpacity: 1 }}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <g clipPath="url(#clip1_1014_28589)">
          <path
            d="M-87.4429 57.5338C-85.1949 53.3964 -79.9861 48.9384 -81.0374 43.0525C-81.9676 38.1225 -89.3293 33.5982 -89.7422 28.4008C-90.18 23.1357 -84.0189 19.1737 -81.2857 14.4727C-79.0948 10.7026 -79.4502 2.9441 -73.621 0.066454C-69.7503 -1.85911 -62.7916 2.19791 -56.7558 0.822062C-48.2229 -1.08842 -44.019 -11.4109 -37.9215 -18.8901C-32.6161 -25.3849 -22.9626 -30.2095 -19.2335 -38.8948C-15.6935 -47.0486 -18.6095 -57.7897 -16.2715 -66.386C-13.748 -75.628 -5.85258 -83.3458 -4.43147 -91.2967C-1.62145 -106.763 -7.90696 -105.334 -6.79169 -118.714"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-85.3915 58.8181C-84.032 56.2394 -81.4632 53.6001 -79.9574 50.5826C-79.0117 48.6565 -78.5049 46.5841 -78.8505 44.2869C-79.0429 43.2417 -79.4851 42.2499 -80.0877 41.2403C-81.7031 38.5255 -84.4316 36.028 -85.9578 33.2418C-86.564 32.1181 -87.0347 30.9444 -87.1415 29.7137C-87.2945 27.7304 -86.5736 25.9256 -85.439 24.1993C-83.5408 21.3067 -80.4012 18.6497 -78.7349 15.6501C-77.2648 12.9536 -77.107 8.46977 -75.1374 4.9351C-74.2952 3.35502 -73.0786 2.06036 -71.3484 1.11538C-69.7966 0.31305 -67.8275 0.434675 -65.6695 0.756092C-62.2951 1.24538 -58.4 2.31263 -54.7935 1.44645C-51.1192 0.55531 -48.1759 -1.79847 -45.5963 -4.8265C-43.2594 -7.57277 -41.1685 -10.8827 -38.9563 -13.9679C-37.8824 -15.4409 -36.8298 -16.8675 -35.706 -18.205C-34.1183 -20.0596 -32.206 -21.7644 -30.2045 -23.5405C-26.8438 -26.4328 -23.2583 -29.4464 -20.4432 -32.9845C-19.1017 -34.6715 -17.8993 -36.4227 -17.025 -38.438C-16.1079 -40.546 -15.5617 -42.8253 -15.3152 -45.1867C-14.79 -49.6134 -15.1209 -54.3791 -14.849 -58.8665C-14.7202 -61.3385 -14.3809 -63.657 -13.6956 -65.8721C-13.0925 -67.7875 -12.3038 -69.6173 -11.3546 -71.4292C-9.86665 -74.2862 -8.03622 -77.1539 -6.3378 -79.8575C-4.53232 -82.7929 -2.81249 -85.5428 -1.89879 -88.4962C-1.71319 -89.1419 -1.52759 -89.7875 -1.40977 -90.4081C-0.827743 -93.7397 -0.634659 -96.3508 -0.698508 -98.4055C-0.783384 -102.608 -1.92804 -104.88 -2.7087 -107.941C-3.40385 -110.456 -3.97053 -113.249 -3.59159 -117.968"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-83.3398 60.1026C-81.9588 57.4775 -79.2973 54.8811 -77.8378 51.8421C-76.8922 49.9161 -76.3639 47.7973 -76.7095 45.5C-76.902 44.4548 -77.3227 43.4167 -77.879 42.4285C-79.4017 39.7565 -81.9446 37.3447 -83.4245 34.5799C-84.0307 33.4562 -84.455 32.3039 -84.5618 31.0732C-84.7148 29.0899 -83.9939 27.285 -82.9057 25.5374C-81.0075 22.6448 -77.8465 19.9414 -76.2265 16.9204C-74.8028 14.2025 -74.7378 9.67582 -72.8359 6.16611C-71.9938 4.58602 -70.8021 3.22359 -69.0719 2.27861C-67.5664 1.45487 -65.576 1.53013 -63.4643 1.83014C-60.1149 2.25164 -56.4302 3.16547 -52.8023 2.25292C-49.1529 1.294 -46.1383 -0.970595 -43.491 -4.02358C-41.1291 -6.70207 -39.0417 -10.1261 -36.7832 -13.1899C-35.7307 -14.6166 -34.6317 -16.0218 -33.4401 -17.3842C-31.8524 -19.2389 -29.8937 -20.9222 -27.8709 -22.7446C-24.5351 -25.7048 -21.0424 -28.7612 -18.2059 -32.3456C-16.8643 -34.0326 -15.6405 -35.8302 -14.7198 -37.8241C-13.7564 -39.9107 -13.2102 -42.19 -12.8959 -44.5763C-12.3493 -49.0494 -12.6588 -53.8615 -12.3406 -58.3275C-12.1653 -60.778 -11.7797 -63.0752 -11.0695 -65.2225C-10.4413 -67.0701 -9.6063 -68.8785 -8.63217 -70.6227C-7.05145 -73.4368 -5.1996 -76.3508 -3.47622 -78.9866C-1.62438 -81.9006 0.166783 -84.5614 1.10544 -87.447C1.33741 -88.0712 1.47664 -88.7383 1.59446 -89.3589C2.22286 -92.6691 2.36957 -95.3016 2.34854 -97.449C2.26367 -101.651 1.27953 -103.906 0.498869 -106.966C-0.174869 -109.528 -0.809328 -112.296 -0.408978 -117.061"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-81.2903 61.3872C-79.888 58.7156 -77.1801 56.1406 -75.6743 53.1231C-74.7072 51.1507 -74.1789 49.0319 -74.5245 46.7346C-74.6956 45.6431 -75.0913 44.6727 -75.6262 43.6382C-77.0348 40.9626 -79.46 38.6614 -80.8471 35.9394C-81.407 34.8371 -81.8099 33.6385 -81.9167 32.4078C-82.0697 30.4245 -81.3952 28.5982 -80.2391 26.8256C-78.3873 23.9115 -75.18 21.2296 -73.5849 18.1408C-72.2076 15.4015 -72.2567 10.8784 -70.4012 7.34726C-69.5591 5.76718 -68.4138 4.38333 -66.6622 3.39198C-65.1353 2.52187 -63.1912 2.57572 -61.1045 2.80795C-57.8264 3.14026 -54.3058 3.92208 -50.7028 2.94175C-47.0998 1.96142 -44.0174 -0.328125 -41.3237 -3.3597C-38.9618 -6.03819 -36.8531 -9.50863 -34.5018 -12.5296C-33.4029 -13.9348 -32.304 -15.3401 -31.0874 -16.6347C-29.4533 -18.4679 -27.4947 -20.1513 -25.4718 -21.9737C-22.136 -24.9338 -18.761 -28.1008 -15.9494 -31.7531C-14.6079 -33.4401 -13.3841 -35.2377 -12.4634 -37.2315C-11.4785 -39.3645 -10.886 -41.6224 -10.5716 -44.0087C-9.95731 -48.5068 -10.3381 -53.4081 -9.90573 -57.8776C-9.68415 -60.3067 -9.22717 -62.5147 -8.49198 -64.5942C-7.81747 -66.4205 -6.95749 -68.161 -5.95841 -69.8374C-4.35272 -72.5838 -2.4331 -75.5228 -0.663353 -78.1372C1.21345 -80.9834 3.07593 -83.555 4.08237 -86.4655C4.31434 -87.0898 4.45357 -87.7568 4.59281 -88.4238C5.2212 -91.734 5.34295 -94.4343 5.32192 -96.5817C5.28342 -100.762 4.43485 -103.067 3.70055 -106.106C3.04822 -108.714 2.34598 -111.457 2.69996 -116.244"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-79.2387 62.6717C-77.8827 59.9788 -75.0607 57.4003 -73.5548 54.3827C-72.5414 52.4317 -72.0595 50.2915 -72.3837 47.9479C-72.5083 46.8777 -72.9291 45.8396 -73.439 44.8728C-74.8012 42.2187 -77.016 40.0709 -78.289 37.3454C-78.8024 36.2645 -79.2053 35.0658 -79.2907 33.7888C-79.4437 31.8054 -78.7478 29.9328 -77.6595 28.1851C-75.8755 25.2961 -72.579 22.5428 -71.0767 19.4112C-69.7242 16.6041 -69.909 12.1309 -68.0998 8.57837C-67.3041 6.97688 -66.1374 5.54667 -64.3857 4.55532C-62.8589 3.68521 -60.9397 3.67127 -58.878 3.83572C-55.6712 4.07885 -52.2683 4.75006 -48.7366 3.68054C-45.1586 2.63243 -42.0049 0.432076 -39.2434 -2.62446C-36.8352 -5.28154 -34.705 -8.79834 -32.3074 -11.7979C-31.2084 -13.2031 -30.0168 -14.5656 -28.8465 -15.8816C-27.1661 -17.6934 -25.2075 -19.3768 -23.1632 -21.2456C-19.8524 -24.2735 -16.5486 -27.5297 -13.7371 -31.1819C-12.3742 -32.9153 -11.1503 -34.7129 -10.1833 -36.6853C-9.15203 -38.7969 -8.51313 -41.0334 -8.19881 -43.4197C-7.58448 -47.9177 -7.92247 -52.9118 -7.39733 -57.3385C-7.12938 -59.7462 -6.62603 -61.9328 -5.81952 -63.9231C-5.12005 -65.6815 -4.23512 -67.3544 -3.18966 -69.0093C-1.51265 -71.6665 0.428382 -74.6519 2.2445 -77.2448C4.21404 -80.0482 6.14785 -82.5306 7.13287 -85.3948C7.36485 -86.019 7.55045 -86.6647 7.64331 -87.3531C8.2717 -90.6633 8.41487 -93.4099 8.39384 -95.5573C8.42311 -99.763 7.66728 -102.025 7.00076 -105.089C6.39126 -107.789 5.57487 -110.529 5.97522 -115.295"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-77.1884 63.956C-75.811 61.2167 -72.9426 58.6596 -71.3904 55.6635C-70.377 53.7124 -69.8737 51.5259 -70.1515 49.2036C-70.2761 48.1335 -70.6755 47.049 -71.139 46.1036C-72.3871 43.4459 -74.4842 41.4087 -75.6644 38.726C-76.1565 37.5988 -76.513 36.4215 -76.5984 35.1445C-76.7514 33.1612 -76.1019 31.2671 -74.9922 29.4731C-73.2081 26.584 -69.8438 23.8058 -68.4343 20.6313C-67.1282 17.8028 -67.4271 13.3332 -65.6643 9.75926C-64.8685 8.15776 -63.7019 6.72755 -61.9966 5.71479C-60.4947 4.7769 -58.5756 4.76297 -56.5388 4.85964C-53.4033 5.01357 -50.1644 5.55277 -46.6114 4.43689C-43.0583 3.321 -39.8119 1.16346 -37.0504 -1.89307C-34.6421 -4.55015 -32.4905 -8.11332 -30.0466 -11.0914C-28.9013 -12.4753 -27.7096 -13.8377 -26.5144 -15.086C-24.809 -16.83 -22.7826 -18.5383 -20.7383 -20.4071C-17.4275 -23.435 -14.2415 -26.8018 -11.4299 -30.4541C-10.1134 -32.2088 -8.84317 -33.985 -7.85472 -36.0039C-6.82346 -38.1154 -6.11677 -40.3768 -5.75608 -42.7418C-5.07397 -47.2648 -5.43692 -52.3266 -4.86541 -56.7318C-4.52968 -59.1646 -4.00138 -61.2833 -3.16991 -63.2059C-2.44548 -64.8965 -1.51418 -66.548 -0.422352 -68.1815C1.32598 -70.7495 3.33479 -73.7598 5.15091 -76.3528C7.16682 -79.1348 9.15055 -81.4816 10.2034 -84.3708C10.4353 -84.995 10.6209 -85.6406 10.7602 -86.3076C11.41 -89.6642 11.4853 -92.3859 11.5321 -94.5582C11.6077 -98.7424 10.9661 -101.008 10.2995 -104.072C9.73285 -106.865 8.82728 -109.533 9.24904 -114.345"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-75.1363 65.2407C-73.7375 62.4551 -70.8228 59.9194 -69.2705 56.9232C-68.2571 54.9722 -67.7324 52.7393 -68.0102 50.4171C-68.1349 49.3469 -68.4878 48.2838 -68.93 47.2921C-70.0853 44.6772 -71.9969 42.7257 -73.1094 40.018C-73.5551 38.9121 -73.9116 37.7349 -73.997 36.4579C-74.1286 34.4282 -73.479 32.5341 -72.4157 30.7187C-70.678 27.8082 -67.3138 25.03 -65.8828 21.8092C-64.6017 18.9129 -64.9434 14.536 -63.3661 10.8764C-62.663 9.23208 -61.45 7.82328 -59.7697 6.74274C-58.2678 5.80485 -56.3272 5.74455 -54.3618 5.75202C-51.2976 5.81677 -48.2228 6.22396 -44.6947 5.04029C-41.1666 3.85663 -37.8275 1.74191 -35.041 -1.24685C-32.5864 -3.88252 -30.4597 -7.51347 -27.923 -10.4488C-26.7314 -11.8112 -25.5611 -13.1272 -24.2981 -14.4005C-22.5464 -16.1231 -20.5199 -17.8314 -18.4543 -19.7466C-15.122 -22.8208 -12.0751 -26.2519 -9.28854 -29.9719C-7.97196 -31.7267 -6.68035 -33.5492 -5.64553 -35.5466C-4.5679 -37.6368 -3.86121 -39.8982 -3.47911 -42.3095C-2.77559 -46.8789 -3.11715 -51.987 -2.49927 -56.3709C-2.11717 -58.7822 -1.47117 -60.7904 -0.639695 -62.7129C0.0847253 -64.4036 1.08736 -65.9658 2.20414 -67.5316C3.97744 -70.0318 6.07544 -73.1135 7.89511 -75.5923C9.93597 -78.3065 11.991 -80.5641 13.0902 -83.4319C13.3222 -84.0561 13.5078 -84.7017 13.647 -85.3687C14.2968 -88.7253 14.3936 -91.4933 14.4404 -93.6657C14.5624 -97.8285 14.0348 -100.097 13.4361 -103.186C12.8908 -106.026 11.9175 -108.669 12.2929 -113.503"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-73.0867 66.5253C-71.7343 63.7182 -68.6804 61.2467 -67.1745 58.2292C-66.1397 56.2318 -65.6827 54.0238 -65.8928 51.6766C-65.996 50.5601 -66.349 49.497 -66.7448 48.5267C-67.7859 45.9083 -69.5799 44.0673 -70.5996 41.4025C-70.9989 40.318 -71.334 39.0945 -71.4194 37.8174C-71.551 35.7877 -70.9479 33.8723 -69.8845 32.0568C-68.1932 29.1249 -64.7611 26.3218 -63.3765 23.0795C-62.1418 20.1618 -62.669 15.6993 -61.0667 12.1075C-60.3173 10.4846 -59.1756 8.98657 -57.4953 7.90603C-55.9719 6.92177 -54.0564 6.79369 -52.1373 6.77976C-49.0981 6.77672 -46.1874 7.0519 -42.752 5.82542C-39.2703 4.62034 -35.8847 2.52703 -33.0305 -0.486685C-30.5295 -3.10094 -28.3601 -6.82463 -25.7984 -9.69214C-24.6067 -11.0546 -23.3901 -12.3492 -22.0807 -13.601C-20.329 -15.3237 -18.2562 -17.0106 -16.1905 -18.9257C-12.9046 -22.0214 -9.92905 -25.5416 -7.12106 -29.308C-5.80449 -31.0628 -4.49147 -32.9317 -3.4317 -34.8614C-2.33265 -36.9979 -1.5796 -39.2379 -1.15113 -41.6278C-0.379825 -46.2221 -0.792702 -51.4194 -0.0357179 -55.7391C0.346385 -58.1504 1.03876 -60.1372 1.94156 -61.9705C2.73731 -63.572 3.73993 -65.1342 4.88167 -66.6322C6.72629 -69.0432 8.84571 -72.1712 10.7117 -74.6287C12.799 -77.3215 14.9717 -79.4685 16.0709 -82.3363C16.3029 -82.9605 16.5099 -83.6524 16.6491 -84.3195C17.2989 -87.676 17.3708 -90.5119 17.4639 -92.6628C17.5859 -96.8256 17.2189 -99.0765 16.6416 -102.212C16.1391 -105.144 15.0765 -107.716 15.4734 -112.596"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-70.9669 67.7844C-69.5931 64.9309 -66.4928 62.4809 -64.9406 59.4848C-63.9058 57.4874 -63.4274 55.233 -63.6588 52.9322C-63.7621 51.8157 -64.0687 50.774 -64.4645 49.8037C-65.4592 47.2067 -67.0428 45.5192 -67.9483 42.8508C-68.3477 41.7663 -68.6364 40.5642 -68.7004 39.2407C-68.832 37.211 -68.2752 35.2742 -67.1905 33.4123C-65.4991 30.4805 -62.0457 27.6309 -60.7074 24.3673C-59.4976 21.3818 -60.1604 16.9692 -58.5831 13.3096C-57.88 11.6653 -56.6919 10.1887 -55.0366 9.04036C-53.5596 8.03469 -51.6226 7.86024 -49.7499 7.8249C-46.782 7.73267 -44.0354 7.87584 -40.625 6.58158C-37.1682 5.30872 -33.6899 3.25823 -30.7894 0.265931C-28.2883 -2.34833 -26.0975 -6.11839 -23.468 -9.01086C-22.2514 -10.3055 -21.0134 -11.6465 -19.7254 -12.852C-17.9273 -14.5532 -15.8081 -16.2187 -13.7674 -18.2016C-10.4601 -21.3437 -7.60224 -24.9745 -4.84063 -28.7623C-3.50264 -30.5634 -2.21103 -32.386 -1.12985 -34.362C0.0155683 -36.4771 0.790032 -38.7635 1.2185 -41.1534C2.01121 -45.7941 1.61975 -51.0378 2.4231 -55.336C2.85158 -57.7259 3.59031 -59.6913 4.56444 -61.4354C5.40655 -63.0155 6.41273 -64.4636 7.60084 -65.9401C9.47042 -68.2834 11.6576 -71.4364 13.57 -73.8724C15.7036 -76.5438 17.9263 -78.5552 19.0932 -81.448C19.3252 -82.0722 19.5786 -82.7427 19.6715 -83.4312C20.3213 -86.7877 20.3931 -89.6236 20.4613 -91.8423C20.6511 -96.03 20.3768 -98.2381 19.8672 -101.399C19.3862 -104.377 18.2559 -106.924 18.6527 -111.804"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-68.9157 69.0692C-67.5204 66.1694 -64.3738 63.7408 -62.8216 60.7446C-61.7868 58.7472 -61.3084 56.4929 -61.5184 54.1457C-61.6217 53.0292 -61.9069 51.9412 -62.2563 50.9923C-63.1369 48.3917 -64.6027 46.8148 -65.4156 44.1892C-65.7685 43.1261 -66.0358 41.8776 -66.0998 40.5542C-66.2314 38.5245 -65.6532 36.5413 -64.6149 34.658C-62.9699 31.7047 -59.4023 28.8517 -58.1568 25.5452C-56.9934 22.5383 -57.7702 18.1292 -56.2393 14.4482C-55.5149 12.7575 -54.3731 11.2595 -52.7178 10.1112C-51.2194 9.05917 -49.3288 8.86331 -47.481 8.76019C-44.5845 8.57877 -41.9341 8.56497 -38.5701 7.24929C-35.1383 5.90865 -31.5887 3.94736 -28.6667 0.908692C-26.1193 -1.68416 -23.9534 -5.522 -21.2312 -8.37165C-20.0146 -9.6663 -18.7052 -10.9181 -17.3959 -12.1699C-15.5514 -13.8497 -13.4322 -15.5152 -11.3915 -17.4982C-8.13057 -20.6616 -5.344 -24.3816 -2.56098 -28.2158C-1.223 -30.017 0.136398 -31.8645 1.21758 -33.8405C2.40936 -35.9342 3.2302 -38.1992 3.68008 -40.6354C4.51916 -45.2547 4.12415 -50.6126 4.99528 -54.9358C5.47012 -57.3042 6.28018 -59.1804 7.30068 -60.9031C8.21412 -62.394 9.2203 -63.8421 10.4334 -65.2509C12.3743 -67.505 14.6043 -70.7507 16.5416 -73.1189C18.7002 -75.7225 20.9942 -77.6448 22.2075 -80.5161C22.4859 -81.1189 22.6929 -81.8109 22.8321 -82.4779C23.4819 -85.8344 23.5288 -88.7381 23.6433 -90.9354C23.8795 -95.1017 23.7194 -97.3134 23.2098 -100.474C22.7716 -103.545 21.5521 -106.021 21.9239 -110.969"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-66.8666 70.3539C-65.5177 67.4327 -62.2569 65.0005 -60.7047 62.0044C-59.6699 60.007 -59.1701 57.7063 -59.3338 55.3805C-59.4156 54.2176 -59.7008 53.1296 -60.0038 52.2021C-60.7703 49.5979 -62.0721 48.153 -62.8385 45.5489C-63.1237 44.4609 -63.4124 43.2587 -63.5014 41.8675C-63.633 39.8378 -63.1011 37.8332 -62.0628 35.9499C-60.4392 33.043 -56.8502 30.1436 -55.6297 26.7693C-54.5126 23.741 -55.4036 19.3355 -53.9191 15.6331C-53.241 13.921 -52.0993 12.423 -50.4225 11.2283C-48.9242 10.1763 -47.0585 9.91262 -45.2357 9.74172C-42.4105 9.47111 -39.9242 9.32529 -36.5851 7.94183C-33.1783 6.53341 -29.5359 4.61495 -26.5675 1.59768C-24.0201 -0.995162 -21.7865 -4.85796 -19.0179 -7.6862C-17.7549 -8.95943 -16.4455 -10.2113 -15.0898 -11.4417C-13.199 -13.1001 -11.0798 -14.7655 -9.01765 -16.7949C-5.73535 -20.0047 -3.08789 -23.7889 -0.283453 -27.6695C1.05453 -29.4706 2.41393 -31.3182 3.51652 -33.3405C4.72971 -35.4806 5.59692 -37.7241 6.02539 -40.114C6.93225 -44.7583 6.51227 -50.1839 7.42977 -54.4857C7.95098 -56.8328 8.80742 -58.6875 9.85287 -60.3425C10.7913 -61.7656 11.8438 -63.1922 13.0355 -64.5547C15.0013 -66.741 17.2991 -70.0116 19.2829 -72.3584C21.4878 -74.9406 23.8531 -76.7737 25.1128 -79.6236C25.3911 -80.2264 25.5982 -80.9184 25.7374 -81.5854C26.4336 -84.9206 26.4555 -87.892 26.57 -90.0893C26.8525 -94.2342 26.8066 -96.4494 26.3648 -99.6349C25.948 -102.753 24.6607 -105.203 25.0539 -110.197"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-64.8157 71.6383C-63.4454 68.6707 -60.1383 66.2599 -58.5397 63.2853C-57.4835 61.2415 -56.9837 58.9408 -57.1474 56.615C-57.2292 55.4521 -57.468 54.3855 -57.7496 53.4116C-58.4697 50.8289 -59.586 49.4696 -60.2598 46.9083C-60.5449 45.8203 -60.7659 44.5932 -60.8762 43.2484C-61.0079 41.2186 -60.476 39.214 -59.4626 37.263C-57.8854 34.3347 -54.2286 31.4103 -53.0544 28.0146C-51.9624 24.9185 -52.9889 20.5629 -51.5507 16.8391C-50.8727 15.127 -49.7559 13.5612 -48.1469 12.3915C-46.6735 11.2717 -44.7615 11.0294 -43.01 10.7693C-40.2097 10.431 -37.9339 10.1317 -34.6198 8.68047C-31.2379 7.20428 -27.5706 5.35359 -24.5558 2.35774C-21.9621 -0.213698 -19.7533 -4.14427 -16.9384 -6.9511C-15.6754 -8.22434 -14.3197 -9.45476 -12.939 -10.6174C-11.0018 -12.2544 -8.88262 -13.9198 -6.82047 -15.9492C-3.53816 -19.159 -0.98699 -23.1002 1.77107 -27.0021C3.06269 -28.8247 4.48987 -30.6972 5.63883 -32.6982C6.89839 -34.8168 7.76559 -37.0604 8.26185 -39.4752C9.19012 -44.1658 8.7666 -49.7056 9.8018 -53.8968C10.3908 -56.2688 11.2508 -58.0094 12.389 -59.6216C13.3737 -61.0233 14.4512 -62.3821 15.6678 -63.6768C17.705 -65.7739 20.0242 -69.0909 22.0543 -71.4163C24.3056 -73.9771 26.7672 -75.6532 28.0269 -78.5031C28.3267 -79.1523 28.5123 -79.7979 28.6729 -80.5113C29.3691 -83.8464 29.3447 -86.8393 29.5484 -89.1079C29.8773 -93.2314 29.9669 -95.4965 29.5715 -98.6606C29.1975 -101.871 27.796 -104.318 28.2142 -109.245"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-62.7633 72.9228C-61.3716 69.9088 -57.9717 67.5409 -56.4195 64.5447C-55.3633 62.501 -54.8635 60.2003 -55.0057 57.8281C-55.0876 56.6652 -55.305 55.5522 -55.5616 54.6462C-56.1676 52.0599 -57.1198 50.8326 -57.7258 48.2464C-57.9646 47.1798 -58.1855 45.9526 -58.2745 44.5614C-58.3383 42.5068 -57.8992 40.4593 -56.8394 38.5297C-55.3086 35.58 -51.584 32.6306 -50.5026 29.1921C-49.5032 26.0532 -50.5976 21.7225 -49.2058 17.9773C-48.5741 16.2438 -47.4109 14.6995 -45.8269 13.4619C-44.3321 12.2958 -42.4664 12.0321 -40.7399 11.7042C-38.011 11.2767 -35.8528 10.8668 -32.5851 9.39418C-29.2495 7.89657 -25.4681 6.04233 -22.407 3.06789C-19.7668 0.517865 -17.5831 -3.48049 -14.6754 -6.24449C-13.366 -7.49632 -12.0317 -8.68037 -10.6296 -9.88938C-8.69241 -11.5263 -6.48051 -13.149 -4.39695 -15.2247C-1.1396 -18.5023 1.31885 -22.4863 4.09832 -26.4347C5.41135 -28.3036 6.81711 -30.1297 8.01244 -32.1093C9.272 -34.2279 10.207 -36.4964 10.7032 -38.9113C11.6993 -43.6268 11.2508 -49.2344 12.3324 -53.4042C12.9214 -55.7762 13.9204 -57.4526 15.0836 -58.9969C16.0933 -60.3308 17.1958 -61.6219 18.4802 -62.9416C20.5637 -65.0173 22.9293 -68.3128 24.9844 -70.5705C27.2606 -73.0635 29.7936 -74.6504 31.121 -77.5253C31.4208 -78.1745 31.6064 -78.8201 31.767 -79.5335C32.4632 -82.8686 32.4602 -85.9078 32.6175 -88.1978C32.9678 -92.3677 33.1965 -94.5686 32.8011 -97.7327C32.4022 -101.011 30.9793 -103.412 31.3726 -108.406"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-60.7127 74.2073C-59.3674 71.1719 -55.8534 68.8004 -54.2548 65.8257C-53.1522 63.8033 -52.6774 61.4348 -52.8196 59.0627C-52.8801 57.8534 -53.1189 56.7868 -53.3078 55.8557C-53.821 53.3123 -54.6555 52.1956 -55.1009 49.6272C-55.2934 48.582 -55.5393 47.2871 -55.5819 45.9174C-55.6457 43.8627 -55.2066 41.8152 -54.1932 39.8642C-52.6624 36.9145 -48.9378 33.9651 -47.8813 30.4588C-46.9069 27.2522 -48.1154 22.925 -46.7914 19.2048C-46.1597 17.4713 -45.0429 15.9055 -43.4375 14.6216C-41.9427 13.4554 -40.0806 13.0776 -38.354 12.7498C-35.6286 12.2081 -33.7023 11.6912 -30.4595 10.1507C-27.149 8.58535 -23.2748 6.77393 -20.2137 3.79949C-17.595 1.29583 -15.322 -2.77385 -12.3679 -5.51644C-11.0122 -6.74686 -9.67791 -7.93091 -8.25086 -9.07214C-6.26726 -10.6877 -4.05536 -12.3103 -1.9718 -14.386C1.28555 -17.6637 3.62629 -21.7583 6.3808 -25.7744C7.69383 -27.6433 9.12101 -29.5158 10.3163 -31.4953C11.6223 -33.5926 12.5573 -35.8611 13.1213 -38.3009C14.1387 -43.0628 13.7117 -48.7168 14.8396 -52.8651C15.475 -55.2158 16.5204 -56.8707 17.7085 -58.3473C18.7432 -59.6134 19.8921 -60.8831 21.2014 -62.135C23.3563 -64.1215 25.7647 -67.5098 27.8447 -69.6996C30.1673 -72.1713 32.7716 -73.669 34.124 -76.4761C34.4238 -77.1252 34.6557 -77.7494 34.77 -78.4842C35.4662 -81.8194 35.4382 -84.9263 35.6419 -87.195C36.0386 -91.3435 36.3814 -93.5479 36.0538 -96.7369C35.6977 -100.108 34.1856 -102.438 34.6003 -107.478"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-58.6625 75.4918C-57.2957 72.4101 -53.7354 70.06 -52.1368 67.0853C-51.0342 65.0629 -50.5379 62.6481 -50.6802 60.2759C-50.7406 59.0667 -50.9117 57.9751 -51.1005 57.044C-51.546 54.4756 -52.1701 53.5124 -52.5691 50.9654C-52.7616 49.9202 -52.9611 48.6467 -52.9823 47.2306C-53.0461 45.1759 -52.632 43.0607 -51.6186 41.1096C-50.1341 38.1385 -46.3418 35.1642 -45.3316 31.6365C-44.4036 28.4084 -45.7263 24.0848 -44.495 20.3218C-43.9096 18.5668 -42.7715 16.9547 -41.166 15.6708C-39.6498 14.4583 -37.8341 14.0591 -36.1539 13.7098C-33.4998 13.0789 -31.7376 12.43 -28.5197 10.8217C-25.2341 9.18858 -21.335 7.44494 -18.1597 4.46696C-15.4946 1.98471 -13.2252 -2.19912 -10.2462 -4.87394C-8.8905 -6.10436 -7.46345 -7.24558 -6.03641 -8.38681C-4.00644 -9.98096 -1.79454 -11.6036 0.31043 -13.7257C3.58919 -17.0496 5.79083 -21.2085 8.56676 -25.271C9.87978 -27.1399 11.307 -29.0124 12.5701 -31.0169C13.8974 -33.1605 14.9251 -35.3862 15.4428 -37.8474C16.5066 -42.5879 16.076 -48.356 17.3181 -52.5079C17.9998 -54.8371 19.0702 -56.4243 20.3511 -57.8581C21.4321 -59.1028 22.6059 -60.3047 23.9402 -61.4888C26.1201 -63.4075 28.5963 -66.8208 30.7226 -68.9892C33.138 -71.418 35.7922 -72.7802 37.166 -75.6336C37.4658 -76.2828 37.7191 -76.9534 37.8798 -77.6668C38.576 -81.0019 38.548 -84.1089 38.7731 -86.4239C39.2161 -90.551 39.6731 -92.7589 39.3669 -95.9944C39.0536 -99.4581 37.4524 -101.716 37.867 -106.757"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-56.6107 76.7765C-55.2225 73.6484 -51.6158 71.3197 -50.0172 68.345C-48.8932 66.2763 -48.4647 63.8864 -48.4928 61.5107C-48.5533 60.3014 -48.7243 59.2098 -48.8454 58.2538C-49.1981 55.7283 -49.6831 54.8293 -49.9894 52.3251C-50.1141 51.2549 -50.3136 49.9814 -50.3562 48.6117C-50.42 46.557 -50.0059 44.4418 -49.0388 42.4693C-47.5544 39.4982 -43.7406 36.4775 -42.7769 32.9284C-41.8738 29.6325 -43.332 25.3589 -42.1471 21.5744C-41.5618 19.8194 -40.47 18.1859 -38.8431 16.8556C-37.3732 15.6217 -35.4897 15.1975 -33.8809 14.759C-31.2982 14.0389 -29.6536 13.2794 -26.4607 11.6034C-23.2001 9.9025 -19.2082 8.20168 -16.008 5.29147C-13.3429 2.80923 -11.0521 -1.42097 -7.98032 -4.05297C-6.59964 -5.2156 -5.17259 -6.35683 -3.67777 -7.52302C-1.60143 -9.09575 0.656837 -10.697 2.71544 -12.8405C5.94783 -16.1859 8.09955 -20.4803 10.8755 -24.5427C12.2099 -26.4581 13.6371 -28.3305 14.9002 -30.335C16.2739 -32.4572 17.323 -34.7293 17.8871 -37.1691C18.9723 -41.956 18.5631 -47.7705 19.8516 -51.9009C20.5797 -54.2087 21.7428 -55.7531 23.0486 -57.1191C24.1546 -58.296 25.3534 -59.4302 26.7341 -60.5928C28.9852 -62.4223 31.4828 -65.882 33.6556 -68.029C36.0959 -70.39 38.8214 -71.663 40.263 -74.5414C40.6091 -75.1692 40.8161 -75.8612 40.9768 -76.5746C41.673 -79.9097 41.62 -83.0844 41.8915 -85.378C42.3809 -89.4837 42.952 -91.6952 42.7136 -94.9556C42.4217 -98.4657 40.7527 -100.699 41.1888 -105.786"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-54.5132 78.0822C-53.1714 74.9327 -49.4255 72.6682 -47.8055 69.6471C-46.6815 67.5784 -46.2316 65.1422 -46.2597 62.7665C-46.2524 61.5322 -46.4698 60.4193 -46.5445 59.4847C-46.7831 56.9555 -47.104 56.1885 -47.364 53.7058C-47.4423 52.657 -47.6418 51.3835 -47.6629 49.9674C-47.7268 47.9127 -47.4517 45.7333 -46.3706 43.7573C-44.8148 40.8753 -41.0509 37.7191 -40.1336 34.1486C-39.2769 30.8313 -40.8956 26.5398 -39.7107 22.7553C-39.1717 20.9789 -38.0121 19.3204 -36.4994 17.9937C-35.0081 16.7134 -33.1496 16.2215 -31.5871 15.7616C-29.0293 14.9737 -27.5952 14.0608 -24.4273 12.317C-21.1916 10.5483 -17.1284 8.93665 -13.9068 5.98007C-11.1953 3.51924 -8.88308 -0.757332 -5.76496 -3.36791C-4.38428 -4.53055 -2.91086 -5.65036 -1.43745 -6.77018C0.638891 -8.34291 2.94352 -9.92275 5.02353 -12.1126C8.27734 -15.5043 10.29 -19.863 13.0409 -23.9933C14.329 -25.93 15.8025 -27.781 17.1334 -29.8105C18.5071 -31.9327 19.6026 -34.1834 20.1667 -36.6232C21.3197 -41.435 20.8392 -47.3387 22.2204 -51.4263C23.0162 -53.7591 24.1829 -55.1893 25.5351 -56.5339C26.6661 -57.643 27.9112 -58.7558 29.3168 -59.8506C31.6393 -61.591 34.1797 -65.1434 36.3774 -67.2226C38.8641 -69.5622 41.661 -70.746 43.1275 -73.5566C43.4736 -74.1844 43.6807 -74.8764 43.8413 -75.5898C44.5375 -78.9249 44.4596 -82.1674 44.7774 -84.4396C45.2669 -88.5453 46.0199 -90.7853 45.8064 -93.9779C45.5574 -97.5807 43.7992 -99.7427 44.2103 -104.897"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-52.4623 79.3666C-51.0991 76.1707 -47.3069 73.9277 -45.6869 70.9066C-44.5629 68.8379 -44.0916 66.3552 -44.1197 63.9795C-44.1124 62.7453 -44.262 61.6074 -44.3582 60.7192C-44.5289 58.1651 -44.6858 57.5301 -44.853 55.0902C-44.9313 54.0414 -45.0631 52.743 -45.0842 51.3268C-45.1266 49.2258 -44.898 47.0249 -43.8168 45.0489C-42.261 42.167 -38.4972 39.0107 -37.6047 35.3724C-36.7944 32.0337 -38.5273 27.7458 -37.3887 23.9399C-36.8284 22.1172 -35.7365 20.4836 -34.2024 19.1105C-32.7112 17.8302 -30.8776 17.2705 -29.34 16.7428C-26.8322 15.8194 -25.5621 14.7744 -22.4406 13.0093C-19.2513 11.2191 -15.0739 9.60395 -11.8059 6.66879C-9.09445 4.20795 -6.7608 -0.114979 -3.59631 -2.70415C-2.16926 -3.84538 -0.695852 -4.9652 0.823932 -6.06361C2.94664 -7.61493 5.25127 -9.19476 7.33128 -11.3846C10.5851 -14.7764 12.48 -19.2456 15.2524 -23.4222C16.5405 -25.3589 18.0818 -27.235 19.3913 -29.2181C20.8328 -31.3652 21.9282 -33.6159 22.5386 -36.0343C23.7131 -40.8925 23.254 -46.8425 24.6815 -50.9088C25.5238 -53.2201 26.7368 -54.6289 28.1603 -55.8843C29.3162 -56.9257 30.6077 -58.017 32.0383 -59.0441C34.3858 -60.7166 36.994 -64.294 39.2381 -66.3518C41.7711 -68.67 44.6642 -69.6968 46.1522 -72.5538C46.4983 -73.1816 46.7054 -73.8736 46.866 -74.587C47.5622 -77.9221 47.4843 -81.1646 47.7772 -83.5046C48.3344 -87.6352 49.1801 -89.8324 48.9881 -93.0714C48.7818 -96.7669 46.9344 -98.8576 47.3456 -104.012"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-50.4116 80.651C-49.027 77.4088 -45.1884 75.1871 -43.522 72.1875C-42.3766 70.0724 -41.9267 67.6361 -41.9334 65.214C-41.9261 63.9798 -42.0757 62.8419 -42.1041 61.9287C-42.1821 59.4174 -42.1535 58.8681 -42.2065 56.4246C-42.2385 55.3973 -42.3702 54.0988 -42.37 52.6363C-42.4124 50.5353 -42.1837 48.3344 -41.1489 46.337C-39.5931 43.455 -35.8079 40.2524 -34.9618 36.5927C-34.1765 33.1863 -36.0235 28.9018 -34.9527 25.1209C-34.4387 23.2768 -33.3255 21.5969 -31.7914 20.2238C-30.325 18.8757 -28.4451 18.3374 -26.9789 17.7205C-24.496 16.7293 -23.39 15.5523 -20.3148 13.7657C-17.1505 11.9078 -12.9481 10.3604 -9.61235 7.40031C-6.85454 4.96089 -4.54584 0.570174 -1.28862 -1.97618C0.138429 -3.1174 1.65821 -4.21581 3.22437 -5.2928C5.39344 -6.82271 7.74444 -8.38114 9.7995 -10.6388C13.0283 -14.0983 14.8305 -18.6104 17.5779 -22.8548C18.866 -24.7915 20.4288 -26.7139 21.7382 -28.697C23.1797 -30.8441 24.368 -33.052 24.9534 -35.5381C26.1956 -40.4213 25.7115 -46.4391 27.2318 -50.4626C28.0741 -52.7739 29.3585 -54.0935 30.8747 -55.3061C32.077 -56.326 33.3935 -57.3496 34.8704 -58.3553C37.2892 -59.9386 39.9652 -63.5409 42.1878 -65.5524C44.7458 -67.8028 47.7103 -68.7404 49.2446 -71.576C49.5908 -72.2038 49.8441 -72.8744 50.0262 -73.6341C50.7687 -76.9478 50.6195 -80.2796 50.9588 -82.5981C51.5623 -86.7073 52.5223 -88.9081 52.398 -92.172C52.2132 -95.9139 50.3194 -98.026 50.7305 -103.18"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-48.3616 81.9358C-47.0233 78.6721 -43.0706 76.4469 -41.4256 73.4937C-40.2802 71.3786 -39.8089 68.8959 -39.8156 66.4739C-39.8083 65.2396 -39.9365 64.0553 -39.9185 63.1636C-39.8824 60.6487 -39.7575 60.2564 -39.6964 57.8094C-39.6819 56.8034 -39.7923 55.4586 -39.8384 53.9747C-39.8808 51.8736 -39.6985 49.6514 -38.6423 47.6076C-37.1329 44.7042 -33.2798 41.4767 -32.5265 37.7741C-31.7875 34.3463 -33.7487 30.0654 -32.7029 26.2166C-32.1889 24.3725 -31.122 22.6712 -29.5665 21.2518C-28.0788 19.8573 -26.2238 19.2512 -24.8039 18.6129C-22.3924 17.5325 -21.4504 16.2235 -18.4002 14.3692C-15.2608 12.4435 -10.9658 10.9389 -7.58361 8.00021C-4.8258 5.56078 -2.4957 1.12369 0.854263 -1.37984C2.32768 -2.49965 3.87242 -3.53028 5.43857 -4.60727C7.63261 -6.06941 10.005 -7.67419 12.0601 -9.93182C15.2889 -13.3914 16.9948 -18.0604 19.7422 -22.3048C21.0517 -24.2879 22.593 -26.1639 23.9703 -28.172C25.4582 -30.2977 26.6678 -32.5519 27.2996 -35.0167C28.5632 -39.9462 28.1006 -46.0104 29.6886 -50.0588C30.5773 -52.3487 31.9544 -53.6255 33.4492 -54.7917C34.6765 -55.7439 36.0179 -56.6997 37.5412 -57.6839C39.985 -59.1995 42.7287 -62.8268 44.9977 -64.8168C47.6021 -67.0458 50.6379 -67.8943 52.2186 -70.7084C52.5647 -71.3362 52.8181 -72.0068 53.0002 -72.7665C53.7427 -76.0803 53.5935 -79.412 53.9542 -81.7769C54.5577 -85.8861 55.6782 -88.069 55.5539 -91.3329C55.4119 -95.1676 53.3826 -97.2297 53.8151 -102.431"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-46.3106 83.2206C-44.9509 79.9105 -40.9518 77.7068 -39.3068 74.7535C-38.115 72.6598 -37.6687 70.1094 -37.629 67.7087C-37.6003 66.4281 -37.6821 65.2652 -37.6641 64.3735C-37.5602 61.8337 -37.2249 61.5948 -37.1175 59.1691C-37.0816 58.1168 -37.167 56.8398 -37.2131 55.3559C-37.2555 53.2548 -37.0518 50.9862 -36.042 48.921C-34.5326 46.0176 -30.6581 42.7437 -29.9512 39.0198C-29.2835 35.5027 -31.3339 31.2931 -30.3344 27.423C-29.8668 25.5575 -28.7321 23.8312 -27.223 22.3903C-25.7352 20.9959 -23.9052 20.322 -22.5317 19.6623C-20.1701 18.4463 -19.3673 17.0731 -16.3884 15.1296C-13.2739 13.1361 -8.88611 11.6744 -5.52538 8.78202C-2.7212 6.36401 -0.369691 1.88056 2.98027 -0.622972C4.50006 -1.72138 6.0448 -2.752 7.61095 -3.829C9.85136 -5.26972 12.2238 -6.87451 14.3466 -9.15709C17.5969 -12.663 19.1636 -17.3963 21.9325 -21.687C23.242 -23.6701 24.8047 -25.5925 26.182 -27.6006C27.6912 -29.7727 28.9258 -31.9591 29.5791 -34.4702C30.889 -39.3784 30.4228 -45.5567 32.0573 -49.5837C32.9923 -51.8522 34.3943 -53.0612 35.9819 -54.1846C37.2341 -55.069 38.6219 -56.0033 40.1702 -56.9198C42.6853 -58.3462 45.4718 -62.0662 47.8122 -63.9671C50.4629 -66.1747 53.595 -66.8662 55.1757 -69.6803C55.5218 -70.3081 55.7966 -71.025 55.9573 -71.7384C56.7212 -75.0985 56.5256 -78.4516 56.9541 -80.8415C57.604 -84.9293 58.86 -87.1621 58.7821 -90.4046C58.6829 -94.3321 56.5644 -96.3229 56.9969 -101.524"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-44.2597 84.5047C-42.8786 81.1483 -38.7868 78.9874 -37.1418 76.0341C-35.95 73.9404 -35.4823 71.3436 -35.4426 68.9429C-35.4139 67.6624 -35.4957 66.4995 -35.4313 65.6291C-35.2347 63.1322 -34.7603 62.9574 -34.5601 60.5746C-34.4779 59.5438 -34.5418 58.2204 -34.588 56.7364C-34.6304 54.6354 -34.473 52.3453 -33.4168 50.3016C-31.9538 47.3768 -28.033 44.1243 -27.351 40.3325C-26.7297 36.7941 -28.8942 32.5881 -27.9411 28.6965C-27.4735 26.831 -26.3852 25.0833 -24.901 23.5747C-23.4382 22.1124 -21.6082 21.4385 -20.2597 20.7111C-17.923 19.4273 -17.3306 17.9007 -14.3303 15.9108C-11.2408 13.8495 -6.82803 12.4555 -3.35315 9.55966C-0.502608 7.16306 1.82394 2.61183 5.26664 0.151121C6.76501 -0.900916 8.37754 -1.9565 9.96865 -2.96572C12.2091 -4.40644 14.6278 -5.98982 16.7507 -8.2724C19.9546 -11.7997 21.45 -16.6222 24.1939 -20.9807C25.457 -22.9852 27.0661 -24.8862 28.4897 -26.8729C30.0454 -29.0236 31.3014 -31.2564 32.001 -33.7461C33.3788 -38.6792 32.8412 -44.9467 34.6148 -48.9094C35.6175 -51.2029 37.0446 -52.3441 38.6571 -53.3997C39.9557 -54.2627 41.3684 -55.1293 42.9417 -55.978C45.4817 -57.3366 48.336 -61.0816 50.7228 -62.961C53.4448 -65.0794 56.6018 -65.7031 58.2504 -68.5423C58.5965 -69.17 58.8713 -69.8869 59.0319 -70.6003C59.7959 -73.9604 59.6217 -77.3599 60.0038 -79.7712C60.7001 -83.8376 62.0702 -86.074 62.0137 -89.3629C61.936 -93.3366 59.7496 -95.3025 60.2036 -100.55"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-42.2087 85.7893C-40.874 82.4115 -36.668 80.247 -35.0231 77.2937C-33.8099 75.1536 -33.3635 72.6032 -33.3025 70.1562C-33.2737 68.8756 -33.3341 67.6663 -33.2234 66.8174C-32.9126 64.3169 -32.2741 64.2742 -31.9812 61.9342C-31.8526 60.9247 -31.9166 59.6013 -31.9413 58.071C-31.9837 55.97 -31.7336 53.7228 -30.7951 51.5684C-29.5389 48.6043 -25.3898 45.3447 -24.7542 41.5316C-24.1793 37.9717 -26.4579 33.7692 -25.5976 29.8348C-25.1763 27.9479 -24.088 26.2002 -22.6039 24.6916C-21.1411 23.2293 -19.2682 22.4627 -18.0125 21.6925C-15.7257 20.2731 -15.3188 18.6609 -12.3434 16.6032C-9.3003 14.5205 -4.77337 13.123 -1.29849 10.2271C1.55205 7.83049 3.92143 3.18653 7.43546 0.815008C8.9802 -0.215618 10.5713 -1.22483 12.2302 -2.259C14.517 -3.67832 16.9821 -5.24029 19.08 -7.59065C22.3053 -11.1643 23.6831 -16.0974 26.4055 -20.4096C27.69 -22.4604 29.2992 -24.3614 30.7691 -26.3267C32.3248 -28.4773 33.6486 -30.7351 34.3731 -33.157C35.7723 -38.1365 35.1634 -44.4932 37.0761 -48.3917C38.1716 -50.6424 39.5772 -51.7372 41.2825 -52.75C42.606 -53.5452 44.0437 -54.344 45.6633 -55.1713C48.2747 -56.4407 51.1505 -60.2321 53.5836 -62.0901C56.352 -64.1871 59.5803 -64.7216 61.2752 -67.5393C61.6677 -68.1457 61.9425 -68.8626 62.0782 -69.6438C62.8421 -73.0039 62.6216 -76.4248 63.0715 -78.861C63.8355 -82.9524 65.2984 -85.1459 65.3097 -88.4598C65.2748 -92.5263 62.9993 -94.4208 63.4068 -99.6894"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-40.159 87.0736C-38.8029 83.6495 -34.5505 81.5064 -32.8592 78.5745C-31.646 76.4344 -31.1783 73.8376 -31.1172 71.3906C-31.067 70.0637 -31.1025 68.9222 -30.9703 68.0269C-30.5454 65.5228 -29.7892 65.5907 -29.4036 63.2936C-29.275 62.2841 -29.2712 60.9357 -29.2959 59.4054C-29.3169 57.258 -29.0668 55.0108 -28.196 52.8814C-26.9863 49.8959 -22.7694 46.6113 -22.1801 42.7768C-21.6302 39.1491 -24.0693 34.9288 -23.2304 31.0408C-22.8091 29.1539 -21.7458 27.3384 -20.2616 25.8298C-18.8238 24.2997 -16.9509 23.5331 -15.7201 22.6951C-13.4583 21.208 -13.2154 19.4637 -10.3114 17.3168C-7.29327 15.1663 -2.69503 13.858 0.847625 10.9372C3.74454 8.56198 6.13533 3.87164 9.64936 1.50013C11.1941 0.469499 12.8316 -0.518304 14.5368 -1.53107C16.87 -2.92897 19.3351 -4.49094 21.433 -6.8413C24.6119 -10.4364 25.9184 -15.4587 28.6159 -19.8386C29.9004 -21.8895 31.5095 -23.7905 33.0009 -25.8021C34.6029 -27.9313 35.9731 -30.1677 36.6727 -32.6574C38.1396 -37.6618 37.5058 -44.0863 39.4648 -47.9634C40.6067 -50.1927 42.0587 -51.2661 43.8353 -52.1897C45.1838 -52.9171 46.6678 -53.6945 48.3124 -54.454C51.0165 -55.6806 53.9137 -59.5183 56.3503 -61.2622C59.1652 -63.3378 62.4898 -63.7153 64.1846 -66.5331C64.5771 -67.1394 64.8519 -67.8564 65.034 -68.6161C65.7979 -71.9762 65.5988 -75.4435 66.0487 -77.8797C66.8127 -81.9711 68.4362 -84.1467 68.4938 -87.4392C68.548 -91.577 66.137 -93.4216 66.566 -98.7366"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-38.0603 88.3797C-36.6827 84.9091 -32.384 82.7874 -30.6927 79.8556C-29.4795 77.7155 -29.0367 75.0509 -28.9293 72.6253C-28.8791 71.2984 -28.8931 70.1105 -28.7146 69.2366C-28.2433 66.754 -27.2553 66.9289 -26.8018 64.6068C-26.6055 63.5724 -26.6231 62.2704 -26.6478 60.7401C-26.6688 58.5927 -26.4651 56.324 -25.5729 54.1483C-24.3632 51.1628 -20.1463 47.8782 -19.582 43.9759C-19.0784 40.3268 -21.6317 36.11 -20.8391 32.2006C-20.4642 30.2923 -19.3545 28.4982 -17.8953 26.9218C-16.4575 25.3918 -14.5882 24.511 -13.4037 23.6516C-11.1918 22.0289 -11.113 20.1526 -8.23044 18.0521C-5.23724 15.8339 -0.592617 14.5469 2.99641 11.6475C5.87191 9.31869 8.30552 4.53562 11.9123 2.20692C13.5034 1.1977 15.1872 0.23131 16.8247 -0.756494C19.2042 -2.13299 21.6694 -3.69495 23.7887 -6.09168C26.989 -9.73314 28.1563 -14.8196 30.8753 -19.246C32.1598 -21.2968 33.7903 -23.2442 35.3281 -25.2344C36.9515 -27.41 38.368 -29.625 39.114 -32.0933C40.6024 -37.144 40.0827 -43.5721 42.0417 -47.4492C43.1836 -49.6784 44.7533 -50.6413 46.5299 -51.5649C47.9247 -52.2709 49.4337 -52.9805 51.1033 -53.6722C53.8323 -54.8311 56.7973 -58.6937 59.2803 -60.4162C62.1201 -62.424 65.5374 -62.7587 67.2787 -65.555C67.6712 -66.1614 67.9459 -66.8783 68.128 -67.6381C68.892 -70.9982 68.6678 -74.5332 69.1641 -76.948C69.9745 -81.018 71.7799 -83.2222 71.7912 -86.536C71.8668 -90.7202 69.388 -92.5399 69.8384 -97.9012"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-36.0087 89.6643C-34.6775 86.1723 -30.2183 84.0685 -28.5733 81.1152C-27.3601 78.9751 -26.9173 76.3106 -26.7421 73.86C-26.6919 72.5331 -26.6382 71.3202 -26.4811 70.4927C-25.8957 68.0065 -24.7435 68.3135 -24.2438 66.0128C-24.001 64.9998 -23.9972 63.6514 -24.0219 62.1211C-24.0429 59.9737 -23.8392 57.7051 -22.972 55.4615C-21.8086 52.4546 -17.5239 49.1451 -17.006 45.2214C-16.5274 41.5045 -19.2162 37.3376 -18.47 33.4068C-18.0737 31.4521 -17.0104 29.6366 -15.5976 28.0388C-14.1383 26.4624 -12.3154 25.5602 -11.1559 24.633C-9.01532 22.9211 -9.10059 20.9128 -6.24295 18.7445C-3.2747 16.4585 1.44123 15.2608 5.07663 12.3828C7.9985 10.0754 10.4072 5.22451 14.1067 2.93863C15.6978 1.92942 17.4066 1.0308 19.1118 0.0180398C21.4913 -1.35845 24.0492 -2.87759 26.1222 -5.29573C29.3225 -8.9372 30.3721 -14.1343 33.1125 -18.607C34.397 -20.6579 36.0739 -22.5838 37.5867 -24.6418C39.2565 -26.796 40.673 -29.0109 41.4404 -31.5256C42.9965 -36.6014 42.4519 -43.0972 44.4787 -46.9992C45.6206 -49.2285 47.283 -50.1485 49.1309 -50.9829C50.5507 -51.6212 52.1061 -52.3093 53.822 -52.9797C56.6224 -54.0493 59.6088 -57.9583 62.1381 -59.6594C65.0243 -61.6458 68.4916 -61.8449 70.2792 -64.6198C70.6717 -65.2262 70.9465 -65.9431 71.1499 -66.7493C71.9139 -70.1093 71.6898 -73.6444 72.2074 -76.1056C73.0642 -80.1541 74.9837 -82.3618 75.0628 -85.7007C75.1813 -89.9776 72.6347 -91.7723 73.0173 -97.1086"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-33.9595 90.9489C-32.607 87.4105 -28.1228 85.3745 -26.41 82.3962C-25.1754 80.2098 -24.7326 77.5453 -24.5574 75.0947C-24.4858 73.7214 -24.4535 72.5549 -24.2286 71.7024C-23.5291 69.2127 -22.2592 69.6303 -21.6667 67.3724C-21.3776 66.3808 -21.3738 65.0324 -21.3771 63.4557C-21.3981 61.3083 -21.2193 58.9719 -20.3735 56.7747C-19.2101 53.7678 -14.904 50.4119 -14.4789 46.4454C-14.0466 42.7071 -16.8496 38.5438 -16.1498 34.5916C-15.7998 32.6154 -14.7365 30.8 -13.3023 29.1558C-11.868 27.5116 -10.0236 26.5631 -8.93193 25.6608C-6.79491 23.8348 -7.06564 21.7408 -4.27932 19.4833C-1.33604 17.1296 3.47264 15.9747 7.10803 13.0966C10.0299 10.7892 12.46 5.89198 16.2058 3.62752C17.8433 2.63972 19.5521 1.7411 21.3038 0.749747C23.7296 -0.605333 26.2875 -2.12448 28.3818 -4.58899C31.5572 -8.29823 32.5355 -13.5845 35.2295 -18.0786C36.4891 -20.1973 38.2123 -22.1018 39.7501 -24.092C41.4199 -26.2462 42.9042 -28.4861 43.7179 -30.9794C45.2741 -36.0551 44.7259 -42.6651 46.8455 -46.5243C48.0551 -48.7785 49.7425 -49.6308 51.6617 -50.376C53.1065 -50.9465 54.6869 -51.5669 56.4491 -52.2158C59.2745 -53.2176 62.3286 -57.1516 64.9044 -58.8313C67.8369 -60.7963 71.4004 -60.8385 73.2558 -63.6383C73.6483 -64.2447 73.9231 -64.9616 74.1266 -65.7677C74.8905 -69.1278 74.6415 -72.7306 75.1591 -75.1919C76.0159 -79.2404 78.0959 -81.4302 78.175 -84.769C78.3363 -89.1387 75.7005 -90.8621 76.1045 -96.2448"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-31.8861 92.1868C-30.5122 88.6021 -25.9816 86.5875 -24.2688 83.6092C-23.0342 81.4228 -22.57 78.7119 -22.3948 76.2613C-22.2769 74.9094 -22.2695 73.6752 -21.9983 72.8441C-21.2059 70.3972 -19.7506 70.9004 -19.0654 68.6853C-18.7763 67.6937 -18.7047 66.3204 -18.7543 64.7223C-18.7754 62.5749 -18.5966 60.2385 -17.7758 57.9735C-16.6587 54.9452 -12.2849 51.5644 -11.906 47.5764C-11.4987 43.7704 -14.4373 39.657 -13.7838 35.6833C-13.4803 33.6858 -12.3956 31.8239 -10.9827 30.2261C-9.54843 28.5819 -7.72902 27.5656 -6.66228 26.5956C-4.57517 24.634 -5.03139 22.4544 -2.27003 20.1291C0.626885 17.754 5.54971 16.5955 9.23148 13.7389C12.1997 11.4529 14.6512 6.5093 18.4434 4.26624C20.0809 3.27844 21.8361 2.40123 23.6127 1.47766C26.0849 0.143986 28.6428 -1.37515 30.7835 -3.81825C33.9589 -7.52749 34.8195 -12.9244 37.5349 -17.4649C38.7945 -19.5835 40.5177 -21.4881 42.1233 -23.5032C43.8608 -25.6824 45.3701 -27.8545 46.1589 -30.4155C47.7828 -35.5162 47.2097 -42.194 49.3756 -46.0318C50.6316 -48.2646 52.3439 -49.0491 54.3096 -49.7729C55.8007 -50.3219 57.406 -50.8746 59.1932 -51.4557C62.0899 -52.3684 65.2332 -56.3737 67.7876 -58.007C70.745 -59.9042 74.4013 -59.9035 76.2817 -62.6356C76.6742 -63.2419 77.0167 -63.9838 77.1524 -64.765C77.9628 -68.1037 77.6424 -71.7957 78.2064 -74.2355C79.131 -78.309 81.3251 -80.5024 81.4506 -83.8198C81.6333 -88.2359 78.8833 -89.9557 79.3551 -95.3633"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-29.8355 93.4715C-28.5079 89.8654 -23.8632 87.8472 -22.1505 84.869C-20.8695 82.7039 -20.4517 79.9716 -20.255 77.4747C-20.1371 76.1227 -20.0619 74.8636 -19.7907 74.0325C-18.9306 71.5606 -17.3112 72.1958 -16.5332 70.0236C-16.1763 69.007 -16.1262 67.6801 -16.1544 66.0356C-16.1754 63.8882 -16.0216 61.484 -15.2008 59.2191C-14.1052 56.2371 -9.70988 52.8099 -9.35603 48.7542C-9.04145 44.9053 -12.0264 40.7705 -11.4407 36.8218C-11.1372 34.8243 -10.0988 32.941 -8.66452 31.2968C-7.20884 29.6063 -5.36803 28.5436 -4.39403 27.5307C-2.37825 25.4799 -2.99853 23.1684 -0.215759 20.7967C2.6562 18.3537 7.60399 17.2631 11.3535 14.3815C14.3218 12.0955 16.8161 7.05915 20.6796 4.90529C22.3421 3.98527 24.1187 3.0617 25.9416 2.15953C28.4602 0.847276 31.0645 -0.650454 33.1802 -3.16133C36.377 -6.91695 37.0985 -12.3781 39.7889 -16.9863C41.0485 -19.105 42.7932 -21.0559 44.3987 -23.071C46.1363 -25.2502 47.7133 -27.4473 48.5271 -29.9405C50.2188 -35.0662 49.6207 -41.8117 51.8793 -45.6067C53.1817 -47.8181 54.9404 -48.5812 56.9774 -49.2158C58.4935 -49.6971 60.1452 -50.2283 61.9573 -50.7416C64.879 -51.5865 68.0901 -55.6168 70.6908 -57.2287C73.741 -59.083 77.4008 -58.9682 79.349 -61.7253C79.8092 -62.3566 80.084 -63.0735 80.2661 -63.8333C81.0764 -67.172 80.756 -70.864 81.3414 -73.3502C82.3124 -77.4022 84.6207 -79.5992 84.8139 -82.9415C85.0394 -87.4503 82.2003 -89.0988 82.6257 -94.5279"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-27.785 94.7561C-26.436 91.1037 -21.7449 89.1069 -19.9858 86.1501C-18.6834 83.9387 -18.2656 81.2063 -18.0689 78.7094C-17.9296 77.3111 -17.8759 76.0983 -17.5368 75.2422C-16.5626 72.7668 -14.8006 73.5804 -13.9548 71.3833C-13.5515 70.3881 -13.4799 69.0148 -13.5296 67.4167C-13.5292 65.2229 -13.3968 62.8651 -12.6223 60.5787C-11.5731 57.5754 -7.11002 54.1232 -6.80254 50.046C-6.53433 46.1757 -9.63343 42.0445 -9.11906 38.0066C-8.86188 35.9877 -7.75574 34.0794 -6.36784 32.4138C-4.95853 30.7019 -3.0963 29.5928 -2.14725 28.5122C-0.18139 26.3259 -0.940772 23.95 1.74926 21.5356C4.59626 19.0248 9.63678 17.977 13.4327 15.1168C16.4473 12.8522 18.9166 7.74809 22.8266 5.61564C24.489 4.69562 26.3369 3.86124 28.1135 2.93767C30.6785 1.64682 33.2828 0.149083 35.3985 -2.3618C38.5489 -6.13882 39.1991 -11.6891 41.9109 -16.3437C43.1919 -18.5088 44.9366 -20.4597 46.5885 -22.4534C48.3724 -24.6112 49.9709 -26.8546 50.7846 -29.3479C52.4977 -34.5199 51.9211 -41.3118 54.2261 -45.0854C55.5748 -47.2754 57.3585 -47.9707 59.4882 -48.5624C61.0293 -48.9759 62.7273 -49.4857 64.5644 -49.9313C67.5574 -50.687 70.7899 -54.7637 73.4156 -56.3078C76.5122 -58.1407 80.2683 -57.869 82.2378 -60.6724C82.6981 -61.3037 82.9729 -62.0206 83.1763 -62.8267C83.9867 -66.1654 83.6413 -69.9252 84.2731 -72.39C85.2441 -76.4421 87.7129 -78.6211 87.9275 -82.0099C88.1959 -86.6114 85.2675 -88.1886 85.7144 -93.664"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-25.7336 96.0405C-24.3631 92.3416 -19.5793 90.3877 -17.8666 87.4094C-16.5642 85.198 -16.1249 82.4193 -15.8819 79.9438C-15.7426 78.5455 -15.7138 77.2649 -15.3035 76.4981C-14.051 74.1511 -12.4994 74.8113 -11.3754 72.7426C-10.833 71.8117 -10.8542 70.3955 -10.8824 68.7511C-10.8821 66.5573 -10.796 64.178 -9.95379 61.8667C-8.90453 58.8634 -4.42006 55.3648 -4.15894 51.2662C-3.91569 47.3282 -7.19672 43.2254 -6.68235 39.1875C-6.42516 37.1686 -5.36539 35.239 -4.00245 33.5056C-2.57172 31.7473 -0.734455 30.5704 0.168219 29.4684C2.08417 27.1465 1.16072 24.6387 3.80438 22.2028C6.62642 19.6242 11.7383 18.6656 15.5342 15.8054C18.5488 13.5408 21.0395 8.39035 25.0422 6.30072C26.751 5.40211 28.5989 4.56773 30.4219 3.66556C32.9869 2.37472 35.6375 0.898393 37.7747 -1.65885C40.9251 -5.43588 41.479 -11.1432 44.1908 -15.7978C45.4718 -17.9628 47.2164 -19.9137 48.8684 -21.9074C50.6523 -24.0652 52.2971 -26.2873 53.1787 -28.8055C54.9596 -34.0025 54.3579 -40.8621 56.7307 -44.6607C58.1258 -46.8292 59.9559 -47.5031 62.1105 -48.0271C63.6979 -48.4192 65.3995 -48.8149 67.283 -49.239C70.3473 -49.9055 73.6227 -54.0749 76.2733 -55.5512C79.3948 -57.3164 83.2437 -57.0018 85.2382 -59.7375C85.6984 -60.3688 85.9732 -61.0857 86.1767 -61.8918C86.987 -65.2305 86.6417 -68.9903 87.2699 -71.5692C88.2873 -75.5999 90.8702 -77.7825 91.1312 -81.1498C91.421 -85.7977 88.4249 -87.3499 88.8931 -92.8718"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-23.6841 97.3251C-22.36 93.6049 -17.462 91.6474 -15.7243 88.7369C-14.422 86.5255 -13.9827 83.7468 -13.7183 81.2249C-13.5789 79.8266 -13.5288 78.4997 -13.072 77.7542C-11.7054 75.4037 -10.0362 76.1745 -8.81942 74.1486C-8.27705 73.2177 -8.27679 71.7552 -8.25867 70.1322C-8.25829 67.9384 -8.15086 65.5128 -7.35498 63.18C-6.35209 60.1553 -1.82124 56.6781 -1.58509 52.5118C-1.3882 48.5523 -4.78338 44.4531 -4.31538 40.3938C-4.10457 38.3534 -3.02338 36.3775 -1.66044 34.6441C-0.208309 32.8394 1.5826 31.6411 2.46031 30.4713C4.30494 28.0602 3.21742 25.4204 5.88249 22.9381C8.67958 20.2918 13.8378 19.3546 17.7264 16.5372C20.7874 14.294 23.2996 9.09719 27.3486 7.02897C29.1038 6.15176 30.9517 5.31739 32.821 4.43663C35.4324 3.1672 38.083 1.69088 40.2202 -0.866372C43.392 -4.68977 43.8068 -10.4613 46.4937 -15.1837C47.7746 -17.3487 49.5407 -19.346 51.239 -21.3183C53.0907 -23.501 54.7356 -25.7231 55.6171 -28.2413C57.4194 -33.4846 56.8391 -40.3907 59.3047 -44.1464C60.7676 -46.3399 62.669 -46.9246 64.8486 -47.3808C66.4609 -47.7051 68.2089 -48.0794 70.1174 -48.4358C73.2066 -49.0345 76.5497 -53.2288 79.2467 -54.6838C82.4146 -56.4275 86.3134 -55.9774 88.3757 -58.738C88.8359 -59.3693 89.1107 -60.0862 89.3142 -60.8923C90.1245 -64.231 89.7542 -68.0586 90.4288 -70.6161C91.4926 -74.6254 94.2575 -76.8365 94.4721 -80.2252C94.8046 -84.9658 91.7408 -86.4931 92.1412 -91.99"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-21.5856 98.6308C-20.2402 94.8642 -15.2958 92.9281 -13.5581 90.0176C-12.2557 87.8062 -11.7951 84.9812 -11.5307 82.4593C-11.3699 81.0146 -11.2484 79.7768 -10.8166 78.9636C-9.56772 76.5025 -7.50254 77.5124 -6.19306 75.5293C-5.58292 74.5734 -5.60407 73.1573 -5.56454 71.4879C-5.56416 69.2941 -5.50309 66.8471 -4.70721 64.5143C-3.70433 61.4896 0.847931 57.9661 1.03772 53.7783C1.20965 49.7511 -2.32109 45.7018 -1.89946 41.6211C-1.68864 39.5807 -0.653834 37.5833 0.730519 35.8035C2.13628 33.9774 3.97001 32.6865 4.77995 31.5416C6.57465 28.995 5.34803 26.2909 7.94178 23.7194C10.6925 21.0517 15.9649 20.1109 19.8535 17.2936C22.9145 15.0504 25.4481 9.80717 29.5435 7.76036C31.2986 6.88316 33.1715 6.11656 35.0872 5.25722C37.7235 4.05556 40.4419 2.55428 42.5541 -0.070743C45.6796 -3.91555 46.0231 -9.77627 48.7313 -14.545C49.9659 -16.7315 51.7784 -18.7073 53.5444 -20.7046C55.3962 -22.8873 57.1337 -25.0665 58.0367 -27.6311C59.8853 -32.8531 59.323 -39.9196 61.8135 -43.6076C63.2764 -45.8011 65.2491 -46.2966 67.5214 -46.71C69.1124 -46.9879 70.9531 -47.3194 72.9079 -47.6543C76.0685 -48.1639 79.433 -52.4046 82.1764 -53.8381C85.3906 -55.5604 89.3857 -54.9533 91.448 -57.7139C91.9082 -58.3452 92.2294 -59.0408 92.4329 -59.8469C93.2432 -63.1856 92.8943 -67.0595 93.5689 -69.617C94.654 -73.6726 97.558 -75.8195 97.8404 -79.2332C98.2622 -84.0452 95.0628 -85.5225 95.4846 -91.0657"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-19.5359 99.9155C-18.1691 96.1025 -13.1783 94.1879 -11.4406 91.2774C-10.1168 89.0197 -9.65618 86.1946 -9.39178 83.6727C-9.231 82.228 -9.04173 80.9653 -8.60996 80.1521C-7.2683 77.7338 -4.85358 78.9613 -3.66179 76.8676C-3.09802 75.8903 -3.00503 74.4706 -2.98691 72.8476C-2.98652 70.6538 -2.92546 68.2068 -2.15454 65.8063C-1.19802 62.7601 3.42202 59.2116 3.51908 54.9811C3.64463 50.9324 0.0211533 46.8403 0.375007 42.7845C0.560865 40.6764 1.57427 38.7254 2.95862 36.9456C4.3858 35.0731 6.17315 33.7608 6.95813 32.5481C8.68151 29.9123 7.29083 27.0762 9.85961 24.437C12.5854 21.7015 17.9291 20.8498 21.8855 18.0075C24.9928 15.7858 27.5015 10.4748 31.6896 8.4708C33.4911 7.615 35.364 6.8484 37.2797 5.98906C39.9624 4.80881 42.6808 3.30754 44.793 0.68251C47.9399 -3.20866 48.1656 -9.17996 50.8275 -13.9701C52.0836 -16.2029 53.9424 -18.1574 55.6621 -20.1761C57.5602 -22.3374 59.3191 -24.563 60.2221 -27.1276C62.0922 -32.3959 61.5048 -39.5302 64.0881 -43.1754C65.5973 -45.3475 67.6164 -45.8215 69.9137 -46.1671C71.551 -46.4237 73.3953 -46.641 75.3965 -46.9545C78.582 -47.3963 82.0143 -51.662 84.804 -53.074C88.0896 -54.7072 92.1311 -54.0787 94.2397 -56.8179C94.7 -57.4492 95.0426 -58.1911 95.246 -58.9972C96.0564 -62.3359 95.6611 -66.2312 96.4035 -68.8137C97.535 -72.8479 100.553 -74.9984 100.836 -78.412C101.279 -83.2704 98.0115 -84.7228 98.4548 -90.3124"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-17.4839 101.2C-16.1634 97.3659 -11.0122 95.4691 -9.27449 92.5586C-7.9507 90.3009 -7.49005 87.4758 -7.15786 84.9289C-6.99709 83.4843 -6.80781 82.2216 -6.32967 81.4297C-4.87387 79.0079 -2.29507 80.3674 -1.05692 78.2952C-0.493148 77.3179 -0.353787 75.9196 -0.314257 74.2502C-0.313873 72.0564 -0.231398 69.563 0.47174 67.1874C1.40685 64.1877 6.06971 60.5464 6.1418 56.2481C6.2424 52.1317 2.48337 48.0895 2.79085 44.0123C2.97671 41.9042 4.01152 39.9068 5.3495 38.1056C6.73031 36.2117 8.88507 34.9565 9.2991 33.5725C10.277 30.48 9.3964 27.8794 11.9866 25.1938C14.641 22.3691 20.0775 21.5603 24.0803 18.7394C27.1876 16.5177 29.739 11.1139 33.9985 9.19915C35.8001 8.34336 37.7657 7.61958 39.6814 6.76024C42.4104 5.6014 45.1288 4.10011 47.2624 1.42872C50.4093 -2.46245 50.5174 -8.54435 53.2007 -13.3809C54.4567 -15.6137 56.3155 -17.5681 58.103 -19.6118C60.0225 -21.8195 61.8064 -23.9773 62.7557 -26.5205C64.6936 -31.8137 64.0813 -39.0158 66.7109 -42.6396C68.2665 -44.7903 70.332 -45.2429 72.7006 -45.4994C74.3629 -45.6881 76.2535 -45.884 78.2797 -46.1298C81.5579 -46.5287 85.058 -50.8193 87.8264 -52.1851C91.1583 -53.7968 95.2497 -53.0327 97.4261 -55.7969C97.8864 -56.4282 98.2289 -57.1701 98.4324 -57.9762C99.2427 -61.3149 98.8225 -65.278 99.6113 -67.8391C100.789 -71.8519 103.943 -74.0522 104.272 -77.4445C104.758 -82.3956 101.401 -83.7766 101.798 -89.3876"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-15.434 102.485C-14.0921 98.604 -8.91589 96.7749 -7.15678 93.8181C-5.78662 91.5818 -5.35093 88.6889 -5.01874 86.142C-4.83656 84.651 -4.64728 83.3883 -4.12278 82.6179C-2.55282 80.1925 0.19003 81.6841 1.49596 79.5868C2.10611 78.6309 2.26688 77.1863 2.30641 75.5169C2.32821 73.2767 2.34291 70.8083 3.06745 68.3864C3.95619 65.3652 8.66542 61.7454 8.71256 57.3792C8.72042 53.22 4.84723 49.1813 5.15471 45.1042C5.2942 42.9746 6.32902 40.9772 7.64204 39.1083C9.04426 37.168 11.1527 35.8914 11.5417 34.4396C12.4483 31.2579 11.45 28.5467 13.9475 25.8183C16.5769 22.9258 22.0847 22.2062 26.1339 19.4068C29.2876 17.2064 31.8604 11.7563 36.1199 9.84152C37.9464 9.0535 39.912 8.32972 41.8741 7.4918C44.6031 6.33296 47.4143 4.8745 49.5479 2.2031C52.6698 -1.75585 52.6851 -7.88058 55.3899 -12.7635C56.6459 -14.9963 58.5261 -16.9971 60.2922 -18.9944C62.258 -21.1807 64.0634 -23.3848 65.0127 -25.928C67.0183 -31.2462 66.3811 -38.5161 69.1248 -42.1434C70.7268 -44.2727 72.8172 -44.6576 75.2786 -44.8712C76.9873 -45.0385 78.9028 -45.1666 80.9539 -45.3446C84.3035 -45.6544 87.825 -49.9914 90.6183 -51.2893C93.9967 -52.8796 98.1843 -51.9586 100.361 -54.7227C100.867 -55.3326 101.164 -56.0959 101.367 -56.9021C102.177 -60.2407 101.779 -64.2502 102.521 -66.8327C103.699 -70.8455 107.013 -73.028 107.41 -76.4452C107.938 -81.489 104.446 -82.8202 104.911 -88.4561"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-13.3812 103.769C-12.0178 99.842 -6.79528 98.0343 -4.9898 95.0989C-3.61964 92.8626 -3.16253 89.9234 -2.83034 87.3765C-2.60179 85.9069 -2.3911 84.5978 -1.8666 83.8274C-0.203908 81.4448 2.67805 83.0006 4.12309 80.9676C4.80101 79.9868 4.94038 78.5885 4.95495 76.8513C4.97675 74.6112 4.99144 72.1427 5.69103 69.653C6.57977 66.6318 11.3104 62.9656 11.3112 58.5781C11.2727 54.3974 7.28534 50.3623 7.54646 46.2638C7.68595 44.1342 8.6958 42.069 10.0088 40.2001C11.4325 38.2134 13.4695 36.8476 13.8586 35.3958C14.6939 32.125 13.5529 29.2354 16.004 26.4856C18.6085 23.5253 24.209 22.8486 28.2582 20.0491C31.4582 17.8701 34.0061 12.3522 38.3583 10.4803C40.1848 9.69226 42.1968 8.98988 44.2053 8.17337C46.9807 7.03594 49.7918 5.57749 51.9468 2.85972C55.0687 -1.09923 54.9664 -7.33456 57.6462 -12.2853C58.9022 -14.518 60.7824 -16.5189 62.6163 -18.5411C64.5821 -20.7274 66.5016 -22.9351 67.4509 -25.4783C69.478 -30.8429 68.8621 -38.1591 71.6522 -41.765C73.322 -43.9192 75.4374 -44.2364 77.9237 -44.3822C79.6573 -44.4818 81.6193 -44.5884 83.6954 -44.6986C87.0699 -44.9406 90.6592 -49.3026 93.4988 -50.5791C96.9021 -52.1017 101.182 -51.1378 103.405 -53.8805C103.912 -54.4904 104.254 -55.2323 104.433 -56.1062C105.265 -59.4913 104.798 -63.4758 105.608 -66.0832C106.833 -70.0746 110.307 -72.2392 110.679 -75.7242C111.251 -80.8608 107.669 -82.1206 108.134 -87.7566"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-11.3321 105.054C-9.99369 101.059 -4.67838 99.2939 -2.8729 96.3585C-1.48133 94.0758 -1.07059 91.1151 -0.692038 88.5897C-0.463486 87.1201 -0.252792 85.811 0.339488 85.0156C2.11633 82.6295 5.16235 84.3173 6.65376 82.3057C7.37805 81.3463 7.53882 79.9016 7.5534 78.1645C7.57519 75.9243 7.56493 73.3881 8.24311 70.9447C9.08548 67.9021 13.8839 64.211 13.8383 59.8021C13.7748 55.5536 9.65194 51.5684 9.82032 47.427C9.91344 45.2761 10.9233 43.2109 12.2577 41.2956C13.635 39.2875 15.7149 37.829 16.0576 36.3558C16.8893 32.9708 15.5165 29.9741 17.989 27.1779C20.5685 24.1499 26.194 23.5409 30.3573 20.7379C33.5574 18.559 36.1267 12.9947 40.5716 11.1655C42.4445 10.3989 44.4565 9.69658 46.4435 8.92643C49.2653 7.81041 52.0764 6.35196 54.2315 3.63419C57.3748 -0.371126 57.1547 -6.71708 59.8345 -11.6678C61.0656 -13.9683 62.9922 -15.9477 64.8724 -17.9486C66.8846 -20.1135 68.8041 -22.3212 69.7748 -24.9107C71.8482 -30.2539 71.2289 -37.6843 74.0653 -41.2688C75.7815 -43.4016 77.9432 -43.6973 80.5009 -43.7539C82.2809 -43.8321 84.2463 -43.8246 86.3688 -43.9134C89.8147 -44.0662 93.4254 -48.4745 96.3114 -49.7296C99.761 -51.2308 104.091 -50.1314 106.36 -52.8527C106.867 -53.4626 107.21 -54.2045 107.435 -55.057C108.313 -58.4206 107.821 -62.4729 108.631 -65.0803C109.923 -69.0967 113.491 -71.2185 113.909 -74.6821C114.502 -79.865 110.853 -81.0999 111.293 -86.8036"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-9.28179 106.338C-7.94342 102.343 -2.51396 100.575 -0.754849 97.618C0.636726 95.3353 1.04746 92.3747 1.44743 89.8028C1.69739 88.2869 1.95445 86.9992 2.52532 86.2502C4.41631 83.8605 7.62639 85.6803 9.16417 83.6902C9.88846 82.7307 10.0956 81.3075 10.1102 79.5703C10.132 77.3302 10.1217 74.7939 10.7749 72.2828C11.6173 69.2402 16.4157 65.5491 16.3452 61.0723C16.2353 56.8025 12.0197 52.7745 12.1203 48.658C12.2134 46.5071 13.1983 44.3741 14.5113 42.5052C15.91 40.4508 17.9435 38.9708 18.2399 37.4762C19.0003 34.002 17.5098 30.8947 19.9109 28.0094C22.4441 24.9599 28.1837 24.3474 32.347 21.5444C35.5721 19.4332 38.1378 13.7548 42.6291 11.9471C44.502 11.1804 46.539 10.5459 48.5937 9.75076C51.4619 8.65615 54.3194 7.2191 56.4495 4.43356C59.5464 0.406824 59.255 -6.02829 61.9099 -11.0468C63.1409 -13.3473 65.0675 -15.3268 66.9691 -17.3739C69.0492 -19.5638 70.9687 -21.7715 71.9857 -24.3396C74.0806 -29.7292 73.4826 -37.206 76.4118 -40.7476C78.1743 -42.859 80.361 -43.0869 82.965 -43.1221C84.77 -43.1325 86.7818 -43.1036 88.9293 -43.1247C92.4001 -43.2097 96.1 -47.6893 99.0109 -48.8767C102.553 -50.335 106.887 -49.1214 109.224 -51.8677C109.731 -52.4776 110.073 -53.2195 110.298 -54.072C111.176 -57.4356 110.638 -61.5093 111.516 -64.1417C112.808 -68.1581 116.558 -70.3084 116.976 -73.772C117.658 -79.0262 113.895 -80.2575 114.335 -85.9613"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-7.18417 107.644C-5.87076 103.581 -0.348565 101.856 1.45691 98.9204C2.84848 96.6377 3.28063 93.6307 3.72697 91.0803C3.97693 89.5643 4.23398 88.2766 4.87263 87.5027C6.80999 85.1345 10.2519 87.0613 11.8361 85.0925C12.6067 84.1545 12.8353 82.6849 12.8499 80.9477C12.8717 78.7076 12.8364 76.1036 13.536 73.6138C14.332 70.5498 19.1982 66.8338 19.0813 62.3356C18.9465 57.998 14.5953 54.0199 14.6495 49.882C14.6963 47.7097 15.7276 45.5981 17.0156 43.6614C18.4143 41.607 20.4443 40.0128 20.6942 38.4969C21.3833 34.9334 19.7287 31.6942 22.1049 28.741C24.6132 25.6238 30.4455 25.0541 34.6552 22.2725C37.8802 20.1613 40.5138 14.458 45.0515 12.6716C46.9707 11.9264 49.054 11.3133 51.1088 10.5182C54.0233 9.44496 56.8808 8.00792 59.0109 5.22237C62.1293 1.14927 61.6988 -5.35009 64.3536 -10.3686C65.5847 -12.6691 67.579 -14.6735 69.4807 -16.7207C71.5607 -18.9105 73.5265 -21.0968 74.59 -23.6436C76.7526 -29.0581 76.1297 -36.6026 79.1053 -40.1228C80.9142 -42.2129 83.1472 -42.4194 85.8225 -42.3654C87.6525 -42.308 89.7107 -42.2577 91.9045 -42.2573C95.4466 -42.2531 99.1679 -46.7791 102.125 -47.9451C105.693 -49.3356 110.166 -48.0578 112.481 -50.7577C112.988 -51.3676 113.352 -52.1559 113.555 -52.962C114.433 -56.3256 113.917 -60.4457 114.77 -63.1459C116.108 -67.1408 119.972 -69.2947 120.457 -72.7833C121.183 -78.1302 117.33 -79.2902 117.77 -84.994"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-5.13229 108.929C-3.79747 104.82 1.7711 103.115 3.57658 100.18C4.98956 97.851 5.4217 94.844 5.86804 92.2935C6.118 90.7776 6.39648 89.4435 7.0815 88.691C9.133 86.3192 12.6712 88.403 14.3695 86.4307C15.2079 85.4677 15.4151 84.0445 15.4511 82.2609C15.4729 80.0208 15.4377 77.4168 16.0909 74.9056C16.8655 71.888 21.7745 68.0792 21.5648 63.5382C21.3837 59.1792 16.872 55.1832 16.9476 50.999C16.948 48.8052 18.0007 46.6473 19.2424 44.6892C20.6161 42.567 22.5997 40.9514 22.871 39.3891C23.5352 35.7579 21.6701 32.3652 24.0678 29.3657C26.551 26.1807 32.4083 25.6788 36.6644 22.9185C39.9358 20.8288 42.5658 15.0113 47.1748 13.3141C49.094 12.569 51.2237 11.9772 53.3035 11.2499C56.218 10.1767 59.1219 8.76102 61.2733 5.92911C64.3917 1.85601 63.8649 -4.80031 66.5198 -9.81878C67.7722 -12.1657 69.7666 -14.1701 71.6932 -16.1495C73.8196 -18.3179 75.8532 -20.5292 76.8703 -23.0973C79.1007 -28.5368 78.4064 -36.1705 81.4962 -39.6943C83.3051 -41.7843 85.6772 -41.9266 88.3775 -41.8049C90.2538 -41.726 92.3156 -41.5616 94.5557 -41.5398C98.1228 -41.4678 101.912 -46.0188 104.894 -47.117C108.508 -48.4861 113.031 -47.0727 115.414 -49.7976C115.921 -50.4075 116.285 -51.1958 116.51 -52.0483C117.388 -55.4119 116.846 -59.5998 117.746 -62.2785C119.084 -66.2735 123.108 -68.4095 123.64 -71.8766C124.387 -77.2699 120.467 -78.405 120.881 -84.1765"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-3.08083 110.213C-1.74601 106.104 3.89034 104.375 5.74218 101.461C7.15517 99.1318 7.58732 96.1248 8.05506 93.528C8.32644 91.9657 8.65127 90.653 9.33629 89.9005C11.5019 87.5252 15.2042 89.741 17.0167 87.7651C17.9015 86.8236 18.1086 85.4003 18.166 83.5704C18.2092 81.2839 18.1062 78.7049 18.7809 76.1474C19.5091 73.1083 24.4859 69.2745 24.2298 64.7121C24.0023 60.3317 19.3765 56.3393 19.4057 52.1336C19.4061 49.9399 20.4124 47.7605 21.6755 45.756C23.0492 43.6338 24.776 41.8435 25.2543 40.3204C26.4677 36.7178 23.8644 33.0967 26.1693 30.0544C28.6276 26.8016 34.5776 26.3425 38.88 23.6037C42.1515 21.5139 44.8029 15.6501 49.4582 13.9743C51.4238 13.2506 53.5535 12.6588 55.6333 11.9315C58.5942 10.8797 61.5444 9.48545 63.6495 6.63213C66.7429 2.49125 66.1234 -4.20789 68.7533 -9.29414C70.0057 -11.6411 72.0001 -13.6455 73.9481 -15.6712C76.0959 -17.886 78.1759 -20.0759 79.2394 -22.6226C81.4912 -28.1085 80.8183 -35.7886 83.9544 -39.2909C85.8311 -41.4059 88.2068 -41.434 90.9535 -41.2908C92.8547 -41.1443 94.9629 -40.9584 97.1816 -40.8902C100.82 -40.7291 104.677 -45.305 107.659 -46.4032C111.298 -47.7045 115.913 -46.2483 118.343 -48.9518C118.896 -49.5403 119.26 -50.3285 119.485 -51.181C120.363 -54.5447 119.775 -58.754 120.742 -61.4577C122.127 -65.4312 126.333 -67.5957 126.819 -71.0843C127.608 -76.5703 123.599 -77.634 124.035 -83.4519"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M-1.03073 111.498C0.279129 107.321 6.05459 105.656 7.86006 102.72C9.31942 100.413 9.72661 97.338 10.1944 94.7413C10.4657 93.1789 10.7906 91.8663 11.522 91.1351C13.7803 88.8026 17.6681 91.1041 19.5269 89.1496C20.4117 88.2081 20.6866 86.7599 20.7226 84.9763C20.7658 82.6898 20.6842 80.0644 21.3125 77.4855C22.0407 74.4464 27.0175 70.6127 26.7365 65.9825C26.4377 61.5128 21.7226 57.5918 21.7055 53.3647C21.681 51.1032 22.6873 48.9238 23.9504 46.9193C25.2991 44.7294 27.0259 42.939 27.5256 41.3695C28.6677 37.6778 25.9217 33.8783 28.1802 30.8146C30.6136 27.494 36.6349 27.1241 40.9588 24.3389C44.2766 22.2706 46.903 16.3389 51.6511 14.706C53.5953 14.0286 55.7714 13.4582 57.9189 12.7059C60.9262 11.6756 63.8764 10.2814 66.0493 7.40307C69.1427 3.26219 68.4055 -3.54758 71.0568 -8.68019C72.2629 -11.0485 74.325 -13.0779 76.3194 -15.0822C78.5135 -17.2756 80.5936 -19.4655 81.6784 -22.0586C83.998 -27.5694 83.3002 -35.3173 86.5291 -38.7768C88.4521 -40.8704 90.8742 -40.8771 93.6921 -40.6447C95.6398 -40.4767 97.7729 -40.2231 100.017 -40.0872C103.726 -39.8368 107.626 -44.5055 110.633 -45.5358C114.318 -46.8158 119.03 -45.2026 121.46 -47.9061C122.013 -48.4946 122.377 -49.2828 122.602 -50.1353C123.48 -53.499 122.913 -57.7546 123.881 -60.4583C125.312 -64.4105 129.632 -66.5785 130.185 -70.092C131.064 -75.6494 126.919 -76.6632 127.355 -82.4811"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M1.01968 112.782C2.35095 108.559 8.17277 106.915 9.95684 104.026C11.4162 101.719 11.8448 98.5976 12.3125 96.0008C12.5839 94.4384 12.9765 93.1008 13.7079 92.3697C16.0805 90.0336 20.0859 92.4457 22.0375 90.5341C22.9686 89.6139 23.2436 88.1657 23.301 86.3358C23.3442 84.0493 23.2626 81.4239 23.8445 78.8236C24.5727 75.7845 29.5709 71.9044 29.2435 67.2528C28.8983 62.7617 24.0691 58.8442 24.0057 54.5957C23.9811 52.3342 24.941 50.1334 26.2041 48.1289C27.5743 45.8926 29.2761 44.0344 29.7294 42.4436C30.8216 38.6163 27.8901 34.7312 30.1701 31.621C32.5571 28.2791 38.6462 27.8842 43.0628 25.1418C46.3806 23.0735 49.0284 17.0955 53.8693 15.5054C55.8598 14.8494 58.0359 14.279 60.2084 13.5945C63.262 12.5855 66.2123 11.1913 68.3851 8.31305C71.5 4.1258 70.6236 -2.74819 73.2499 -7.94858C74.456 -10.3169 76.5182 -12.3463 78.534 -14.397C80.7281 -16.5904 82.8759 -18.8052 84.0072 -21.3769C86.3267 -26.8877 85.6718 -34.7283 88.947 -38.1664C90.9164 -40.2386 93.3848 -40.2239 96.2741 -39.9023C98.2467 -39.6666 100.426 -39.3915 102.695 -39.1878C106.43 -38.8697 110.397 -43.5633 113.451 -44.5723C117.228 -45.8094 121.944 -44.0821 124.441 -46.8105C124.994 -47.399 125.358 -48.1872 125.605 -49.0861C126.483 -52.4497 125.891 -56.7732 126.858 -59.4769C128.311 -63.4754 132.77 -65.5792 133.345 -69.1391C134.266 -74.7892 129.986 -75.7531 130.444 -81.6173"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M3.0708 114.067C4.40208 109.844 10.2703 108.222 12.1221 105.308C13.6029 102.954 14.0101 99.8787 14.5456 97.257C14.8633 95.716 15.2096 94.357 16.0087 93.601C18.4954 91.2614 22.665 93.8054 24.6629 91.9152C25.6618 90.9701 25.9367 89.5219 25.9941 87.692C26.0373 85.4055 25.9094 82.7587 26.5127 80.112C27.1946 77.0515 32.2605 73.1464 31.8868 68.4734C31.5166 63.9146 26.5733 60.0006 26.442 55.7771C26.3711 53.4941 27.331 51.2934 28.6156 49.2425C29.9857 47.0062 31.6626 45.0802 32.0695 43.468C33.044 39.53 30.0412 35.5557 32.2498 32.3564C34.6119 28.9467 40.7937 28.5946 45.2103 25.8523C48.5745 23.8053 51.2437 17.7809 56.0846 16.1909C58.1215 15.5563 60.3439 15.0073 62.5164 14.3228C65.5486 13.3602 68.613 11.9625 70.7609 9.0164C73.8294 4.80774 72.9032 -2.20181 75.5295 -7.4022C76.757 -9.81692 78.8192 -11.8462 80.8599 -13.8292C83.1004 -16.0012 85.2946 -18.1946 86.4009 -20.834C88.7882 -26.3698 88.1083 -34.2782 91.4299 -37.6949C93.4456 -39.7457 95.9605 -39.7095 98.8747 -39.3202C100.847 -39.0845 103.077 -38.6739 105.392 -38.4487C109.198 -38.0414 113.187 -42.7814 116.287 -43.769C120.089 -44.9383 124.898 -43.1682 127.42 -45.8288C127.994 -46.4636 128.337 -47.2055 128.583 -48.1044C129.461 -51.468 128.824 -55.8129 129.859 -58.5416C131.357 -62.5186 135.931 -64.626 136.552 -68.1645C137.495 -73.861 133.147 -74.7999 133.626 -80.7105"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M5.14322 115.305C6.44953 111.014 12.4105 109.435 14.2623 106.521C15.7431 104.167 16.1717 101.046 16.7072 98.4238C17.0464 96.8365 17.3926 95.4775 18.2381 94.7428C20.8176 92.446 25.1726 95.0758 27.2168 93.2069C28.2622 92.2832 28.5371 90.835 28.5695 88.9373C28.6127 86.6508 28.5062 83.9576 29.0417 81.3359C29.7236 78.2754 34.7896 74.3703 34.3909 69.6296C33.9743 65.0493 28.8705 61.1175 28.6928 56.8726C28.6219 54.5896 29.6032 52.3425 30.795 50.2488C32.1402 47.9447 33.8171 46.0187 34.1991 44.3387C35.1237 40.2652 31.9354 36.2053 34.119 32.9382C36.4561 29.4606 42.7093 29.1978 47.1723 26.4768C50.5364 24.4299 53.2485 18.3128 58.1607 16.8119C60.1976 16.1773 62.445 15.6961 64.6389 14.9653C67.7175 14.0241 70.7819 12.6263 72.9298 9.68024C76.0196 5.42521 74.9543 -1.64856 77.602 -6.89532C78.8295 -9.31004 80.8917 -11.3394 83.0002 -13.3473C85.2622 -15.5657 87.5027 -17.7376 88.6339 -20.3093C91.0891 -25.87 90.3842 -33.8462 93.7985 -37.2201C95.8606 -39.2494 98.4004 -39.1455 101.407 -38.7134C103.405 -38.4098 105.681 -37.9778 108.021 -37.6849C111.852 -37.2098 115.908 -41.9748 119.055 -42.9409C122.904 -44.0888 127.808 -42.1618 130.352 -44.8687C130.927 -45.5036 131.269 -46.2455 131.515 -47.1444C132.393 -50.508 131.777 -54.8992 132.812 -57.6279C134.311 -61.605 139.113 -63.7194 139.688 -67.2793C140.673 -73.0686 136.257 -73.9825 136.715 -79.8468"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M7.19417 116.589C8.52189 112.252 14.5756 110.715 16.4274 107.801C17.9082 105.447 18.3582 102.28 18.8937 99.6581C19.2329 98.0708 19.6469 96.6868 20.4924 95.9522C23.1396 93.6304 27.6373 96.4385 29.8421 94.5876C30.8874 93.6639 31.2301 92.1907 31.2411 90.3394C31.2843 88.0529 31.1314 85.3383 31.6884 82.6702C32.3238 79.5883 37.4576 75.6583 37.0126 70.8961C36.5496 66.2944 31.3316 62.3661 31.1077 58.0998C30.9903 55.7954 31.9717 53.5483 33.1849 51.4083C34.5301 49.1041 36.2034 47.064 36.539 45.3626C37.3923 41.1999 34.0399 37.008 36.1772 33.7195C38.4893 30.1741 44.7889 29.9327 49.2982 27.2332C52.7087 25.2076 55.3959 19.0227 60.4008 17.5647C62.4841 16.9515 64.7315 16.4703 66.9503 15.8072C70.0753 14.8874 73.1397 13.4897 75.309 10.4972C78.3989 6.24221 77.2158 -0.942168 79.8386 -6.25671C81.0661 -8.67143 83.1497 -10.7471 85.2582 -12.7551C87.5665 -14.952 89.807 -17.124 90.9597 -19.742C93.4362 -25.3491 92.7527 -33.3717 96.2348 -36.7705C98.3434 -38.7784 100.929 -38.6531 104.008 -38.1318C106.052 -37.8068 108.352 -37.307 110.739 -36.9927C114.641 -36.4284 118.741 -41.2861 121.89 -42.1381C125.786 -43.2646 130.74 -41.202 133.331 -43.8876C133.905 -44.5224 134.315 -45.2893 134.54 -46.1418C135.418 -49.5054 134.756 -53.918 135.858 -56.6717C137.404 -60.6273 142.319 -62.7453 142.962 -66.3302C144.037 -72.1908 139.485 -73.0548 139.964 -78.9654"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M9.29144 117.895C10.6192 113.558 16.7406 111.996 18.5925 109.082C20.0732 106.728 20.5232 103.561 21.0802 100.893C21.4193 99.3054 21.8333 97.9214 22.7253 97.2081C25.4652 94.9292 30.1483 97.823 32.4209 95.9471C33.5126 95.0448 33.8089 93.5502 33.8877 91.6739C33.9524 89.341 33.7994 86.6264 34.31 83.9369C34.9241 80.9014 40.1007 76.8786 39.6092 72.095C39.1214 67.4256 33.7678 63.5472 33.4974 59.2595C33.3801 56.9551 34.3365 54.6402 35.5497 52.5001C36.87 50.1282 38.5433 48.0881 38.854 46.3189C39.5895 42.0456 36.1658 37.7645 38.2782 34.4082C40.5653 30.7951 46.9362 30.6429 51.5133 27.9184C54.9238 25.8929 57.6324 19.6616 62.6836 18.2249C64.767 17.6117 67.0607 17.152 69.3259 16.5103C72.4972 15.6119 75.5616 14.2142 77.731 11.2217C80.8208 6.96671 79.5201 -0.328269 82.1643 -5.68918C83.3668 -8.17168 85.5217 -10.1582 87.6517 -12.2125C89.96 -14.4094 92.2683 -16.6064 93.4673 -19.203C96.0116 -24.835 95.2568 -32.9468 98.8317 -36.3028C101.008 -38.3357 103.619 -38.1426 106.722 -37.5535C108.791 -37.1608 111.117 -36.5932 113.55 -36.2574C117.498 -35.6717 121.69 -40.4866 124.84 -41.3386C128.761 -42.3973 133.854 -40.2705 136.445 -42.9561C137.065 -43.5695 137.429 -44.3577 137.676 -45.2566C138.6 -48.5988 137.912 -53.0793 139.015 -55.8329C140.607 -59.7671 145.637 -61.8887 146.326 -65.4521C147.422 -71.3591 142.803 -72.1981 143.257 -78.1765"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M11.3417 119.18C12.6445 114.775 18.8586 113.256 20.7105 110.342C22.259 107.963 22.6627 104.774 23.2196 102.106C23.5802 100.472 24.0406 99.1099 24.9325 98.3966C27.7866 96.1142 32.6338 99.1399 34.9527 97.2854C36.0908 96.4045 36.4085 94.8636 36.4873 92.9873C36.5983 90.6758 36.3526 87.9184 36.9096 85.2503C37.4773 82.1934 42.7002 78.192 42.1375 73.3192C41.5569 68.607 36.1355 64.7535 35.8188 60.4444C35.6551 58.1186 36.6115 55.8037 37.8461 53.6173C39.1878 51.199 40.8362 49.0912 41.1004 47.3005C41.7861 42.8917 38.1769 38.5249 40.2643 35.1008C42.5265 31.42 48.9438 31.2891 53.5672 28.586C57.0241 26.5819 59.7541 20.3043 64.8517 18.889C66.9814 18.2973 69.2537 17.8839 71.5653 17.2636C74.783 16.3867 77.8938 15.0103 80.0381 11.9501C83.1031 7.62726 81.731 0.243085 84.3288 -5.13924C85.5314 -7.62174 87.7077 -9.65461 89.8376 -11.7089C92.2137 -13.9308 94.5684 -16.1063 95.721 -18.7244C98.2868 -24.4028 97.5534 -32.5609 101.175 -35.8955C103.351 -37.9284 106.101 -37.6711 109.229 -37.0142C111.345 -36.6 113.695 -35.9647 116.153 -35.5612C120.173 -34.8863 124.386 -39.7475 127.583 -40.5781C131.596 -41.594 136.693 -39.353 139.351 -42.0635C139.972 -42.677 140.336 -43.4652 140.582 -44.3641C141.506 -47.7063 140.794 -52.2545 141.918 -55.0545C143.531 -59.0351 148.722 -61.1388 149.411 -64.7022C150.55 -70.7019 145.841 -71.4697 146.295 -77.4481"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M13.3921 120.464C14.7162 116.013 20.9768 114.516 22.875 111.623C24.4236 109.244 24.8486 106.009 25.4519 103.362C25.8125 101.728 26.2943 100.319 27.2326 99.6276C30.2008 97.3416 35.1657 100.478 37.5774 98.6663C38.7832 97.7604 39.0795 96.2659 39.1798 94.3432C39.2907 92.0317 39.0451 89.2743 39.5771 86.5384C40.1448 83.4815 45.3892 79.4338 44.78 74.5396C44.1745 69.7595 38.5712 65.9346 38.2545 61.6255C38.0444 59.2783 39.0222 56.917 40.2354 54.777C41.5771 52.3587 43.1541 50.1616 43.4398 48.3246C44.0541 43.8266 40.3023 39.2814 42.3433 35.836C44.5591 32.1337 51.0906 31.9993 55.7604 29.3176C59.2173 27.3135 61.9686 20.9895 67.1127 19.5956C69.2423 19.0039 71.6074 18.6333 73.8726 17.9916C77.0903 17.1147 80.2475 15.7597 82.4596 12.6745C85.5245 8.35174 84.0348 0.856975 86.654 -4.57172C87.8565 -7.05422 90.0328 -9.08709 92.2091 -11.12C94.5852 -13.3419 96.9399 -15.5174 98.2067 -18.139C100.865 -23.7746 100.082 -32.0683 103.796 -35.3601C106.018 -37.3715 108.794 -37.0464 112.015 -36.3467C114.155 -35.8648 116.552 -35.208 119.034 -34.7367C123.126 -33.9726 127.361 -38.8803 130.603 -39.6894C134.642 -40.6376 139.81 -38.3073 142.514 -40.9965C143.135 -41.6099 143.499 -42.3982 143.745 -43.297C144.67 -46.6393 143.911 -51.2089 145.103 -54.0338C146.762 -57.993 152.113 -60.0789 152.824 -63.6887C154.052 -69.7597 149.208 -70.4775 149.683 -76.5023"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M15.4445 121.749C16.7687 117.297 23.1434 115.797 24.9952 112.883C26.5438 110.504 26.9902 107.222 27.5935 104.575C27.9541 102.942 28.4823 101.554 29.442 100.816C32.5029 98.5728 37.6533 101.795 40.1114 100.005C41.3172 99.0987 41.6813 97.5792 41.7815 95.6565C41.8925 93.3451 41.6683 90.5413 42.1539 87.784C42.7216 84.7271 47.9874 80.6329 47.3319 75.7173C46.6799 70.9159 40.9625 67.0945 40.6208 62.7176C40.4108 60.3704 41.3886 58.0091 42.5305 55.7799C43.8472 53.2938 45.4242 51.0968 45.6635 49.2384C46.2065 44.6511 42.337 39.9954 44.3531 36.4821C46.5439 32.7121 53.1467 32.6668 57.8629 30.0066C61.3661 28.0239 64.1139 21.5857 69.3293 20.2811C71.4839 19.7571 73.8704 19.3402 76.182 18.7199C79.4461 17.8643 82.6032 16.5094 84.8153 13.4242C87.9017 9.05505 86.2728 1.49605 88.9134 -3.97901C90.116 -6.46151 92.3137 -8.54076 94.5364 -10.5522C96.9588 -12.7527 99.3813 -14.9532 100.602 -17.5962C103.282 -23.2782 102.52 -31.6182 106.28 -34.8886C108.549 -36.8787 111.371 -36.5321 114.617 -35.7646C116.782 -35.2149 119.204 -34.4904 121.733 -33.9977C125.849 -33.1658 130.173 -38.1448 133.441 -38.8862C137.525 -39.8129 142.811 -37.3721 145.516 -40.0612C146.136 -40.6746 146.547 -41.4415 146.793 -42.3404C147.718 -45.6826 146.98 -50.2986 148.172 -53.1235C149.831 -57.0827 155.343 -59.1507 156.075 -62.8068C157.346 -68.9706 152.366 -69.6385 152.842 -75.6633"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M17.4943 123.033C18.7936 118.514 25.261 117.056 27.1129 114.142C28.6828 111.717 29.1079 108.482 29.7326 105.789C30.1145 104.109 30.6427 102.721 31.6274 102.051C34.8025 99.8039 40.1169 103.158 42.6677 101.41C43.9199 100.526 44.284 99.0065 44.3842 97.0839C44.4952 94.7724 44.2246 91.9472 44.7102 89.1899C45.2316 86.1116 50.5651 81.9925 49.8633 77.0555C49.165 72.2326 43.3548 68.3684 42.9454 64.0165C42.7103 61.6015 43.6881 59.2402 44.83 57.011C46.1467 54.5249 47.7202 52.2137 47.9132 50.3339C48.3599 45.5897 44.3513 40.8697 46.3423 37.2887C48.5083 33.4508 55.1574 33.427 59.9199 30.7882C63.4232 28.8055 66.1924 22.3209 71.5005 21.0591C73.6551 20.5351 76.088 20.1396 78.4245 19.5871C81.735 18.753 84.9385 17.4194 87.1257 14.2665C90.1656 9.8759 88.4868 2.18134 91.0811 -3.31513C92.3051 -5.844 94.5028 -7.92324 96.7254 -9.9347C99.1693 -12.1816 101.617 -14.3143 102.884 -16.9359C105.585 -22.6642 104.844 -31.0506 108.672 -34.346C110.988 -36.3146 113.856 -35.9467 117.173 -35.09C119.385 -34.5189 121.831 -33.7265 124.385 -33.1661C128.573 -32.245 132.919 -37.2703 136.233 -37.9903C140.363 -38.8956 145.699 -36.3192 148.45 -38.9869C149.071 -39.6004 149.481 -40.3672 149.749 -41.3125C150.673 -44.6547 149.911 -49.3385 151.149 -52.142C152.855 -56.0798 158.481 -58.1513 159.259 -61.7861C160.551 -67.9962 155.504 -68.6391 156.001 -74.7103"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M19.5449 124.318C20.8655 119.752 27.3793 118.316 29.2776 115.423C30.8475 112.998 31.294 109.716 31.9187 107.023C32.347 105.365 32.8288 103.956 33.8813 103.26C37.1491 101.056 42.6276 104.542 45.2462 102.77C46.5448 101.907 46.9303 100.341 47.0305 98.4184C47.1415 96.1069 46.8709 93.2817 47.3315 90.4566C47.8529 87.3783 53.1864 83.2592 52.4596 78.2544C51.7364 73.3638 45.7906 69.5495 45.3348 65.1761C45.0998 62.7612 46.0312 60.3785 47.1945 58.1029C48.4862 55.549 50.0598 53.2378 50.2277 51.2902C50.6031 46.4568 46.4305 41.6048 48.3752 38.0024C50.5161 34.0968 57.258 34.1158 62.0669 31.4984C65.6165 29.5371 68.4072 23.0062 73.7153 21.7443C75.9163 21.2418 78.3277 20.8926 80.732 20.3152C84.0888 19.5024 87.2923 18.1689 89.4795 15.0159C92.5409 10.579 90.723 2.8202 93.3387 -2.72264C94.5163 -5.27292 96.7603 -7.33075 99.0508 -9.36717C101.541 -11.5926 104.056 -13.7503 105.277 -16.3933C108.046 -22.1466 107.28 -30.6008 111.201 -33.8533C113.584 -35.8469 116.477 -35.4112 119.819 -34.4867C122.056 -33.8478 124.549 -33.0341 127.128 -32.4058C131.341 -31.417 135.754 -36.4673 139.093 -37.1195C143.249 -37.957 148.656 -35.2914 151.475 -37.9841C152.095 -38.5976 152.527 -39.4108 152.773 -40.3096C153.719 -43.6982 152.889 -48.357 154.149 -51.2069C155.901 -55.1233 161.662 -57.2448 162.487 -60.8581C163.868 -67.1395 158.707 -67.779 159.204 -73.8501"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M21.5977 125.602C22.9183 121.037 29.4785 119.622 31.3981 116.683C32.9681 114.257 33.436 110.929 34.0607 108.236C34.489 106.578 35.0386 105.144 36.091 104.448C39.4267 102.22 45.0692 105.838 47.802 104.062C49.1469 103.22 49.5324 101.654 49.654 99.6851C49.7864 97.3272 49.4695 94.4806 49.9301 91.6555C50.4051 88.5558 55.8064 84.4118 55.0332 79.3856C54.2636 74.4735 48.2037 70.6628 47.6552 66.2466C47.3738 63.8102 48.3052 61.4275 49.4899 59.1055C50.7817 56.5517 52.3302 54.1727 52.4732 52.1573C52.7773 47.2348 48.4869 42.2721 50.4067 38.6019C52.5227 34.6285 59.3359 34.7367 64.1662 32.0729C67.7158 30.1116 70.5279 23.5344 75.9287 22.3154C78.1297 21.8128 80.5875 21.485 82.9918 20.9076C86.395 20.1163 89.5985 18.7827 91.8071 15.5834C94.8685 11.1465 92.9329 3.27707 95.5236 -2.33354C96.7012 -4.88383 98.9667 -6.98803 101.257 -9.02446C103.747 -11.2499 106.309 -13.3862 107.597 -16.0541C110.388 -21.8538 109.644 -30.3543 113.611 -33.5854C116.04 -35.5576 118.98 -35.1005 122.393 -34.0869C124.676 -33.4266 127.194 -32.5451 129.819 -31.8954C134.103 -30.8174 138.585 -35.8926 141.924 -36.5448C146.172 -37.3395 151.651 -34.5847 154.448 -37.231C155.069 -37.8445 155.5 -38.6577 155.747 -39.5566C156.693 -42.9452 155.884 -47.6503 157.143 -50.5002C158.917 -54.463 164.864 -56.4988 165.663 -60.1799C167.088 -66.5541 161.837 -67.1222 162.287 -73.2147"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M23.6473 126.887C24.9429 122.253 31.6422 120.903 33.5619 117.964C35.1782 115.56 35.5997 112.21 36.2922 109.492C36.7419 107.787 37.2701 106.4 38.3903 105.679C41.8187 103.493 47.6467 107.197 50.4258 105.442C51.8385 104.576 52.2241 103.01 52.2993 101.019C52.4317 98.6616 52.1147 95.815 52.529 92.9685C52.9826 89.9152 58.4267 85.6784 57.6072 80.6308C56.7662 75.6295 50.5708 71.8687 50.0222 67.4525C49.7408 65.0161 50.6473 62.5657 51.7642 60.2687C53.031 57.6471 54.5796 55.2681 54.6976 53.1849C54.884 48.1517 50.4759 43.0785 52.3707 39.3405C54.394 35.3243 61.3213 35.429 66.2444 32.808C69.8404 30.8681 72.6275 24.223 78.1424 23.0005C80.3898 22.5193 82.894 22.213 85.2769 21.6819C88.6801 20.8906 91.9763 19.5999 94.1386 16.3792C97.175 11.8744 95.1467 3.96221 97.7588 -1.69478C98.9364 -4.24506 101.202 -6.34926 103.539 -8.36428C106.075 -10.5683 108.658 -12.7509 109.946 -15.4189C112.805 -21.2435 112.036 -29.8118 116.049 -33.0215C118.525 -34.9723 121.536 -34.426 124.996 -33.391C127.303 -32.6629 129.847 -31.7136 132.518 -31.0425C136.827 -29.8967 141.376 -34.9969 144.761 -35.6277C149.035 -36.3546 154.585 -33.5106 157.45 -36.1819C158.117 -36.7739 158.502 -37.6086 158.77 -38.5538C159.716 -41.9424 158.928 -46.6939 160.188 -49.5438C161.915 -53.528 168.069 -55.5245 168.915 -59.1842C170.382 -65.6512 165.042 -66.1479 165.514 -72.2868"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M25.7444 128.193C27.0614 123.513 33.8071 122.184 35.7053 119.291C37.343 116.841 37.7182 113.47 38.457 110.774C38.9068 109.069 39.5028 107.656 40.6015 106.982C44.1441 104.793 50.1362 108.628 53.008 106.916C54.4207 106.05 54.8526 104.505 54.9279 102.515C55.0603 100.157 54.7647 97.2642 55.1326 94.3963C55.5398 91.3215 61.0303 87.1061 60.1394 81.9693C59.2521 76.9467 52.9425 73.1894 52.3476 68.7518C52.0199 66.294 52.9263 63.8436 54.0646 61.5002C55.3528 58.8322 56.8764 56.3854 56.9481 54.2808C57.0846 49.1121 52.5125 43.9069 54.3609 40.1475C56.3592 36.0635 63.3578 36.2573 68.2809 33.6364C71.8555 31.7428 74.7068 24.9587 80.2468 23.8039C82.4941 23.3227 85.0233 23.0842 87.474 22.5281C90.9235 21.7583 94.2197 20.4675 96.4034 17.2004C99.4398 12.6957 97.3152 4.62651 99.9059 -0.984108C101.105 -3.58076 103.417 -5.66354 105.754 -7.67855C108.312 -9.92896 110.941 -12.0902 112.276 -14.7367C115.18 -20.5399 114.408 -29.2224 118.468 -32.4107C120.99 -34.3401 124.047 -33.7724 127.532 -32.6695C129.886 -31.92 132.454 -30.9029 135.151 -30.1641C139.531 -28.9291 144.123 -34.122 147.533 -34.685C151.853 -35.3906 157.453 -32.411 160.385 -35.1073C161.052 -35.6993 161.484 -36.5125 161.752 -37.4578C162.698 -40.8464 161.864 -45.6193 163.191 -48.4942C164.965 -52.4569 171.233 -54.457 172.1 -58.163C173.635 -64.6549 168.181 -65.1481 168.674 -71.3334"
            stroke="currentcolor"
            style={{ stroke: "currentcolor", strokeOpacity: 1 }}
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1014_28589">
          <rect
            width="16"
            height="16"
            rx="8"
            fill="white"
            style={{ fill: "currentColor", fillOpacity: 0 }}
          />
        </clipPath>
        <clipPath id="clip1_1014_28589">
          <rect
            width="244.893"
            height="221.424"
            fill="white"
            style={{ fill: "currentColor", fillOpacity: 0 }}
            transform="translate(-18.3867 207.944) rotate(-134.415)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
