import React from "react";
import styles from "./Stack.module.css";

export interface StackCSSProps {
  "--stack-gap"?: number;
}
export interface StackProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "style"> {
  /**
   * Size of gap in rems.
   */
  gap?: number;
  style?: React.CSSProperties & StackCSSProps;
}

export const Stack = ({
  children,
  gap = 0.25,
  style,
  ...restProps
}: StackProps): JSX.Element => (
  <div
    className={styles.base}
    style={
      { ...style, "--stack-gap": gap } as React.CSSProperties & StackCSSProps
    }
    {...restProps}
  >
    {children}
  </div>
);
