import React from "react";
import { Nav } from "../components/Nav";
import cn from "classnames";
import styles from "./PageTemplate.module.css";

export interface PageTemplateProps
  extends React.AllHTMLAttributes<HTMLDivElement> {}

export const PageTemplate = ({
  children,
  className,
  ...restProps
}: PageTemplateProps): JSX.Element => {
  return (
    <main {...restProps} className={cn(className, styles.base)}>
      <div className={styles.content}>{children}</div>
      <Nav kind="content-page" />
    </main>
  );
};
