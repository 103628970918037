import React from "react";
import cn from "classnames";
import styles from "./UserLogin.module.css";
import { Stack } from "./Stack";
import { Button } from "./Button";
import { useAuth } from "../hooks";
import { signInWithGoogle, signOutFromGoogle } from "../auth";
import { LogoMark, LogoType } from "./Logo";
import { Link } from "react-router-dom";

export interface UserLoginProps
  extends React.AllHTMLAttributes<HTMLDivElement> {}

export const UserLogin = ({
  className,
  ...restProps
}: UserLoginProps): JSX.Element => {
  const { user } = useAuth();

  return (
    <Stack {...restProps} className={cn(className, styles.base)}>
      <LogoMark style={{ width: "5rem", height: "5rem" }} />
      <p className={styles.callout}>
        The dumb todo app to save you from your smart self.
      </p>
      <Button
        onClick={user ? signOutFromGoogle : signInWithGoogle}
        kind="primary"
        large
      >
        Sign-Up for Free! (or sign-in)
      </Button>
      <hr />
      <LogoType as="h1" style={{ fontSize: "1.5rem" }} />
      <div className={styles.threeColumn}>
        <p>
          Purposefully <Link to="/about">feature light</Link>
        </p>
        <p>
          As <Link to="/privacy">creep-free</Link> as possible
        </p>
        <p>
          <Link to="/download">Download</Link> to your computer or phone
        </p>
      </div>
    </Stack>
  );
};
