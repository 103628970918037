import React from "react";
import cn from "classnames";
import styles from "./Select.module.css";

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {}

export const Select = ({ children, className, ...restProps }: SelectProps) => {
  return (
    <select className={cn(className, styles.base)} {...restProps}>
      {children}
    </select>
  );
};
