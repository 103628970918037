import React from "react";
import cn from "classnames";
import styles from "./Busy.module.css";

export interface BusyProps extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Loading spinner.
 *
 * @todo Accessibility updates including a global aria-live region for annoucenments.
 */
export const Busy = ({ className, ...restProps }: BusyProps): JSX.Element => {
  return (
    <div className={cn(className, styles.base)} {...restProps}>
      <div className={styles.loader} />
    </div>
  );
};
