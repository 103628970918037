import React from "react";
import classNames from "classnames";
import styles from "./LogoMark.module.css";

export interface LogoMarkProps
  extends React.AllHTMLAttributes<HTMLDivElement> {}

export const LogoMark = ({
  className,
  ...restProps
}: LogoMarkProps): JSX.Element => (
  <div
    className={classNames(styles.base, className)}
    {...restProps}
    aria-description="Logo"
  />
);
