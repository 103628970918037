import {
  Timestamp,
  collection,
  limit,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { db } from "../firebase-config";

export const allCompletedQuery = (userId: string, pageSize: number) =>
  query(
    collection(db, "todos"),
    where("userId", "==", userId),
    where("datetimeCompleted", "!=", null),
    orderBy("datetimeCompleted", "asc"),
    orderBy("created", "asc"),
    limit(pageSize)
  );

export const allTrashQuery = (userId: string, pageSize: number) =>
  query(
    collection(db, "todos"),
    where("userId", "==", userId),
    where("datetimeDeleted", "!=", null),
    orderBy("datetimeDeleted", "asc"),
    orderBy("created", "asc"),
    limit(pageSize)
  );

export const completedDateRangeQuery = (
  userId: string,
  pageSize: number,
  startDate: Timestamp,
  endDate: Timestamp
) =>
  query(
    collection(db, "todos"),
    where("userId", "==", userId),
    where("datetimeCompleted", "!=", null),
    where("datetimeCompleted", ">=", startDate),
    where("datetimeCompleted", "<=", endDate),
    orderBy("datetimeCompleted", "asc"),
    orderBy("created", "asc"),
    limit(pageSize)
  );

export const trashDateRangeQuery = (
  userId: string,
  pageSize: number,
  startDate: Timestamp,
  endDate: Timestamp
) =>
  query(
    collection(db, "todos"),
    where("userId", "==", userId),
    where("datetimeDeleted", "!=", null),
    where("datetimeDeleted", ">=", startDate),
    where("datetimeDeleted", "<=", endDate),
    orderBy("datetimeDeleted", "asc"),
    orderBy("created", "asc"),
    limit(pageSize)
  );

export const todoListQuery = (
  userId: string,
  parentList: string,
  pageSize: number
) =>
  query(
    collection(db, "todos"),
    where("userId", "==", userId),
    where("parentList", "==", parentList),
    orderBy("datetimeCompleted", "asc"),
    orderBy("order", "asc"),
    orderBy("created", "asc"),
    limit(pageSize)
  );
