import React, { useEffect, useState } from "react";
import cn from "classnames";
import { signInWithGoogle, signOutFromGoogle } from "../auth";
import { useAuth } from "../hooks/useAuth";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuDivider,
  DropdownMenuTitle,
} from "./DropdownMenu";
import type { TimeFilter } from "../types";
import styles from "./Nav.module.css";
import { IndicatorSet } from "./IndicatorSet";
import { getDayName, getListByName } from "../utils";
import { ArchiveTodoHook, useTodos, useViewportSize } from "../hooks";
import { LogoType } from "./Logo";
import { useNavigate, useLocation } from "react-router";
import { ArchiveDialog } from "./ArchiveDialog";
import { useGlobalContext } from "../App";
import { TodoList } from "./Todo/TodoList";

export interface NavProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The kind of nav to render
   *
   * @default "todoList"
   */
  kind?: "todoList" | "content-page";
  /**
   * Triggered by user swipe gestures on the nav element.
   * - "left" means the user dragged their finger from right to left, the
   *   distance will be a positive number.
   * - "right" means the user dragged their finger from left to right, the
   *   distance will be a negative number.
   *
   * @param direction
   * @param distance
   * @returns
   */
  onSwipe?: (direction: "left" | "right", distance: number) => void;
  /**
   * The realtime distance of the swipe gesture.
   *
   * @param distance
   * @returns
   */
  onSwipeMove?: (distance: number) => void;
}

export const Nav = ({
  className,
  kind = "todoList",
  onSwipe,
  onSwipeMove,
  ...restProps
}: NavProps): JSX.Element => {
  const location = useLocation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { activeDialog, setActiveDialog, setActiveCard } = useGlobalContext();
  const { width: viewportWidth } = useViewportSize();
  const [swipeStartX, setSwipeStartX] = useState(0);
  const [completedTodosFilterValue, setCompletedTodosFilterValue] =
    useState<TimeFilter | null>("today");
  const [trashTodosFilterValue, setTrashTodosFilterValue] =
    useState<TimeFilter | null>("today");
  const lists = {
    next: getListByName("next"),
    later: getListByName("later"),
    trash: getListByName("trash"),
    completed: getListByName("completed"),
  };
  const completedTodos: ArchiveTodoHook = useTodos(
    "completed",
    100,
    completedTodosFilterValue
  );
  const trashTodos: ArchiveTodoHook = useTodos(
    "trash",
    100,
    trashTodosFilterValue
  );

  const getLabel = (): string => {
    if (user) {
      return `Sign Out ${user.email}`;
    }
    return "Sign In with Google";
  };

  const handleSwipe = (event: React.TouchEvent<HTMLDivElement>): void => {
    const distance = swipeStartX - event.changedTouches[0].clientX;
    const swipeDirection = distance > 0 ? "left" : "right";

    onSwipe?.(swipeDirection, distance);
  };
  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>): void => {
    onSwipeMove?.(swipeStartX - event.touches[0].clientX);
  };

  const maybeRenderIndicators = (): JSX.Element | null => {
    if (viewportWidth >= 768) return null;
    if (kind === "content-page") return null;

    return <IndicatorSet />;
  };
  const maybeRenderMenuLists = (): JSX.Element | null => {
    if (viewportWidth >= 768 && kind !== "content-page") return null;

    return (
      <>
        <DropdownMenuTitle label="Lists" />
        <DropdownMenuItem
          label={getDayName()}
          onClick={() => {
            setActiveCard("now");
            if (kind === "content-page") navigate("/");
          }}
        />
        <DropdownMenuItem
          label={lists.next.description}
          onClick={() => {
            setActiveCard("next");
            if (kind === "content-page") navigate("/");
          }}
        />
        <DropdownMenuItem
          label={lists.later.description}
          onClick={() => {
            setActiveCard("later");
            if (kind === "content-page") navigate("/");
          }}
        />
        <DropdownMenuDivider />
      </>
    );
  };
  const renderTrashDialog = (): JSX.Element => (
    <ArchiveDialog
      content={<TodoList todos={trashTodos.todos} listName="trash" />}
      onOpenChange={(open: boolean): void => {
        setActiveDialog(open ? "trash" : null);
      }}
      open={activeDialog === "trash"}
      todoHook={trashTodos}
      onChangeDateFilter={(e) =>
        setTrashTodosFilterValue(e.target.value as TimeFilter)
      }
      dateFilterValue={trashTodosFilterValue ?? undefined}
      title="Deleted"
    />
  );
  const renderCompletedDialog = (): JSX.Element => (
    <ArchiveDialog
      content={<TodoList todos={completedTodos.todos} listName="completed" />}
      onOpenChange={(open: boolean): void => {
        setActiveDialog(open ? "complete" : null);
      }}
      open={activeDialog === "complete"}
      todoHook={completedTodos}
      onChangeDateFilter={(e) =>
        setCompletedTodosFilterValue(e.target.value as TimeFilter)
      }
      dateFilterValue={completedTodosFilterValue ?? undefined}
      title="Completed"
    />
  );

  // set dialog queries
  useEffect(() => {
    if (activeDialog === "complete") {
      setCompletedTodosFilterValue(completedTodosFilterValue ?? "today");
    }
    if (activeDialog === "trash") {
      setTrashTodosFilterValue(trashTodosFilterValue ?? "today");
    }
  }, [activeDialog, completedTodosFilterValue, trashTodosFilterValue]);

  return (
    <nav
      className={cn(className, styles.base)}
      aria-describedby="logo"
      onTouchStart={(event) => setSwipeStartX(event.touches[0].clientX)}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleSwipe}
      {...restProps}
    >
      <div className={styles.menuWrap}>
        <DropdownMenu
          label="Menu"
          renderMenuButton={(buttonProps): JSX.Element => {
            const { className, ...restButtonProps } = buttonProps;
            return (
              <button
                {...restButtonProps}
                aria-label="Menu"
                className={styles.menuButton}
              />
            );
          }}
        >
          {maybeRenderMenuLists()}
          <DropdownMenuTitle label="View Archives" />
          <DropdownMenuItem
            label={lists.completed.description}
            onClick={() => setActiveDialog("complete")}
          />
          <DropdownMenuItem
            label={lists.trash.description}
            onClick={() => setActiveDialog("trash")}
          />
          <DropdownMenuDivider />
          <DropdownMenuItem label="Settings" to="/settings" />
          <DropdownMenuItem label="About Chekhov" to="/about" />
          <DropdownMenuItem label="Download Chekhov" to="/download" />
          <DropdownMenuDivider />
          <DropdownMenuItem
            label={getLabel()}
            onClick={user ? signOutFromGoogle : signInWithGoogle}
          />
        </DropdownMenu>
      </div>
      <LogoType as="h1" id="logo" />
      {maybeRenderIndicators()}
      {renderCompletedDialog()}
      {renderTrashDialog()}
    </nav>
  );
};
