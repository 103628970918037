import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  getDocs,
  onSnapshot,
  query,
  runTransaction,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase-config";
import type { ParentList, TimeFilter, Todo } from "../types";
import {
  allCompletedQuery,
  todoListQuery,
  completedDateRangeQuery,
  trashDateRangeQuery,
  allTrashQuery,
} from "./firebase.queries";
import {
  getTodayTimestamps,
  getThisWeekTimestamps,
  getLastWeekTimeStamps,
  getThisMonthTimestamps,
  getThisYearTimestamps,
} from "../utils/datetime";

/**
 * Adds a new todo item to the Firebase database.
 * @param {Partial<Todo>} todo - The data for the new todo item.
 * @returns The ID of the newly added todo item.
 */
export const addNewTodo = async (todo: Partial<Todo>) => {
  const docRef = await addDoc(collection(db, "todos"), todo);
  return docRef.id;
};

export type FetchTodosArgs = {
  parentList: ParentList;
  pageSize: number;
  userId: string;
  dateRange?: TimeFilter | null;
};

/**
 * Fetches a page of todos from the database.
 */
export const fetchTodos = async ({
  parentList,
  pageSize,
  userId,
  dateRange,
}: FetchTodosArgs) => {
  return new Promise<Todo[]>((resolve, reject) => {
    let q;

    if (parentList === "completed") {
      if (!dateRange || dateRange === "all") {
        q = allCompletedQuery(userId, pageSize);
      } else if (dateRange === "thisYear") {
        const [startOfYear, endOfYear] = getThisYearTimestamps();
        q = completedDateRangeQuery(userId, pageSize, startOfYear, endOfYear);
      } else if (dateRange === "thisMonth") {
        const [startOfMonth, endOfMonth] = getThisMonthTimestamps();
        q = completedDateRangeQuery(userId, pageSize, startOfMonth, endOfMonth);
      } else if (dateRange === "lastWeek") {
        const [startOfWeek, endOfWeek] = getLastWeekTimeStamps();
        q = completedDateRangeQuery(userId, pageSize, startOfWeek, endOfWeek);
      } else if (dateRange === "thisWeek") {
        const [startOfWeek, endOfWeek] = getThisWeekTimestamps();
        q = completedDateRangeQuery(userId, pageSize, startOfWeek, endOfWeek);
      } else {
        const [startOfDay, endOfDay] = getTodayTimestamps();
        q = completedDateRangeQuery(userId, pageSize, startOfDay, endOfDay);
      }
    } else if (parentList === "trash") {
      if (!dateRange || dateRange === "all") {
        q = allTrashQuery(userId, pageSize);
      } else if (dateRange === "thisYear") {
        const [startOfYear, endOfYear] = getThisYearTimestamps();
        q = trashDateRangeQuery(userId, pageSize, startOfYear, endOfYear);
      } else if (dateRange === "thisMonth") {
        const [startOfMonth, endOfMonth] = getThisMonthTimestamps();
        q = trashDateRangeQuery(userId, pageSize, startOfMonth, endOfMonth);
      } else if (dateRange === "lastWeek") {
        const [startOfWeek, endOfWeek] = getLastWeekTimeStamps();
        q = trashDateRangeQuery(userId, pageSize, startOfWeek, endOfWeek);
      } else if (dateRange === "thisWeek") {
        const [startOfWeek, endOfWeek] = getThisWeekTimestamps();
        q = trashDateRangeQuery(userId, pageSize, startOfWeek, endOfWeek);
      } else {
        const [startOfDay, endOfDay] = getTodayTimestamps();
        q = trashDateRangeQuery(userId, pageSize, startOfDay, endOfDay);
      }
    } else {
      q = todoListQuery(userId, parentList, pageSize);
    }

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const todos = snapshot.docs.map((doc) => ({
          ...(doc.data() as Todo),
          id: doc.id,
        }));
        resolve(todos);
      },
      (error) => {
        reject(error);
      }
    );

    return () => unsubscribe();
  });
};

/**
 * Moves a todo item to a new list.
 */
export const moveToListByID = async ({
  todoID,
  targetList,
}: {
  todoID: string;
  targetList: ParentList;
  sourceList: ParentList;
}) => {
  const todoRef = doc(db, "todos", todoID);

  const updatedTodoData: Partial<Todo> = {
    parentList: targetList,
    ...(targetList === "completed" && { datetimeCompleted: Timestamp.now() }),
    ...(targetList === "trash" && { datetimeDeleted: Timestamp.now() }),
  };

  await updateDoc(todoRef, updatedTodoData);
};

/**
 * Recreate moving all items from the source list to the target list
 * for use in updated react-query optimistic mutations. This function
 * MUST be manually aligned with the actual `moveAllToList` function
 * to ensure that the optimistic update matches the actual update.
 */
export const getMoveAllToListMutatedState = (
  targetListName: ParentList,
  targetListTodos: Todo[],
  sourceListTodos: Todo[]
) => {
  const updatedTodos: Todo[] = sourceListTodos.map((todo: Todo) => {
    return {
      ...todo,
      parentList: targetListName,
      ...(targetListName === "completed" && {
        datetimeCompleted: Timestamp.now(),
      }),
      ...(targetListName === "trash" && { datetimeDeleted: Timestamp.now() }),
    };
  });

  return [...targetListTodos, ...updatedTodos];
};
/**
 * Moves all items from the source list to the target list.
 */
export const moveAllToList = async ({
  userId,
  sourceList,
  targetList,
}: {
  userId: string | null;
  sourceList: ParentList;
  targetList: ParentList;
}) => {
  const q = query(
    collection(db, "todos"),
    where("userId", "==", userId),
    where("parentList", "==", sourceList)
  );

  try {
    const querySnapshot = await getDocs(q);
    const itemsToUpdate: DocumentReference[] = [];

    querySnapshot.forEach((doc) => {
      itemsToUpdate.push(doc.ref);
    });

    if (itemsToUpdate.length > 0) {
      await runTransaction(db, async (transaction) => {
        const newData: { [key: string]: any } = { parentList: targetList };

        if (targetList === "completed") {
          newData.datetimeCompleted = Timestamp.now();
        }
        if (targetList === "trash") {
          newData.datetimeDeleted = Timestamp.now();
        }

        itemsToUpdate.forEach((itemRef) => {
          transaction.update(itemRef, { ...newData });
        });
      });
    }
  } catch (error) {
    console.error("Error moving all items", error);
  }
};

/**
 * Clears all completed items from the given list.
 */
export const clearCompleted = async ({
  listName,
  userId,
}: {
  listName: ParentList;
  userId: string | null;
}) => {
  const q = query(
    collection(db, "todos"),
    where("userId", "==", userId),
    where("parentList", "==", listName),
    where("datetimeCompleted", "!=", null)
  );

  try {
    const querySnapshot = await getDocs(q);
    const itemsToUpdate: DocumentReference[] = [];

    querySnapshot.forEach((doc) => {
      itemsToUpdate.push(doc.ref);
    });

    if (itemsToUpdate.length > 0) {
      await runTransaction(db, async (transaction) => {
        itemsToUpdate.forEach((itemRef) => {
          transaction.update(itemRef, { parentList: "completed" });
        });
      });
      return { updated: true, count: itemsToUpdate.length, listName, userId };
    } else {
      return { updated: false, count: 0, listName, userId };
    }
  } catch (error) {
    console.error("Error clearing completed items", error);
  }
};

/**
 * Toggle the "completed" status of a todo item.
 * - If the item has a completed datetime, on toggle it will be set to null.
 * - If the item does not have a completed datetime, on toggle it will be set to the current datetime.
 * - If the item has no title, it will be deleted instead of toggled.
 *
 * @param todo
 */
export const toggleTodoStatus = async ({
  datetimeCompleted,
  id: todoID,
  title: todoTitle,
}: Pick<Todo, "datetimeCompleted" | "id" | "title">) => {
  const isComplete = Boolean(datetimeCompleted);
  const newDatetimeCompleted = isComplete ? null : Timestamp.now();

  try {
    if (todoID) {
      const todoRef = doc(db, "todos", todoID);

      if (todoTitle.trim() === "" || Boolean(todoTitle) === false) {
        await deleteDoc(todoRef);
      } else {
        await updateDoc(todoRef, { datetimeCompleted: newDatetimeCompleted });
      }
    }
  } catch (error) {
    console.error("Error updating todo", error);
  }
};

export const updateTitle = async (todoID: string | null, title: string) => {
  if (!todoID) {
    console.warn(`No todoID found for title "${title}"`);
    return;
  }

  try {
    const todoRef = doc(db, "todos", todoID);
    await updateDoc(todoRef, { title });
  } catch (error) {
    console.error("Error updating todo", error);
  }
};
