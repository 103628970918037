import React from "react";
import {
  SwipeableList,
  SwipeableListItem,
  Type as SwipeableListType,
} from "react-swipeable-list";
import { SwipeActionButton } from "./SwipeActionButton";
import {
  getAfterLists,
  getBeforeLists,
  getIncrementedListName,
  moveToListByID,
} from "../../utils";
import type { ParentList, Todo } from "../../types";
import { TodoInput } from "./TodoInput";
import { useMutation } from "react-query";
import { queryClient, useGlobalContext } from "../../App";
import styles from "./TodoList.module.css";

type HandleAddTodoArgs = { parentList: ParentList; position: string };

export interface TodoListProps {
  todos: Todo[];
  listName: ParentList;
  handleAddTodo?: (args: HandleAddTodoArgs) => void;
}

export const TodoList = ({
  todos,
  listName,
  handleAddTodo: handleAddTodoProp,
}: TodoListProps): JSX.Element => {
  const leadingActionsList = getBeforeLists(listName);
  const trailingActionsList = getAfterLists(listName);
  const { focusedTodoID, setFocusedTodoID } = useGlobalContext();

  const handleAddTodo = (args: HandleAddTodoArgs) => {
    if (handleAddTodoProp) {
      handleAddTodoProp(args);
    }
  };
  const handleDelete = async ({
    todoID,
    parentList,
  }: {
    todoID: string;
    parentList: ParentList;
  }) => {
    moveToListMutation.mutate({
      todoID,
      targetList: "trash",
      sourceList: parentList,
    });
  };
  const moveToListMutation = useMutation(moveToListByID, {
    onSuccess: (_result, data) => {
      const { targetList, sourceList } = data;
      queryClient.invalidateQueries(["todos", sourceList]);
      queryClient.invalidateQueries(["todos", targetList]);
    },
  });

  return (
    <SwipeableList
      type={SwipeableListType.IOS}
      fullSwipe
      threshold={0.75}
      style={{
        borderBottomLeftRadius: "var(--card-border-radius)",
        borderBottomRightRadius: "var(--card-border-radius)",
      }}
    >
      {todos.map((todo: Todo) => (
        <SwipeableListItem
          key={todo.id}
          leadingActions={
            <SwipeActionButton
              todo={todo}
              listsToTarget={leadingActionsList}
              position="leading"
            />
          }
          trailingActions={
            <SwipeActionButton
              todo={todo}
              listsToTarget={trailingActionsList}
              position="trailing"
            />
          }
          className={styles.swipeableListItem}
        >
          <TodoInput
            key={todo.id}
            existingTodo={todo}
            onCreateNewTodo={(todoID) =>
              handleAddTodo({ parentList: listName, position: todoID })
            }
            onDelete={(todoID) =>
              handleDelete({ todoID, parentList: listName })
            }
            focusedTodoID={focusedTodoID}
            onClearFocusID={() => setFocusedTodoID(null)}
            onMoveForward={(todoID) => {
              if (todoID) {
                const nextList = getIncrementedListName(1, listName);
                if (nextList === null) return;

                moveToListMutation.mutate({
                  todoID,
                  targetList: nextList,
                  sourceList: listName,
                });
              }
            }}
            onMoveBackward={(todoID) => {
              if (todoID) {
                const previousList = getIncrementedListName(-1, listName);
                if (previousList === null) return;

                moveToListMutation.mutate({
                  todoID,
                  targetList: previousList,
                  sourceList: listName,
                });
              }
            }}
          />
        </SwipeableListItem>
      ))}
    </SwipeableList>
  );
};
