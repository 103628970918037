import React from "react";
import cn from "classnames";
import styles from "./Card.module.css";
import { Button } from "./Button";
import { Group } from "./Group";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuDivider,
  DropdownMenuTitle,
} from "./DropdownMenu";
import { ParentList, Todo } from "../types";
import { clearCompleted, getMoveAllToListMutatedState } from "../utils";
import { queryClient, useGlobalContext } from "../App";
import { copyListToClipboard, moveAllToList } from "../utils";
import { useToast } from "./Toast";
import { useMutation } from "react-query";
import { forEach } from "lodash";

export interface CardCSSProps {
  "--card-border-color"?: string;
  "--card-border-radius"?: string;
  "--card-surface"?: string;
  "--card-title-color"?: string;
}
export interface CardProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "style"> {
  title?: string;
  /**
   * Card is rendered in a "floating" state, meaning it has a shadow.
   *
   * @default false
   */
  floating?: boolean;
  handleAddTodo: () => void;
  addTodoButtonRef: React.RefObject<HTMLButtonElement>;
  inert?: boolean;
  onClickHeader?: () => void;
  /**
   * Card is rendered in a "flush" state, meaning it has no border or shadow
   *
   * @default false
   */
  flush?: boolean;
  listName: ParentList;
  list: Todo[];
  style?: CardCSSProps & React.CSSProperties;
}

export const Card = ({
  addTodoButtonRef,
  handleAddTodo,
  children,
  className,
  onClickHeader,
  floating = false,
  flush = false,
  title,
  inert,
  listName,
  list,
  ...restProps
}: CardProps): JSX.Element => {
  const { user } = useGlobalContext();
  const toast = useToast();
  const moveAllToListMutation = useMutation(moveAllToList, {
    onMutate: async (data) => {
      const { targetList: targetListName, sourceList: sourceListName } = data;

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(["todos", sourceListName]);
      await queryClient.cancelQueries(["todos", targetListName]);

      // Snapshot the previous value
      const previousSourceList: Todo[] | undefined = queryClient.getQueryData([
        "todos",
        sourceListName,
        user?.uid,
        100,
      ]);
      const previousTargetList: Todo[] | undefined = queryClient.getQueryData([
        "todos",
        targetListName,
        user?.uid,
        100,
      ]);

      const updatedSourceTodos: [] = [];
      const updatedTargetTodos = getMoveAllToListMutatedState(
        targetListName,
        previousTargetList ?? [],
        previousSourceList ?? []
      );

      // Optimistically update to the new value
      queryClient.setQueryData(
        ["todos", sourceListName, user?.uid, 100],
        (_old: Todo[] | undefined) => {
          return [];
        }
      );
      queryClient.setQueryData(
        ["todos", targetListName, user?.uid, 100],
        (old: Todo[] | undefined) => {
          const targetList = old ?? [];
          const sourceList = previousSourceList ?? [];

          forEach(sourceList, (todo) => {
            todo.parentList = targetListName;
          });

          return [...targetList, ...sourceList];
        }
      );

      return {
        previousSourceList,
        previousTargetList,
        targetList: targetListName,
        sourceList: sourceListName,
        updatedSourceTodos,
        updatedTargetTodos,
      };
    },
    onError: (error, _data, context) => {
      console.error("moveAllToListMutation error", error);
      // Roll back the optimistic update
      queryClient.setQueryData(
        ["todos", context?.sourceList],
        context?.previousSourceList
      );
      queryClient.setQueryData(
        ["todos", context?.targetList],
        context?.previousTargetList
      );
    },
    onSuccess: (_result, data, context) => {
      if (!context) return;
      const { targetList, sourceList } = data;
      const { updatedSourceTodos, updatedTargetTodos } = context;
      // Manually set the new state for source and target lists
      queryClient.setQueryData(["todos", sourceList], updatedSourceTodos);
      queryClient.setQueryData(["todos", targetList], updatedTargetTodos);
    },
  });
  const clearAllCompletedMutation = useMutation(clearCompleted, {
    onSuccess: (_result, data) => {
      console.log("cleared completed", _result, data);
      queryClient.invalidateQueries(["todos", data.listName]);
      queryClient.invalidateQueries(["todos", "completed"]);
    },
  });

  const renderActionBar = (): JSX.Element | null => {
    if (!handleAddTodo) return null;

    const handleCopyCurrentList = () => {
      copyListToClipboard({ list, title: listName });
      toast.addMessage("List copied to clipboard");
    };

    return (
      <Group className={styles.headerActions}>
        <DropdownMenu
          triggerIcon="more"
          triggerAriaLabel="Edit"
          disabled={inert}
        >
          <DropdownMenuItem
            label="Copy current list"
            onClick={handleCopyCurrentList}
          />
          <DropdownMenuItem
            label="Clear completed items"
            onClick={() =>
              clearAllCompletedMutation.mutate({
                listName,
                userId: user?.uid ?? null,
              })
            }
          />
          <DropdownMenuDivider />
          <DropdownMenuTitle label="Move all items to..." />
          <DropdownMenuItem
            label="...Trash"
            disabled={listName === "trash"}
            onClick={() =>
              moveAllToListMutation.mutate({
                userId: user?.uid ?? null,
                sourceList: listName,
                targetList: "trash",
              })
            }
          />
          <DropdownMenuItem
            label="...Completed"
            disabled={listName === "completed"}
            onClick={() =>
              moveAllToListMutation.mutate({
                userId: user?.uid ?? null,
                sourceList: listName,
                targetList: "completed",
              })
            }
          />
          <DropdownMenuItem
            label="...Now"
            disabled={listName === "now"}
            onClick={() =>
              moveAllToListMutation.mutate({
                userId: user?.uid ?? null,
                sourceList: listName,
                targetList: "now",
              })
            }
          />
          <DropdownMenuItem
            label="...Next"
            disabled={listName === "next"}
            onClick={() =>
              moveAllToListMutation.mutate({
                userId: user?.uid ?? null,
                sourceList: listName,
                targetList: "next",
              })
            }
          />
          <DropdownMenuItem
            label="...Later"
            disabled={listName === "later"}
            onClick={() =>
              moveAllToListMutation.mutate({
                userId: user?.uid ?? null,
                sourceList: listName,
                targetList: "later",
              })
            }
          />
        </DropdownMenu>
        <Button
          aria-label="Add todo"
          className={styles.headerButton}
          icon="add"
          disabled={inert}
          onClick={handleAddTodo}
          ref={addTodoButtonRef}
        />
      </Group>
    );
  };
  const renderTitle = () => {
    if (!title) return null;

    return (
      <header className={styles.titleWrap}>
        {/* The title onClick is not a button and lacks a tab stop. It's not meant to be
            focusable, nor is it meant to show up in the accessibility tree. This 
            onClick is meant to quickly to other lists and is a convenience feature 
            that is in addition to the official navigation buttons. */}
        <h2 className={styles.title} onClick={onClickHeader}>
          {title}
        </h2>
        {renderActionBar()}
      </header>
    );
  };

  return (
    <section
      className={cn(
        className,
        styles.base,
        flush ? styles.flush : styles.card,
        { [styles.floating]: floating }
      )}
      {...restProps}
    >
      {renderTitle()}
      {/** @ts-expect-error  `inert` is a real thing*/}
      <div inert={inert ? "true" : undefined} className={styles.childrenWrap}>
        {children}
      </div>
    </section>
  );
};
