import React from "react";
import cn from "classnames";
import styles from "./LogoType.module.css";

export interface LogoTypeProps
  extends React.AllHTMLAttributes<HTMLHeadingElement> {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export const LogoType = ({
  as: Component,
  className,
  ...restProps
}: LogoTypeProps): JSX.Element => (
  <Component className={cn(styles.base, className)} {...restProps}>
    Chekhov
  </Component>
);
