import {
  GoogleAuthProvider,
  signInWithPopup,
  UserCredential,
  AuthError,
  signOut,
} from "firebase/auth";
import { auth } from "./firebase-config";

export const signInWithGoogle = async (): Promise<void> => {
  try {
    const provider = new GoogleAuthProvider();
    const result: UserCredential = await signInWithPopup(auth, provider);

    // The signed-in user info.
    const user = result.user;
    console.log("User signed in: ", user);

    // Optional: Use the token to access Google APIs, if necessary.
    // This part is optional and often not needed for Firebase auth purposes.
    // const credential = GoogleAuthProvider.credentialFromResult(result);
    // const token = credential?.accessToken; // Note: `accessToken` might be undefined, handle accordingly.
    // Use this token to access Google APIs, if required and available.
  } catch (error) {
    const e = error as AuthError; // Casting the error to `AuthError` type for better error handling.
    console.error("Error signing in with Google: ", e.message);
    // Handle errors here. Error codes: e.code, email used for sign in attempt: e.email, etc.
  }
};

export const signOutFromGoogle = async (): Promise<void> => {
  try {
    await signOut(auth);
    console.log("User signed out successfully.");
  } catch (error) {
    console.error("Error signing out user: ", error);
  }
};
