import React from "react";

export const LaterIcon = (
  props: React.AllHTMLAttributes<SVGElement>
): JSX.Element => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 8h10m0 0-3-4m3 4-3 4M5 8h3m0 0L5 4m3 4-3 4"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
