import React, { useMemo } from "react";
import { CheckedSVG } from "./components/CheckedSVG";
import { UncheckedSVG } from "./components/UncheckedSVG";
import styles from "./Checkbox.module.css";

export interface CheckboxProps
  extends React.AllHTMLAttributes<HTMLLabelElement> {
  checked: boolean;
}

export const Checkbox = ({
  checked,
  ...restProps
}: CheckboxProps): JSX.Element => {
  const renderCheckbox = useMemo(() => {
    if (checked) {
      return <CheckedSVG />;
    }
    return <UncheckedSVG />;
  }, [checked]);

  return (
    <label className={styles.base} {...restProps}>
      <div aria-hidden className={styles.svgContainer}>
        {renderCheckbox}
      </div>
      <div className={styles.srOnly}>{checked ? "Completed" : "Todo"}</div>
      <input
        type="checkbox"
        checked={checked}
        className={styles.actualCheckbox}
        readOnly
        // This is a presentational checkbox, so it doesn't need to be focusable.
        // Control is handled by the containing element.
        tabIndex={-1}
      />
    </label>
  );
};
