import React from "react";

export const DoneIcon = (
  props: React.AllHTMLAttributes<SVGElement>
): JSX.Element => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m3 8.5 2.5 2.9c.5.6.7.9 1 .9.3 0 .6-.4 1-1l5.5-8"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
