import React, { useMemo } from "react";
import type { ArchiveTodoHook } from "../hooks";
import styles from "./ArchiveDialog.module.css";
import { Button } from "./Button";
import { Dialog } from "./Dialog";
import { Select } from "./Select";
import type { SelectProps } from "./Select";
import { TimeFilter } from "../types";
import { copyListToClipboard } from "../utils";
import { useToast } from "./Toast";
import { Busy } from "./Busy";

export interface ArchiveDialogProps {
  content: JSX.Element;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  todoHook: ArchiveTodoHook;
  onChangeDateFilter: SelectProps["onChange"];
  dateFilterValue: TimeFilter | undefined;
  title: string;
}

export const ArchiveDialog = ({
  content,
  dateFilterValue,
  onChangeDateFilter,
  onOpenChange,
  open,
  todoHook,
  title,
}: ArchiveDialogProps) => {
  const toast = useToast();
  const todoListLength = todoHook.todos.length;
  const hasTodos = todoListLength > 0;

  const renderDialogContent = useMemo(() => {
    if (todoHook.loading) return <Busy />;
    if (!hasTodos) return <p>No items found</p>;

    return content;
  }, [content, hasTodos, todoHook.loading]);

  const renderCopyButton = useMemo(
    () => (
      <Button
        disabled={!hasTodos}
        kind="reverse"
        onClick={() => {
          copyListToClipboard({
            list: todoHook.todos,
            title: "Archive List",
          });
          toast.addMessage(`Copied ${todoListLength} items`);
        }}
        style={{ "--button-font-size": "0.75rem" }}
      >
        Copy List ({todoListLength})
      </Button>
    ),
    [hasTodos, todoListLength, todoHook.todos, toast]
  );
  const renderDateFilter = useMemo(
    () => (
      <Select
        onChange={onChangeDateFilter}
        value={dateFilterValue}
        className={styles.dateFilterSelect}
      >
        <option value="today">Today</option>
        <option value="thisWeek">This Week</option>
        <option value="lastWeek">Last Week</option>
        <option value="thisMonth">This Month</option>
        <option value="thisYear">This Year</option>
        <option value="all">All</option>
      </Select>
    ),
    [dateFilterValue, onChangeDateFilter]
  );

  return (
    <Dialog
      headingText={title}
      headingAction={renderCopyButton}
      open={open}
      onOpenChange={onOpenChange}
      className={styles.base}
    >
      {renderDateFilter}
      {renderDialogContent}
    </Dialog>
  );
};
