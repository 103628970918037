import React from "react";
import {
  SwipeAction as ReactSwipeAction,
  LeadingActions as ReactSwipeLeadingActions,
  TrailingActions as ReactSwipeTrailingActions,
} from "react-swipeable-list";
import type { List, Todo } from "../../types";
import styles from "./SwipeAction.module.css";
import { moveToListByID } from "../../utils";
import { useMutation } from "react-query";
import { queryClient } from "../../App";
import { TrashIcon, DoneIcon, LaterIcon, NextIcon, NowIcon } from "../Icons";

interface SwipeActionProps {
  onClick?: () => void;
  destructive?: boolean;
  list: List;
  todo: Todo;
}

function SwipeAction({
  onClick,
  destructive = false,
  list,
  todo,
  ...restProps
}: SwipeActionProps): JSX.Element {
  const handleClick = (): void => {
    // if (destructive) handleTodoDelete();
    if (!onClick) {
      return;
    }

    onClick();
  };

  let IconComponent;
  switch (list.name) {
    case "trash":
      IconComponent = TrashIcon;
      break;
    case "completed":
      IconComponent = DoneIcon;
      break;
    case "later":
      IconComponent = LaterIcon;
      break;
    case "next":
      IconComponent = NextIcon;
      break;
    case "now":
      IconComponent = NowIcon;
      break;
    default:
      IconComponent = TrashIcon;
  }

  return (
    <ReactSwipeAction
      destructive={destructive}
      onClick={handleClick}
      // Technically this is a button but it doesn't need to be focusable
      // or to appear in the accessibility tree, as it's meant as a swipe
      // shortcut. The a11y path is through other means.
      Tag="div"
      {...restProps}
      // @ts-expect-error - className IS a valid prop and passes through to the underlying div
      className={styles.buttonReset}
    >
      <div
        className={styles.base}
        style={{
          backgroundColor: list.surface,
        }}
      >
        <IconComponent className={styles.icon} />
        {list.description}
      </div>
    </ReactSwipeAction>
  );
}

export interface SwipeActionButtonProps {
  todo: Todo;
  listsToTarget: List[];
  position: "trailing" | "leading";
}

export const SwipeActionButton = ({
  todo,
  listsToTarget,
  position,
}: SwipeActionButtonProps): JSX.Element | null => {
  const moveToListMutation = useMutation(moveToListByID, {
    onSuccess: (_result, data) => {
      const { targetList, sourceList } = data;
      queryClient.invalidateQueries(["todos", sourceList]);
      queryClient.invalidateQueries(["todos", targetList]);
    },
  });

  if (!listsToTarget.length) return null;
  const Component =
    position === "trailing"
      ? ReactSwipeTrailingActions
      : ReactSwipeLeadingActions;

  return (
    <Component>
      {listsToTarget.map((list, index) => (
        <SwipeAction
          onClick={() =>
            moveToListMutation.mutate({
              todoID: todo.id,
              targetList: list.name,
              sourceList: todo.parentList,
            })
          }
          list={list}
          key={`list.description-${index}`}
          todo={todo}
          destructive
        />
      ))}
    </Component>
  );
};
