import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyApXUElkHnonTvAnWZUpm-rJqEKCyNYKXQ",
  authDomain: "chekhov-a83e8.firebaseapp.com",
  projectId: "chekhov-a83e8",
  storageBucket: "chekhov-a83e8.appspot.com",
  messagingSenderId: "564507249275",
  appId: "1:564507249275:web:b6f884b7f01f25e089b0de",
  measurementId: "G-Z46F0FFC2K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Firebase Authentication
export const auth = getAuth(app);
