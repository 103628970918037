import { useState, useEffect } from "react";

export type ViewportSize = {
  width: number;
  height: number;
};

/**
 * Listens to window resize events and returns the current viewport size.
 *
 * I expect vewiprot resizing to be fairly rare in this app, so I haven't tried
 * to optimize this hook. If you find that it's causing performance issues, you
 * could try debouncing the resize event.
 *
 * @returns ViewportSize
 */
export const useViewportSize = () => {
  const [size, setSize] = useState<ViewportSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return size;
};
