import { Todo } from "../types";

export interface ListToCopy {
  list: Todo[];
  title: string;
}

/**
 * For a given list, create a Markdown formatted list of all todos in the list.
 */
export function createMarkdownFromList({ list, title }: ListToCopy): string {
  if (list.length === 0) {
    console.warn("Empty list");
    return "";
  }

  const listMap = list
    .map((todo) => {
      const title = todo.title ?? " ";
      const completedMark = todo.datetimeCompleted ? "x" : " ";

      return `-[${completedMark}] ${title}`;
    })
    .join("\n");

  const header = `# ${title}\n\n`;

  return `${header}${listMap}`;
}

export async function copyListToClipboard({
  list,
  title,
}: ListToCopy): Promise<void> {
  const markdown = createMarkdownFromList({ list, title });

  try {
    await navigator.clipboard.writeText(markdown);
  } catch (error) {
    console.error("Error writing to clipboard: ", error);
  }
}
