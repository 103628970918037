import React, { useContext, createContext, useState, useCallback } from "react";
import cn from "classnames";
import styles from "./Toast.module.css";

type ToastMessage = {
  id: number;
  message: string;
  /**
   * The delay in milliseconds before the message is removed.
   *
   * @default 5000
   */
  delay?: number;
};

interface ToastContextType {
  addMessage: (message: string, delay?: number) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

let idCounter = 0;

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addMessage = useCallback((message: string, delay?: number) => {
    const id = idCounter++;
    setMessages((previousMessages) => [
      ...previousMessages,
      { id, message, delay: delay || 5000 },
    ]);

    setTimeout(() => {
      setMessages((msgs) => msgs.filter((msg) => msg.id !== id));
    }, 5000);
  }, []);

  return (
    <ToastContext.Provider value={{ addMessage }}>
      {children}

      <div
        aria-live="polite"
        aria-atomic="true"
        className={cn(styles.container, {
          [styles.open]: messages.length !== 0,
        })}
      >
        {messages.map((msg) => (
          <div key={msg.id} className={styles.base}>
            {msg.message}
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  return context;
};

export const GlobalToastContext = createContext<ToastContextType | undefined>(
  undefined
);
export const GlobalToastProvider = GlobalToastContext.Provider;
export const useGlobalToast = () => {
  const context = useContext(GlobalToastContext);

  if (!context) {
    throw new Error("useGlobalToast must be used within a GlobalToastProvider");
  }

  return context;
};

export let toastMessage = (message: string, delay?: number): void => {
  throw new Error(
    "toastMessage function has not been initialized. Make sure you are within ToastFunctionProvider."
  );
};

export const setGlobalToastMessage = (
  fn: (message: string, delay?: number) => void
) => {
  toastMessage = fn;
};
